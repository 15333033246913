import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/individual-contractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/individual-contractor", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/individual-contractor", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data, id) => {
  return responseFormatter(
    http.patch(
      "/individual-contractor",
      { ...data, id },
      {
        setAuthHeader: true,
      }
    )
  );
};

const bulkUploads = (data) => {
  return responseFormatter(
    http.patch("/ic/upload-doc", data, {
      setAuthHeader: true,
    })
  );
};

const ICDocsDelete = (id) => {
  return responseFormatter(
    http.delete(`/ic/delete-doc/${id}`, {
      setAuthHeader: true,
    })
  );
};

const markActiveInactive = (data) => {
  return responseFormatter(
    http.patch("/individual-contractor-update-status", data, {
      setAuthHeader: true,
    })
  );
};

const markFlagUnflag = (data) => {
  return responseFormatter(
    http.patch("/change-ic-flag-status", data, {
      setAuthHeader: true,
    })
  );
};

const updateFlagReason = (data) => {
  return responseFormatter(
    http.patch("/change-ic-flag-reason", data, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};
const getAllLocations = (query) => {
  return responseFormatter(
    http.get(`/addresses/get?entityType=ic`, {
      setAuthHeader: true,
    })
  );
};

const createLocation = (data) => {
  return responseFormatter(
    http.post("/addresses/create", data, {
      setAuthHeader: true,
    })
  );
};

const updateLocation = (data) => {
  return responseFormatter(
    http.put("/addresses/update", data, {
      setAuthHeader: true,
    })
  );
};

const deleteLocation = (entity_type, entity_id, address_id) => {
  return responseFormatter(
    http.delete(`/addresses/delete/${entity_type}/${entity_id}/${address_id}`, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/individualContractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getICData = (query) => {
  return responseFormatter(
    http.get(`/individual-contractors/get-ic-data/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const createStandbyRecord = (data) => {
  return responseFormatter(
    http.post("/ic/create-stand-by-record", data, {
      setAuthHeader: true,
    })
  );
};

const updateStandbyRecord = (data) => {
  return responseFormatter(
    http.put("/ic/update-stand-by-record", data, {
      setAuthHeader: true,
    })
  );
};

const markStandbyAsDefault = (data) => {
  return responseFormatter(
    http.patch("/ic/update-stand-by-status", data, {
      setAuthHeader: true,
    })
  );
};

const deleteStandbyRecord = (id) => {
  return responseFormatter(
    http.delete(`/ic/remove-stand-by-record/${id}`, {
      setAuthHeader: true,
    })
  );
};

const patchUpdateWcpMasterIc = (data) => {
  return responseFormatter(
    http.patch("/individual-contractors/update-wcp-master-ic", data, {
      setAuthHeader: true,
    })
  );
};

const addDriver = (data) => {
  return responseFormatter(
    http.post("/ic/create-wcp-driver", data, {
      setAuthHeader: true,
    })
  );
};

const updateDriver = (data) => {
  return responseFormatter(
    http.put("/ic/update-wcp-driver", data, {
      setAuthHeader: true,
    })
  );
};

const deletedriver = (id) => {
  return responseFormatter(
    http.delete(`/ic/remove-wcp-driver/${id}`, {
      setAuthHeader: true,
    })
  );
};

const ContractorService = {
  get,
  post,
  put,
  patch,
  markActiveInactive,
  getLocalResources,
  download,
  markFlagUnflag,
  updateFlagReason,
  getAllLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  bulkUploads,
  ICDocsDelete,
  getICData,
  createStandbyRecord,
  deleteStandbyRecord,
  updateStandbyRecord,
  markStandbyAsDefault,
  patchUpdateWcpMasterIc,
  addDriver,
  updateDriver,
  deletedriver,
};

export default ContractorService;
