import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/invoice/invoice-history/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getPaymentReport = (query) => {
  return responseFormatter(
    http.get(`/invoice/payment-report/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getRevenueReport = (query) => {
  return responseFormatter(
    http.get(`/invoice/revenue-per-customer-report/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerPaymentReport = (query) => {
  return responseFormatter(
    http.get(`/invoice/customer-payment-report/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getOverduePaymentReport = (query) => {
  return responseFormatter(
    http.get(`/invoice/overdue-payment-report/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getAllInvoice = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/invoice/create-invoice", data, {
      setAuthHeader: true,
    })
  );
};
const generateMultipleInvoices = (data) => {
  return responseFormatter(
    http.post("/invoice/generate-multiple-invoices", data, {
      setAuthHeader: true,
    })
  );
};
const recordPayment = (data) => {
  return responseFormatter(
    http.post("/invoice/add-invoice-payments", data, {
      setAuthHeader: true,
    })
  );
};

const getRecordPayment = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices-payments/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getInvoiceEmailHistory = (query) => {
  return responseFormatter(
    http.get(`/invoice/email-history${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const sendEmail = (data) => {
  return responseFormatter(
    http.post("/invoice/send-invoice-by-email", data, {
      setAuthHeader: true,
    })
  );
};

const update = (data) => {
  return responseFormatter(
    http.put("/invoice/update-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const finalizeInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/finalize-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const reOrdering = (data) => {
  return responseFormatter(
    http.patch("/invoices/reorder-line-items", data, {
      setAuthHeader: true,
    })
  );
};

const markInvoiceSent = (data) => {
  return responseFormatter(
    http.patch("/invoice/mark-as-sent", data, {
      setAuthHeader: true,
    })
  );
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const postLineItem = (data) => {
  return responseFormatter(
    http.post("/invoice/create-line-items", data, {
      setAuthHeader: true,
    })
  );
};
const updateLineItem = (data) => {
  return responseFormatter(
    http.put("/invoice/update-line-item", data, {
      setAuthHeader: true,
    })
  );
};

const deleteLineItem = (query) => {
  return responseFormatter(
    http.delete(`/invoice/remove-line-item${query}`, {
      setAuthHeader: true,
    })
  );
};

const approveInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/approve-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const rejectInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/reject-invoice", data, {
      setAuthHeader: true,
    })
  );
};
const deleteInvoices = (query) => {
  return responseFormatter(
    http.delete(`/invoices${query}`, {
      setAuthHeader: true,
    })
  );
};

const getInvoicesPayments = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices-payments${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getAllInvoiceDetails = (query) => {
  return responseFormatter(
    http.get(`/invoice/all-invoice-details${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const addEmailInBranch = (data) => {
  return responseFormatter(
    http.put("/customer-branch", data, {
      setAuthHeader: true,
    })
  );
};

const updateLineItemDescription = (data) => {
  return responseFormatter(
    http.patch("/invoice-item/update-description", data, {
      setAuthHeader: true,
    })
  );
};

const getBillingAndSettlementLineItem = (query) => {
  return responseFormatter(
    http.get(`/invoice/billing-settlement${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const postBillingAndSettlementLineItem = (data) => {
  return responseFormatter(
    http.post("/invoice/add-line-item-using-bs", data, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "/" });
};

const postInvoiceDescriptionNotes = (data) => {
  return responseFormatter(
    http.post("/invoice/create-additional-notes", data, {
      setAuthHeader: true,
    })
  );
};
const putInvoiceDescriptionNotes = (data) => {
  return responseFormatter(
    http.put("/invoice/update-additional-notes", data, {
      setAuthHeader: true,
    })
  );
};

const deleteInvoiceDescriptionNotes = (id) => {
  return responseFormatter(
    http.delete(`invoice/delete-additional-notes/${id} `, {
      setAuthHeader: true,
    })
  );
};

const deleteBSLineItem = (query) => {
  return responseFormatter(
    http.delete(`/invoices/delete-line-items${query}`, {
      setAuthHeader: true,
    })
  );
};

const revertInvoicePayment = (id) => {
  return responseFormatter(
    http.patch(
      `/invoice/revert-invoice-payments/${id}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const updateRecordPayment = (data) => {
  return responseFormatter(
    http.put("/invoice/update-invoice-payments", data, {
      setAuthHeader: true,
    })
  );
};

const getPaymentLogs = (query) => {
  return responseFormatter(
    http.get(`/invoice/payment-logs${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const updateBillAddress = (data) => {
  return responseFormatter(
    http.patch(`invoice/update-billTo`, data, {
      setAuthHeader: true,
    })
  );
};

const exportForQB = (query) => {
  return responseFormatter(
    http.get(`invoices/exportForQB${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getDriverList = (query) => {
  return responseFormatter(
    http.get(`/ic/wcp-driver${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  get,
  post,
  update,
  recordPayment,
  sendEmail,
  markInvoiceSent,
  getAllInvoice,
  getCustomerLocation,
  postLineItem,
  updateLineItem,
  deleteLineItem,
  approveInvoice,
  rejectInvoice,
  finalizeInvoice,
  getRecordPayment,
  deleteInvoices,
  getInvoicesPayments,
  generateMultipleInvoices,
  getAllInvoiceDetails,
  addEmailInBranch,
  getInvoiceEmailHistory,
  getPaymentReport,
  getCustomerPaymentReport,
  getOverduePaymentReport,
  getRevenueReport,
  updateLineItemDescription,
  getBillingAndSettlementLineItem,
  postBillingAndSettlementLineItem,
  getLocalResources,
  postInvoiceDescriptionNotes,
  putInvoiceDescriptionNotes,
  deleteInvoiceDescriptionNotes,
  deleteBSLineItem,
  revertInvoicePayment,
  updateRecordPayment,
  reOrdering,
  getPaymentLogs,
  updateBillAddress,
  exportForQB,
  getDriverList,
};

export default Service;
