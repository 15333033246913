import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Grid as DataGrid, ActionDialog } from "shared/components";
import Grid from "../grid";
import { PAGE_KEYS, VALIDATIONS } from "shared/constants";

import {
  queryStringBuilderNew,
  getPageConfig,
  setPageConfig,
  updatePagination,
  updateLayout,
  validator,
  updateGridLayout,
  debounce,
} from "utils";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";

import Service from "../service";

import SharedService from "modules/shared/service";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@material-ui/core/Paper";

import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";

import { Autocomplete } from "@material-ui/lab";

import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Tab,
  Tabs,
} from "@material-ui/core";
import UploadFiles from "modules/invoice/file-upload";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HistoryIcon from "@material-ui/icons/History";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

let gridHelper = null;
let timeout = null;
let fetchController;

const defaultState = {
  entries: [],

  totalEntries: 0,
  customers: [],

  customersList: [],
  pageFilters: [],

  isFetching: false,

  search: "",
  searchOptionCustomer: "",
  searchOptionContractor: "",

  pageNumber: 1,
  pageSize: 100,
  order: null,
  orderBy: null,

  filters: {
    status: [],
    is_deleted: [],
    roasterType: [],
    locations: [],
  },

  contractorEntries: [],
  totalIcEntries: 0,
  isIcFetching: false,
  icPageNumber: 1,
  icPageSize: 100,
  icOrder: "DESC",
  icOrderBy: "email",
  icPageFilters: [],
  icSearch: "",
  email: "",
  from: "",
  emailSubject: "",
  emailBody: "",

  showAddPermanentEmailDialog: false,
  ccEmail: "",
  ccEmailArray: [],

  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
    ccEmail: " ",
  },
  selectedRows: [],
  customerEmailArray: [],
  selectedCustomerEmails: [],
  filteredIcEmails: [],
  areAllContractorSelected: false,
  areAllCustomerSelected: false,
  isFetchingCustomerEmails: false,
  isFetchingContractorEmails: false,
  allCustomerEmails: [],
  allContractorEmails: [],
  tabIndex: 1,
  markCustomerSelection: false,
};

const ViewBulkMail = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.BULK_MAILS);

  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    icPageSize: pageConfig?.icPageSize || defaultState.icPageSize,
    icPageNumber: pageConfig?.icPageNumber || defaultState.icPageNumber,
    icSearch: (pageConfig && pageConfig?.icSearch) || defaultState.icSearch,
  });

  const getSearchArray = (search, type) => {
    const columns =
      type === "contractor"
        ? ["icid", "first_name", "last_name", "ic_company_name"]
        : ["name", "route_name", "cust_branch_state", "cust_branch_location"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      ccEmail: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      emailBody: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 2000 },
      ],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const uploadedAttachment = (attachment) => {
    setState((prevState) => ({
      ...prevState,
      uploadedAttachment: attachment,
    }));
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.ccEmailArray.some((obj) => obj === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.ccEmail?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.ccEmail?.trim()],

        ...(type === "ccEmailArray" && {
          showAddPermanentEmailDialog: false,
          ccEmail: defaultState.ccEmail,
        }),
      }));
    }
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );

      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (state.errors?.ccEmail === " ") {
        event.preventDefault();
        handleAddEmail("ccEmailArray");
      }
    }
  };

  const handleBlur = () => {
    if (state.errors?.ccEmail === " ") {
      handleAddEmail("ccEmailArray");
    }
  };

  const fetchEntries = useCallback(
    async (search, pageNumber, pageSize, filters, pageFilters = []) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      const searchArray = getSearchArray(search);

      const filters_is_deleted = [
        { field: "is_deleted", type: "=", value: false },
      ];
      const filters_is_active = [
        { field: "is_active", type: "=", value: true },
      ];

      let filtersArr = [
        ...filters_is_deleted,
        ...filters_is_active,
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        {},
        search && search.length > 0
      );

      const { data, error } = await SharedService.getCustomerList(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }
      const sortedEntries = (data.customers || defaultState.entries)
        .filter((entry) => !entry.is_deleted)
        .map((entry) => {
          (entry.customer_branches || []).sort((a, b) =>
            Intl.Collator().compare(a.location || "", b.location || "")
          );
          (entry.customer_branches || [])
            .filter((customer) => !customer.is_deleted)
            .map((customer) => {
              (customer.routes || [])
                .sort((a, b) =>
                  Intl.Collator().compare(a.name || "", b.name || "")
                )
                .filter((route) => !route.is_deleted);
              return customer;
            });
          return entry;
        });

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: sortedEntries,
        totalEntries: data.count || defaultState.totalEntries,
        customers: data.customers || defaultState.customers,
      }));
      return data;
    },
    []
  );

  const fetchIcEntries = useCallback(
    async (search, pageSize, pageNumber, order, orderBy, pageFilters = []) => {
      if (fetchController) fetchController.abort(); // Abort any ongoing request
      fetchController = new AbortController();
      const signal = fetchController.signal;

      setState((prevState) => ({
        ...prevState,
        isIcFetching: true,
      }));
      const searchArray = getSearchArray(search, "contractor");

      const filters_is_active = [
        { field: "is_active", type: "=", value: true },
      ];

      let filtersArr = [
        ...filters_is_active,
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      const queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );

      try {
        const { data, error } = await Service.getICData(queryString, {
          signal,
        });
        if (signal.aborted) {
          return;
        }
        if (error) {
          setState((prevState) => ({
            ...prevState,
            isIcFetching: false,
          }));
          return toast.error(
            Array.isArray(error) ? error[0].message : error.message
          );
        }

        setState((prevState) => ({
          ...prevState,
          isIcFetching: false,
          contractorEntries: data.contractors || defaultState.contractorEntries,
          totalIcEntries: data.count || defaultState.totalEntries,
        }));
        return data;
      } catch (abortError) {
        setState((prevState) => ({
          ...prevState,
          isIcFetching: false,
        }));
      }
    },
    []
  );

  const fetchCustomerEmails = async (search) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCustomerEmails: true,
    }));

    const queryString = search ? `?search=${search}` : "";

    const { data, error } = await Service.getCustomerEmails(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCustomerEmails: defaultState.isFetchingCustomerEmails,
        markCustomerSelection: defaultState.markCustomerSelection,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      areAllCustomerSelected: data.emails.length > 0,
      allCustomerEmails: data.emails,
      isFetchingCustomerEmails: defaultState.isFetchingCustomerEmails,
      searchOptionCustomer: search,
      markCustomerSelection: defaultState.markCustomerSelection,
    }));
  };

  const fetchContractorEmails = async (search) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingContractorEmails: true,
    }));

    const queryString = search ? `?search=${search}` : "";

    const { data, error } = await Service.getContractorEmails(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingContractorEmails: defaultState.isFetchingContractorEmails,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const contractorEmails = data.emails.sort((a, b) => {
      return (b.email ? 1 : 0) - (a.email ? 1 : 0);
    });

    setState((prevState) => ({
      ...prevState,
      areAllContractorSelected: data.emails.length > 0,
      allContractorEmails: contractorEmails,
      isFetchingContractorEmails: defaultState.isFetchingContractorEmails,
      searchOptionContractor: search,
    }));
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.entries, state.tabIndex]);

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.contractorEntries, state.tabIndex]);

  useEffect(() => {
    fetchEntries(
      state.search,
      state.pageNumber,
      state.pageSize,
      state.filters,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.pageNumber,
    state.pageSize,
    state.filters,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchIcEntries(
      state.icSearch,
      state.icPageSize,
      state.icPageNumber,
      state.icOrder,
      state.icOrderBy,
      state.icPageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.icPageNumber, pageSize: state.icPageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchIcEntries,
    state.icSearch,
    state.icPageSize,
    state.icPageNumber,
    state.icOrder,
    state.icOrderBy,
    state.icPageFilters,
  ]);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.BULK_MAILS, {
        search,
        pageNumber: defaultState.pageNumber,
      });
    }, 1000);
  }, []);

  const handleIcSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        icSearch: search,
        icPageNumber: defaultState.icPageNumber,
      }));
      setPageConfig(PAGE_KEYS.BULK_MAILS, {
        icSearch: search,
        icPageNumber: defaultState.icPageNumber,
      });
    }, 1000);
  }, []);

  const handleSelectionChange = (row) => {
    setState((prevState) => {
      const existingRowIndex = prevState.selectedRows.findIndex(
        (selectedId) => selectedId === row.id
      );

      if (existingRowIndex !== -1) {
        const updatedSelectedRows = prevState.selectedRows.filter(
          (selectedId) => selectedId !== row.id
        );

        return {
          ...prevState,
          selectedRows: updatedSelectedRows,
        };
      } else {
        return {
          ...prevState,
          selectedRows: [...prevState.selectedRows, row.id],
        };
      }
    });
  };

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.BULK_MAILS, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.BULK_MAILS, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);
  const handleIcPageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.BULK_MAILS, {
      icPageNumber: pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      icPageNumber: pageNumber,
    }));
  }, []);

  const handleIcPageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.BULK_MAILS, {
      icPageSize: pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      icPageSize: pageSize,
      icPageNumber: defaultState.icPageNumber,
    }));
  }, []);

  const handleIcSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      icOrder: order,
      icOrderBy: fieldObj.field || fieldObj.fieldName,
    }));
  }, []);

  const handleCustomerSelectionChange = (event, data) => {
    if (event.target.checked) {
      const customerBranches = [];

      data &&
        data.customer_branches.forEach((branch) => {
          if (
            branch?.email_addresses &&
            branch?.email_addresses?.emailAddresses &&
            branch?.email_addresses?.emailAddresses.length > 0
          ) {
            const uniqueEmails = Array.from(
              new Set(
                branch?.email_addresses?.emailAddresses
                  ?.filter((email) => email)
                  ?.map((email) => email.trim())
              )
            );

            const customerBranch = {
              customerId: data?.id,
              branchId: branch?.id,
              pdxCompanyId: branch?.pdx_company_id,
              type: "customer",
              customerEmailArray: uniqueEmails,
            };

            customerBranches.push(customerBranch);
          }
        });
      setState((prevState) => ({
        ...prevState,
        selectedCustomerEmails: [
          ...prevState.selectedCustomerEmails.filter(
            (item) =>
              !customerBranches.some(
                (branch) => branch.branchId === item.branchId
              )
          ),
          ...customerBranches,
        ],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedCustomerEmails: prevState.selectedCustomerEmails.filter(
          (item) => item.customerId !== data?.id
        ),
      }));
    }
  };

  const handleEmailSelectionChange = (item) => {
    setState((prevState) => {
      const existingItemIndex = prevState.selectedCustomerEmails.findIndex(
        (selected) =>
          selected.branchId === item.branchId &&
          selected.customerId === item.customerId
      );

      if (existingItemIndex !== -1) {
        const existingItem =
          prevState.selectedCustomerEmails[existingItemIndex];
        const isEmailAlreadySelected = existingItem.customerEmailArray.includes(
          item.email
        );

        if (isEmailAlreadySelected) {
          const updatedEmailArray = existingItem.customerEmailArray.filter(
            (email) => email !== item.email
          );

          const updatedSelectedChild =
            updatedEmailArray.length > 0
              ? prevState.selectedCustomerEmails.map((selected, index) =>
                  index === existingItemIndex
                    ? { ...selected, customerEmailArray: updatedEmailArray }
                    : selected
                )
              : prevState.selectedCustomerEmails.filter(
                  (_, index) => index !== existingItemIndex
                );

          return { ...prevState, selectedCustomerEmails: updatedSelectedChild };
        } else {
          const updatedSelectedChild = prevState.selectedCustomerEmails.map(
            (selected, index) =>
              index === existingItemIndex
                ? {
                    ...selected,
                    customerEmailArray: [
                      ...selected.customerEmailArray,
                      item.email,
                    ],
                  }
                : selected
          );

          return { ...prevState, selectedCustomerEmails: updatedSelectedChild };
        }
      } else {
        return {
          ...prevState,
          selectedCustomerEmails: [
            ...prevState.selectedCustomerEmails,
            { ...item, type: "customer", customerEmailArray: [item.email] },
          ],
        };
      }
    });
  };

  const handleBranchSelectionChange = (event, row) => {
    const filteredEmailArray = row.email_addresses.emailAddresses.filter(
      (email) => email && email.trim() !== ""
    );
    const item = {
      branchId: row?.id,
      pdxCompanyId: row?.pdx_company_id,
      customerId: row?.customer_id,
      customerEmailArray: filteredEmailArray,
      type: "customer",
    };

    setState((prevState) => {
      const existingIndex = prevState.selectedCustomerEmails.findIndex(
        (selectedItem) => selectedItem.branchId === item.branchId
      );

      if (event.target.checked) {
        if (existingIndex !== -1) {
          const updatedSelectedChild = [...prevState.selectedCustomerEmails];
          updatedSelectedChild[existingIndex] = item;
          return {
            ...prevState,
            selectedCustomerEmails: updatedSelectedChild,
          };
        } else {
          return {
            ...prevState,
            selectedCustomerEmails: [...prevState.selectedCustomerEmails, item],
          };
        }
      } else {
        return {
          ...prevState,
          selectedCustomerEmails: prevState.selectedCustomerEmails.filter(
            (selectedItem) => selectedItem.branchId !== item.branchId
          ),
        };
      }
    });
  };

  const isRowEmailsSelected = (currentRowData, selectedCustomerEmails) => {
    const totalBranchesWithEmailsInRow =
      currentRowData?.customer_branches?.reduce((count, branch) => {
        return (
          count +
          (branch?.email_addresses &&
          branch?.email_addresses?.emailAddresses &&
          branch?.email_addresses?.emailAddresses.length > 0
            ? 1
            : 0)
        );
      }, 0);

    const selectedEmailsInRow =
      selectedCustomerEmails.length > 0 &&
      selectedCustomerEmails.filter(
        (item) => item.customerId === currentRowData.id
      ).length;

    return selectedEmailsInRow === totalBranchesWithEmailsInRow;
  };

  const handleTabChange = useCallback((event, tabIndex) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex,
    }));
  }, []);

  const TAB_LIST = [
    { id: 1, label: "Customers" },
    { id: 2, label: "Contractors" },
  ];

  const columnConfig = [
    [
      {
        // headerClassName: classes.editColumns,
        id: "col-edit",
        // noResize: true,
        render: (row) => {
          return (
            <>
              {!!row?.customer_branches.length &&
                row?.customer_branches.some(
                  (branch) =>
                    branch?.email_addresses &&
                    branch?.email_addresses?.emailAddresses &&
                    branch?.email_addresses?.emailAddresses.length > 0
                ) && (
                  <Checkbox
                    className="ml-3"
                    color="primary"
                    size="small"
                    style={{ padding: 0, margin: 0 }}
                    disabled={state.areAllCustomerSelected}
                    onChange={(e) => handleCustomerSelectionChange(e, row)}
                    checked={
                      isRowEmailsSelected(row, state.selectedCustomerEmails) ||
                      state.areAllCustomerSelected
                    }
                  />
                )}
            </>
          );
        },
      },

      {
        headerClassName: classes.nameColumns,
        id: "name",
        label: "Name",
        field: "name",
        canSort: true,
      },
    ],
    [
      {
        headerClassName: classes.editColumns,
        id: "col-edit",
        // noResize: true,
        render: (row) => (
          <>
            {row?.email_addresses?.emailAddresses &&
              row?.email_addresses?.emailAddresses.length > 0 &&
              row?.email_addresses?.emailAddresses && (
                <Checkbox
                  className="ml-3"
                  color="primary"
                  size="small"
                  style={{ padding: 0, margin: 0 }}
                  onChange={(e) => handleBranchSelectionChange(e, row)}
                  disabled={state.areAllCustomerSelected}
                  checked={
                    state.selectedCustomerEmails.some(
                      (selected) =>
                        selected.branchId === row?.id &&
                        selected.customerId === row?.customer_id &&
                        selected.customerEmailArray.length ===
                          row?.email_addresses?.emailAddresses.filter(
                            (email) => email && email.trim() !== ""
                          ).length
                    ) || state.areAllCustomerSelected
                  }
                />
              )}
          </>
        ),
      },

      {
        id: "pdx_company",
        label: "PDX Company",
        fieldName: "pdx_company",
        render: (row) => (
          <Tooltip title={row.pdxCompany?.value || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.pdxCompany?.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "state",
        label: "State",
        field: "state",
        render: (row) => (
          <Typography variant="body2">{row.state?.name}</Typography>
        ),
      },
      {
        id: "location",
        label: "Location",
        field: "location",
      },
    ],
    [
      {
        // headerClassName: classes.editColumns,
        id: "col-edit",
        noResize: true,
        render: (row) => (
          <div className="d-flex f-align-center f-justify-end">
            <Checkbox
              className="ml-3 mr-3"
              color="primary"
              size="small"
              style={{ padding: 0, margin: 0 }}
              disabled={state.areAllCustomerSelected}
              onChange={() => handleEmailSelectionChange(row)}
              checked={
                state.selectedCustomerEmails.some(
                  (selected) =>
                    selected.branchId === row.branchId &&
                    selected.customerId === row.customerId &&
                    selected.customerEmailArray.includes(row.email)
                ) || state.areAllCustomerSelected
              }
            />
          </div>
        ),
      },
      {
        headerClassName: classes.routeColumns,
        id: "email",
        label: "Email Addresses",
        field: "email",
      },
    ],
  ];

  const contractorColumnConfig = [
    {
      // headerClassName: classes.editColumns,
      id: "col-edit",
      // noResize: true,
      render: (row) => {
        return (
          <>
            {!!row.email && (
              <Checkbox
                color="primary"
                size="small"
                style={{ padding: 0, margin: 0 }}
                onChange={() => handleSelectionChange(row)}
                disabled={state.areAllContractorSelected}
                checked={
                  state.areAllContractorSelected ||
                  (state.selectedRows.includes(row.id) &&
                    !state.areAllContractorSelected)
                }
              />
            )}
          </>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      field: "email",
      canSort: true,
      hasEllipses: true,
    },
    {
      headerClassName: classes.icidFlagTitle,
      id: "icid",
      label: "ICID",
      field: "icid",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.icid} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.icid}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      headerClassName: classes.tableHeaderCell,
      id: "last_name",
      label: "Last Name",
      field: "last_name",
      canSort: true,
      hasEllipses: true,
    },
    {
      headerClassName: classes.tableHeaderCell,
      id: "first_name",
      label: "First Name",
      field: "first_name",
      canSort: true,
      hasEllipses: true,
    },

    {
      headerClassName: classes.tableHeadCell,
      id: "ic_company_name",
      label: "IC Company Name",
      field: "ic_company_name",
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "state",
      label: "State",
      field: "state",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.state?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "city",
      label: "City",
      field: "city",
      canSort: true,
      hasEllipses: true,
    },

    {
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.pdxCompany?.value ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      headerClassName: classes.tableHeaderWidth,
      id: "customer_name",
      label: "Default Customer",
      field: "customer_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.customer?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.customer?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleSubmit = async (
    customerEmailArray,
    icEmailArray,
    areAllCustomersSelected,
    areAllContractorsSelected,
    ccEmailArray,
    subject,
    body,
    attachment,
    search,
    icSearch
  ) => {
    setState((prevState) => ({ ...prevState, isBulkEmailSending: true }));

    let emailArray = [];
    let emailType;
    const hasCustomer = customerEmailArray.length > 0;
    const hasContractor = icEmailArray.length > 0;

    if (hasCustomer && !hasContractor) {
      emailType = "customer";
    } else if (hasContractor && !hasCustomer) {
      emailType = "contractor";
    } else if (hasCustomer && hasContractor) {
      emailType = "all";
    }

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email_body", body);
    formData.append("emailType", emailType);

    if (areAllCustomersSelected) {
      formData.append("areAllCustomersSelected", areAllCustomersSelected);
    }

    if (areAllContractorsSelected) {
      formData.append("areAllContractorsSelected", areAllContractorsSelected);
    }
    if (search) {
      formData.append("searchOptionCustomer", search);
    }
    if (icSearch) {
      formData.append("searchOptionContractor", icSearch);
    }

    if (!areAllCustomersSelected && !areAllContractorsSelected) {
      emailArray = [...customerEmailArray, ...icEmailArray];
      emailArray.forEach((emailObj, index) => {
        formData.append(`emailArray[${index}][type]`, emailObj.type);
        if (emailObj.customerId) {
          formData.append(
            `emailArray[${index}][customerId]`,
            emailObj.customerId
          );
        }

        if (emailObj.type === "customer") {
          emailObj.customerEmailArray.forEach((email, emailIndex) => {
            formData.append(
              `emailArray[${index}][customerEmails][${emailIndex}]`,
              email
            );
          });
          formData.append(`emailArray[${index}][branchId]`, emailObj.branchId);
        } else if (emailObj.type === "contractor") {
          formData.append(`emailArray[${index}][icEmail]`, emailObj.icEmail);
          formData.append(`emailArray[${index}][icId]`, emailObj.icId);
        }
      });
    }

    if (ccEmailArray) {
      ccEmailArray.forEach((email, index) => {
        formData.append(`ccEmailArray[${index}]`, email);
      });
    }

    if (attachment) {
      attachment.forEach((file, index) => {
        formData.append(`uploadedAttachment`, file);
      });
    }

    const { error } = await Service.sendBulkMails(formData);

    if (error) {
      setState((prevState) => ({ ...prevState, isBulkEmailSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success("Email sent successfully.");
    setState((prevState) => ({
      ...prevState,
      isBulkEmailSending: false,
      showSendBulkMailPopup: false,
      selectedCustomerEmails: defaultState.selectedCustomerEmails,
      ccEmailArray: defaultState.ccEmailArray,
      from: defaultState.from,
      emailSubject: defaultState.emailSubject,
      emailBody: defaultState.emailBody,
      selectedRows: defaultState.selectedRows,
      filteredIcEmails: defaultState.filteredIcEmails,
      areAllCustomerSelected: defaultState.areAllCustomerSelected,
      areAllContractorSelected: defaultState.areAllContractorSelected,
      allCustomerEmails: defaultState.allCustomerEmails,
      allContractorEmails: defaultState.allContractorEmails,
      uploadedAttachment: defaultState.uploadedAttachment,
      searchOptionCustomer: defaultState.searchOptionCustomer,
      searchOptionContractor: defaultState.searchOptionContractor,
    }));
  };

  useEffect(() => {
    const updatedFilteredIcEmails = state.selectedRows.reduce((acc, rowId) => {
      const existingEmail = state.filteredIcEmails.find(
        (item) => item.icId === rowId
      );
      const newEmail = state.contractorEntries.find(
        (item) => item.id === rowId
      );

      if (existingEmail) {
        acc.push(existingEmail);
      } else if (newEmail) {
        acc.push({
          icId: newEmail?.id,
          icEmail: newEmail?.email,
          customerId: newEmail?.default_customer_id || null,
          pdxCompanyId: newEmail?.pdx_company_id || null,
          type: "contractor",
        });
      }

      return acc;
    }, []);

    setState((prevState) => ({
      ...prevState,
      filteredIcEmails: updatedFilteredIcEmails,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedRows, state.contractorEntries]);

  const allEmails = state.selectedCustomerEmails.flatMap(
    (customer) => customer.customerEmailArray
  );
  const uniqueEmails = [...new Set(allEmails)];

  return (
    <div className="mr-5" id="numbers-page-wrapper">
      <div
        className={clsx("mr-10", classes.fixedHeader, classes.fixedHeaderOne)}
      >
        <Typography variant="h4" color="primary" className=" ml-2">
          Send Bulk Email
          <Button
            style={{ float: "right" }}
            className="mt-1"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Send bulk emails to customers or contractors.
        </Typography>
      </div>

      <div
        className={clsx(
          "d-flex f-justify-between f-align-center mb-2",
          classes.fixedHeader,
          classes.fixedHeaderTwo
        )}
      >
        <div>
          <Button
            startIcon={<PlaylistAddIcon />}
            variant="outlined"
            className="mr-2 mt-2"
            color="primary"
            disabled={
              (!state.filteredIcEmails.length &&
                !state.selectedCustomerEmails.length &&
                !state.allCustomerEmails.length &&
                !state.allContractorEmails.length) ||
              state.isFetchingCustomerEmails ||
              state.isFetchingContractorEmails
            }
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showSendBulkMailPopup: true,
              }))
            }
          >
            Send Email
          </Button>
          <Button
            startIcon={<RotateLeftIcon />}
            variant="outlined"
            className="mr-2 mt-2"
            color="primary"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showSendBulkMailPopup: false,
                email: defaultState.email,
                ccEmail: defaultState.ccEmail,
                selectedCustomerEmails: defaultState.selectedCustomerEmails,
                selectedRows: defaultState.selectedRows,
                filteredIcEmails: defaultState.filteredIcEmails,
                areAllCustomerSelected: defaultState.areAllCustomerSelected,
                areAllContractorSelected: defaultState.areAllContractorSelected,
                allCustomerEmails: defaultState.allCustomerEmails,
                allContractorEmails: defaultState.allContractorEmails,
                emailSubject: defaultState.emailSubject,
                emailBody: defaultState.emailBody,
                uploadedAttachment: defaultState.uploadedAttachment,
                searchOptionCustomer: defaultState.searchOptionCustomer,
                searchOptionContractor: defaultState.searchOptionContractor,
                errors: {
                  email: " ",
                  ccEmail: " ",
                  from: " ",
                  emailSubject: " ",
                  emailBody: " ",
                },
              }))
            }
          >
            Reset Selection
          </Button>
        </div>

        <div className="d-flex ml-10 mt-2"></div>

        <div>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2 mt-2"
            startIcon={<HistoryIcon />}
            onClick={() => history.push("bulk-email/history")}
          >
            View Email History
            {state.isLoading && (
              <CircularProgress size={24} className={classes.progressBtn} />
            )}
          </Button>
        </div>
      </div>

      <div
        className={clsx(
          "mt-1 mb-1",
          classes.fixedHeader,
          classes.fixedHeaderThree
        )}
        style={{ textAlign: "center" }}
      >
        <Typography variant="h6" className="mb-1 mt-1">
          To proceed with sending emails, please select the appropriate email
          recipients.
        </Typography>
        <Typography variant="h6" className="mb-1 mt-1">
          You can choose customer emails, contractor emails, or both depending
          on your needs.
        </Typography>
      </div>

      <Tabs
        value={state.tabIndex}
        onChange={handleTabChange}
        indicatorColor={"#eeeeee"}
        textColor={"primary"}
        // classes={{
        //   indicator: classes.indicator,
        // }}
        style={{
          marginTop: "0.8rem",
          background: "#eeeeee",
          maxWidth: "20rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          border: "2px solid primary",
        }}
      >
        {TAB_LIST.map((tab) => (
          <Tab
            value={tab.id}
            key={tab.id}
            name={tab.id}
            style={{
              paddingBottom: 0,
              borderTop:
                tab.id === state.tabIndex ? "4px solid #80808045" : "none",
              borderLeft:
                tab.id === state.tabIndex ? "4px solid #80808045" : "none",
              borderRight:
                tab.id === state.tabIndex ? "4px solid #80808045" : "none",
            }}
            label={
              <Box
                // component={Paper}
                elevation={tab.id === state.tabIndex ? 8 : 0}
                style={{
                  px: "42px",
                  py: "7px",
                  backgroundColor: "grey.50",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  color={
                    tab.id === state.tabIndex ? "primary.dark" : "grey.300"
                  }
                >
                  {tab.label}
                </Typography>
              </Box>
            }
          />
        ))}
      </Tabs>

      {state.tabIndex === 1 && (
        <Paper elevation={2} className={clsx("p-4", classes.paperSpacing)}>
          <div className="d-flex f-align-center f-justify-between pt-2 pb-4">
            <Typography className={classes.heading}>
              Select Customers to Send Emails
            </Typography>
            <div className="d-flex f-align-center">
              <Typography variant="body1">Enter to search by:</Typography>
              <TextField
                type="text"
                variant="outlined"
                className="ml-2"
                size="small"
                defaultValue={state.search}
                placeholder="Name,State,Location"
                InputProps={{
                  endAdornment: <SearchIcon />,
                  type: "search",
                }}
                onChange={(evt) => {
                  handleSearch((evt.target.value || "").trim());
                  if (state.areAllCustomerSelected) {
                    setState((prevState) => ({
                      ...prevState,
                      areAllCustomerSelected:
                        defaultState.areAllCustomerSelected,
                    }));
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
              />
            </div>
            <Button
              variant={
                state.areAllCustomerSelected &&
                state.allCustomerEmails.length > 0
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              disableRipple
              disabled={state.isFetchingCustomerEmails}
              onClick={(e) => {
                e.stopPropagation();
                if (state.areAllCustomerSelected) {
                  setState((prevState) => ({
                    ...prevState,
                    areAllCustomerSelected: defaultState.areAllCustomerSelected,
                    allCustomerEmails: defaultState.allCustomerEmails,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    markCustomerSelection: true,
                  }));
                }
              }}
            >
              Select All
            </Button>
          </div>
          <Grid
            columns={columnConfig}
            rows={state.entries}
            childIdentifier={["customer_branches", "email_addresses"]}
            isLoading={state.isFetching}
            totalRows={state.totalEntries}
            pageSize={state.pageSize}
            pageNumber={state.pageNumber}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            classes={{
              gridActions: "f-justify-end",
            }}
            onReady={(instance) => (gridHelper = instance)}
          />
        </Paper>
      )}

      {state.tabIndex === 2 && (
        <Paper elevation={2} className={clsx("p-4", classes.paperSpacing)}>
          <div className="d-flex f-align-center f-justify-between pt-2 pb-4">
            <Typography className={classes.heading}>
              Select Contractors to Send Emails
            </Typography>
            <div className="d-flex f-align-center">
              <Typography variant="body1">Enter to search by:</Typography>
              <TextField
                type="text"
                variant="outlined"
                className="ml-2"
                size="small"
                defaultValue={state.icSearch}
                placeholder="Name, ICID, IC Co. Name"
                InputProps={{
                  endAdornment: <SearchIcon />,
                  type: "search",
                }}
                onChange={(evt) => {
                  handleIcSearch((evt.target.value || "").trim());
                  if (state.areAllContractorSelected) {
                    setState((prevState) => ({
                      ...prevState,
                      areAllContractorSelected:
                        defaultState.areAllContractorSelected,
                    }));
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
              />
            </div>
            <Button
              variant={
                state.areAllContractorSelected &&
                state.allContractorEmails.length > 0
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              disableRipple
              disabled={state.isFetchingContractorEmails}
              onClick={(e) => {
                e.stopPropagation();
                if (state.areAllContractorSelected) {
                  setState((prevState) => ({
                    ...prevState,
                    areAllContractorSelected:
                      defaultState.areAllContractorSelected,
                    allContractorEmails: defaultState.allContractorEmails,
                  }));
                } else {
                  fetchContractorEmails(state.icSearch);
                }
              }}
            >
              Select All
              {state.isFetchingContractorEmails && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
          <DataGrid
            columns={contractorColumnConfig}
            rows={state.contractorEntries}
            totalRows={state.totalIcEntries}
            actionBarConfig={null}
            isLoading={state.isIcFetching}
            onReady={(instance) => (gridHelper = instance)}
            pageSize={state.icPageSize}
            pageNumber={state.icPageNumber}
            order={state.icOrder}
            orderBy={state.icOrderBy}
            onPageNumberChange={handleIcPageNumberChange}
            onPageSizeChange={handleIcPageSizeChange}
            onSortChange={handleIcSortChange}
            hasSelection={false}
          />
        </Paper>
      )}

      {state.showSendBulkMailPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.invoiceSentPaperSize,
          }}
          open={!!state.showSendBulkMailPopup}
          contentText={
            <div style={{ overflow: "hidden" }}>
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                Send Emails
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value={"noreply@pdxdelivers.com"}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  To
                </Typography>

                <FormControl
                  className="d-flex"
                  style={{
                    gap: "6px",
                    width: "100%",
                  }}
                >
                  {((state.selectedCustomerEmails &&
                    state.selectedCustomerEmails.length !== 0) ||
                    (state.allCustomerEmails &&
                      state.allCustomerEmails.length !== 0)) && (
                    <div className="mt-1 mb-2">
                      <FormLabel>Customer's Email(s)</FormLabel>
                      <div className={classes.textChip}>
                        <Autocomplete
                          disableClearable
                          fullWidth
                          size="small"
                          options={[]}
                          className="mt-3"
                          freeSolo
                          multiple
                          defaultValue={
                            state.areAllCustomerSelected
                              ? state.allCustomerEmails
                              : uniqueEmails
                          }
                          disabled
                          renderTags={(value, props) =>
                            value.map((e) => (
                              <Chip
                                key={e}
                                label={e}
                                className={classes.emailChip}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              className={classes.noBorder}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {((state.filteredIcEmails &&
                    state.filteredIcEmails.length !== 0) ||
                    (state.allContractorEmails &&
                      state.allContractorEmails.length !== 0)) && (
                    <div className="mt-1 mb-2">
                      <FormLabel>Contractor's Email(s)</FormLabel>
                      <div className={classes.textChip}>
                        <Autocomplete
                          disableClearable
                          fullWidth
                          size="small"
                          options={[]}
                          className="mt-3"
                          freeSolo
                          multiple
                          defaultValue={
                            state.areAllContractorSelected
                              ? state.allContractorEmails
                              : state.filteredIcEmails
                          }
                          disabled
                          renderTags={(value, props) =>
                            value.map((e) => (
                              <Chip
                                key={typeof e === "string" ? e : e.icEmail}
                                label={typeof e === "string" ? e : e.icEmail}
                                className={classes.emailChip}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              className={classes.noBorder}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </FormControl>
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Cc
                </Typography>
                <TextField
                  label="Add Email ID(s)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="ccEmail"
                  value={state.ccEmail}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.ccEmail?.trim()}
                  helperText={state.errors.ccEmail}
                  disabled={state.isBulkEmailSending}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    startAdornment: (
                      <div className={classes.chipsContainer}>
                        {state.ccEmailArray.map((email) => (
                          <Chip
                            key={email}
                            disabled={state.isBulkEmailSending}
                            label={email}
                            onDelete={() =>
                              handleDeleteEmail(email, "ccEmailArray")
                            }
                            className={classes.emailChip}
                          />
                        ))}
                      </div>
                    ),
                  }}
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  required
                  label="Add Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  disabled={state.isBulkEmailSending}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  required
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  minRows={10}
                  maxRows={10}
                  multiline
                  disabled={state.isBulkEmailSending}
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
              <UploadFiles
                uploadedAttachment={uploadedAttachment}
                isLoading={state.isBulkEmailSending}
                isBulkEmail={true}
              />
            </div>
          }
          onConfirm={() =>
            handleSubmit(
              state.areAllCustomerSelected
                ? state.allCustomerEmails
                : state.selectedCustomerEmails,
              state.areAllContractorSelected
                ? state.allContractorEmails
                : state.filteredIcEmails,
              state.areAllCustomerSelected,
              state.areAllContractorSelected,
              state.ccEmailArray,
              state.emailSubject,
              state.emailBody,
              state.uploadedAttachment,
              state.searchOptionCustomer,
              state.searchOptionContractor
            )
          }
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              showSendBulkMailPopup: false,
              email: defaultState.email,
              ccEmail: defaultState.ccEmail,
              areAllCustomerSelected: defaultState.areAllCustomerSelected,
              areAllContractorSelected: defaultState.areAllContractorSelected,
              allCustomerEmails: defaultState.allCustomerEmails,
              allContractorEmails: defaultState.allContractorEmails,
              emailSubject: defaultState.emailSubject,
              emailBody: defaultState.emailBody,
              uploadedAttachment: defaultState.uploadedAttachment,
              searchOptionCustomer: defaultState.searchOptionCustomer,
              searchOptionContractor: defaultState.searchOptionContractor,
              errors: {
                email: " ",
                ccEmail: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            (validate() && validate() !== " ") || state.isBulkEmailSending
          }
          positiveActionLabel={
            <>
              SEND EMAILS
              {state.isBulkEmailSending && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.markCustomerSelection && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={state.markCustomerSelection}
          contentText={
            "Only customers with valid email addresses will be selected."
          }
          onConfirm={() => fetchCustomerEmails(state.search)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              markCustomerSelection: false,
              isFetchingCustomerEmails: false,
            }))
          }
          isConfirmDisabled={state.isFetchingCustomerEmails}
          positiveActionLabel={
            <>
              Select
              {state.isFetchingCustomerEmails && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </div>
  );
};

export default ViewBulkMail;
