import { useState } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "./style";
import { TableContainer, TableHead } from "@material-ui/core";

const CollapsibleGrid = ({
  columns = [],
  rows = [],
  childIdentifier = [],
  rowEvents = [],
  classes: passedClasses = {},
  rowIndex = null,
  onReady = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({ open: false });

  onReady({
    toggle: (open = false) => {
      setState((prevState) => ({
        ...prevState,
        open,
      }));
    },
  });

  return (
    <>
      <TableContainer
        className={clsx(classes.tableContainer, {
          [classes.container]: childIdentifier.length && rows.length > 0,
        })}
      >
        <Table>
          <TableHead>
            <TableRow
              hover
              className={classes.root}
              {...rowEvents.reduce(
                (acc, { type, handler }) => ({
                  ...acc,
                  [type]: () => handler(rows, rowIndex, 1),
                }),
                {}
              )}
            >
              <TableCell padding="checkbox" className="no-border ml-4">
                {childIdentifier.length > 0
                  ? !!rows[childIdentifier[0]]?.length && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            open: !prevState.open,
                          }))
                        }
                      >
                        {state.open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    )
                  : !!rows?.length && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            open: !prevState.open,
                          }))
                        }
                      >
                        {state.open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    )}
              </TableCell>
              {columns[0].map((column, columnIndex) => {
                if (column.isHidden) {
                  return null;
                }
                let content = (
                  <Typography
                    className="d-inline-block w-100"
                    variant="body2"
                    component="span"
                    noWrap={column.hasEllipses}
                  >
                    {column.label || ""}
                  </Typography>
                );
                if (column.render) {
                  content = column.render(rows, rowIndex);
                }

                return (
                  <TableCell
                    className={clsx(
                      "no-border",
                      classes.tableCell,
                      column.className,
                      classes.tableHeadPadding
                    )}
                    key={`${column.label || "grid-column"}-${columnIndex}${
                      rows.id
                    }`}
                  >
                    <Tooltip
                      title={column.label || ""}
                      placement={column.dataTooltipPlacement || "top-start"}
                    >
                      {content}
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={columns[0].length + 1}
                className={clsx("pl-0 pr-0 pb-0 pt-0", {
                  [classes.customerMainSeparatorBorder]: state.open,
                })}
              >
                <Collapse in={state.open} timeout="auto" unmountOnExit>
                  <Table>
                    <TableBody>
                      {childIdentifier.length > 0
                        ? rows[childIdentifier[0]]
                            ?.sort((a, b) =>
                              a.rateType.value.localeCompare(b.rateType.value)
                            )
                            ?.map((row, childRowIndex) => {
                              const childColumns = columns[1] || [];
                              return (
                                <TableRow
                                  hover
                                  key={row.id}
                                  className={clsx(
                                    classes.row,
                                    passedClasses.row,
                                    row.className
                                  )}
                                  {...rowEvents.reduce(
                                    (acc, { type, handler }) => ({
                                      ...acc,
                                      [type]: () =>
                                        handler(row, childRowIndex, 2),
                                    }),
                                    {}
                                  )}
                                >
                                  <TableCell padding="checkbox"></TableCell>
                                  {childColumns.map((column, columnIndex) => {
                                    let content = (
                                      <span>{row[column.fieldName] || ""}</span>
                                    );
                                    if (column.render) {
                                      content = column.render(
                                        row,
                                        childRowIndex
                                      );
                                    }

                                    return (
                                      <TableCell
                                        className={clsx(
                                          classes.tableCellWidth,
                                          classes.tablePadding
                                        )}
                                        key={`${
                                          column.label || "grid-column"
                                        }-${columnIndex}${row.id}`}
                                      >
                                        <Typography
                                          style={{ fontWeight: 400 }}
                                          variant="body2"
                                          noWrap
                                        >
                                          {content}
                                        </Typography>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                        : rows?.map((row, childRowIndex) => {
                            const childColumns = columns[1] || [];
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                className={clsx(
                                  classes.row,
                                  passedClasses.row,
                                  row.className
                                )}
                                {...rowEvents.reduce(
                                  (acc, { type, handler }) => ({
                                    ...acc,
                                    [type]: () =>
                                      handler(row, childRowIndex, 2),
                                  }),
                                  {}
                                )}
                              >
                                <TableCell padding="checkbox"></TableCell>
                                {childColumns.map((column, columnIndex) => {
                                  if (column.isHidden) {
                                    return null;
                                  }
                                  let content = (
                                    <span>{row[column.fieldName] || ""}</span>
                                  );
                                  if (column.render) {
                                    content = column.render(row, childRowIndex);
                                  }

                                  return (
                                    <TableCell
                                      className={clsx(
                                        classes.tableCellWidth,
                                        classes.tablePadding
                                      )}
                                      key={`${
                                        column.label || "grid-column"
                                      }-${columnIndex}${row.id}`}
                                    >
                                      <Typography
                                        style={{ fontWeight: 400 }}
                                        variant="body2"
                                        noWrap
                                      >
                                        {content}
                                      </Typography>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CollapsibleGrid;
