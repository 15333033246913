import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  paperSize: {
    width: 416,
  },
  colEdit: {
    width: "98px",
  },
  emptyCell: {
    width: "35px",
  },
  deleteAddress: {
    width: 370,
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  locationInputRoot: {
    // maxHeight: 190,
    overflow: "auto !important",
    maxWidth: 684,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 244,
    },
  },
  locationInput: {
    minWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
    },
  },
  addLocation: {
    width: 575,
  },
  gridPaper: { minHeight: 120, maxHeight: 240, overflow: "hidden" },
  paper: {
    minWidth: 720,
    maxWidth: 600,
    minHeight: 120,
    maxHeight: 404,
  },
  noRecordsImg: {
    maxHeight: 187,
  },
  paperSpacing: {
    // marginTop: "14px !important",
    paddingTop: "7px !important",
    paddingBottom: "0px !important ",
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  fuelSurchargeHeader: {
    minWidth: "164px",
  },
  routeColumns: {
    minWidth: "92px",
    maxWidth: "92px",
  },
  editColumns: {
    width: "0%",
  },
  nameColumns: {
    width: "100%",
  },
  routerateTypesColumns: {
    minWidth: "148px",
  },
  invoiceTypeHeader: {
    minWidth: "116px",
  },
  fuelTypeHeader: {
    minWidth: "148px",
  },
  fsReductionHeader: {
    minWidth: "164px",
  },
  extraMilesheader: {
    minWidth: "188px",
  },
  editAddress: { width: "20px", paddingRight: "0px !important" },
  fixedHeader: {
    width: "100%",

    height: "64px",

    position: "sticky",
    background: "#fafafa",
  },
  fixedHeaderOne: {
    top: "64px",
    zIndex: 1000,
  },
  fixedHeaderTwo: {
    top: "128px",
    zIndex: 999,
  },
  fixedHeaderThree: {
    top: "192px",
    zIndex: 998,
  },
  dialogPaper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
  },
  isTotal: {
    backgroundColor: "#eaf6fb",
  },
  invoiceSentPaperSize: {
    minWidth: 800,
    width: 800,
    "& .MuiDialogContent-root": {
      paddingTop: "0px",
    },
    minHeight: 120,
    maxHeight: 800,
    overflowY: "auto",
  },
  buttonStyle1: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "16px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    marginBottom: theme.spacing(1),
    maxHeight: 100, // Set a max height (adjust as needed)
    overflowY: "auto", // Enable vertical scrolling
  },
  inputRoot: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  emailChip: {
    marginRight: "4px",
    marginBottom: "4px",
    marginTop: "6px",
  },
  accordianPaperSpacing: {
    // marginTop: "2px",
    height: "calc(100vh - 20rem)",
    overflowY: "auto",
    overflowX: "hidden",
    paddingBottom: 10,
    paddingRight: "4px",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  textChip: {
    maxHeight: 200,
    width: "100%",
    border: "1px solid #bdbdbd",
    borderRadius: "6px",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  noBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
}));

export default useStyles;
