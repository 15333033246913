import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/base-rosters${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getBREntries = (query) => {
  return responseFormatter(
    http.get(`/base-roster/kams-customers-with-locations/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const deleteEntry = (entryIds) => {
  return responseFormatter(
    http.delete(`/base-rosters?recordIds=${entryIds}`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/base-roster", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data, id) => {
  return responseFormatter(
    http.put(
      `/base-roster/`,
      { ...data, id },
      {
        setAuthHeader: true,
      }
    )
  );
};

const markActiveInactive = (data) => {
  return responseFormatter(
    http.patch(`/base-roster-update-status/`, data, {
      setAuthHeader: true,
    })
  );
};

const priceMatrix = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getDriverList = (query) => {
  return responseFormatter(
    http.get(`/ic/wcp-driver${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const BaseRosterService = {
  get,
  delete: deleteEntry,
  getCustomerLocation,
  post,
  patch,
  markActiveInactive,
  getLocalResources,
  priceMatrix,
  getBREntries,
  getDriverList,
};

export default BaseRosterService;
