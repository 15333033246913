import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  Fragment,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import FlagIcon from "@material-ui/icons/Flag";
import {
  exportBase64ToFile,
  getFormattedTime,
  updatePageConfig,
  useOnScreen,
  validator,
} from "utils";
import { useLocation } from "react-router-dom";
import { Datepicker, Grid } from "shared/components";
import { CollapsibleGrid } from "../../shared/components";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Dropdown, FilterComponent } from "shared/components";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { AppContext } from "shared/contexts";
import { ROLE, RATE_TYPES } from "modules/shared/constants";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TuneIcon from "@material-ui/icons/Tune";
import { ActionDialog } from "shared/components";
import { VALIDATIONS } from "shared/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { noop, PAGE_KEYS } from "shared/constants";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Service from "../service";
import SharedService from "../../shared/service";
import { InlineFieldForm } from "modules/shared/components";
import WarningIcon from "@material-ui/icons/Warning";

import {
  getDateString,
  getTokenData,
  queryStringBuilderNew,
  getUTCDateString,
  getPageConfig,
  setPageConfig,
} from "utils";
import useStyles from "./style";
import { Checkbox } from "@material-ui/core";
import IcForm from "modules/shared/components/ic-form";

const defaultState = {
  entries: [],
  totalEntries: 0,
  isFetching: false,
  selectedRows: [],
  scheduleDate: null,
  kam: null,
  manager: null,
  stateList: [],
  pdxCompanyList: [],
  AddReviewNote: false,
  errors: {
    notes: " ",
    reviewNotes: "",
  },
  notes: "",
  reviewNotes: "",
  customer: null,
  icDetails: {},
  showIcDetails: false,
  selectedLocation: null,
  editingNote: {},
  isFiltering: false,
  pageFilters: [],
  vehicleTypeList: [],
  settlementCompanyList: [],
  locationList: [],
  customerlocationList: [],
  showConfirmationPopup: false,
  doNotBillRowID: 0,
  doNotBillExtraMilesID: 0,
  doNotBillTollID: 0,
  doNotPayExtraMilesID: 0,
  doNotPayTollID: 0,
  confirmDontBill: false,
  confirmDontBillValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  payQuantityExceedDaily: null,
  confirmDontPay: false,
  isIcFetching: false,
  confirmDontPayValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  isFetchingCautionFlagTypeList: false,
  isFetchingPriceMatrix: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,
  priceMatrix: [],
  cautionFlagSubmitReasonList: [],
  doNotBillType: null,
  doNotPayRowID: 0,
  doNotpayType: null,
  doNotpayTollType: null,
  doNotBillTollType: null,
  clickedRowId: null,
  clickedRowCustomerName: null,
  doNotpayMilesType: null,
  doNotBillMilesType: null,
  isDownloadLoading: false,
  showRejectConfirmationPopup: false,
  showAddLookupDialog: false,
  isLookupLoading: false,
  reviewNotesList: [],
  isRowOpen: false,
  dimensions: 0,
  selectedRowId: 0,
  standByRateTypeList: [],
  contractorList: [],
  isFetchingList: {
    pdxCompany: false,
    reviewNotes: false,
    standByRateTypes: false,
  },
  fetching: {
    users: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    row_type: {
      label: "Schedule Status",
      value: "row_type",
      isSelected: true,
    },
    price_type: {
      label: "Price Type",
      value: "price_type",
      isSelected: true,
    },
    dont_bill: {
      label: "Do Not Bill",
      value: "dont_bill",
      isSelected: true,
    },
    dont_pay: {
      label: "Do Not Pay",
      value: "dont_pay",
      isSelected: true,
    },
    dont_bill_miles: {
      label: "Do Not Bill Extra Miles",
      value: "dont_bill_miles",
      isSelected: true,
    },
    dont_pay_miles: {
      label: "Do Not Pay Extra Miles",
      value: "dont_pay_miles",
      isSelected: true,
    },
    dont_bill_tolls: {
      label: "Do Not Bill Toll",
      value: "dont_bill_tolls",
      isSelected: true,
    },
    dont_pay_tolls: {
      label: "Do Not Pay Toll",
      value: "dont_pay_tolls",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
    change_notes: {
      label: "AA/MGR Notes",
      value: "change_notes",
      isSelected: true,
    },
    kam_notes: {
      label: "KAM Notes",
      value: "kam_notes",
      isSelected: true,
    },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    schedule_date: {
      label: "Schedule Date",
      value: "schedule_date",
      isSelected: true,
    },
    customer_name: {
      label: "Customer Name",
      value: "customer_name",
      isSelected: true,
    },
    kam: {
      label: "KAM",
      value: "kam",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },
    WCP_Driver: {
      label: "WCP-Driver",
      value: "WCP_Driver",
      isSelected: true,
    },
    settlement_company: {
      label: "Settlement Company",
      value: "settlement_company",
      isSelected: true,
    },

    bill_rate_type: {
      label: "Billing Rate Type",
      value: "bill_rate_type",
      isSelected: true,
    },

    bill_rate: {
      label: "Billing Rate",
      value: "bill_rate",
      isSelected: true,
    },
    bill_quantity: {
      label: "Billing Quantity",
      value: "bill_quantity",
      isSelected: true,
    },
    bill_amount: {
      label: "Billing Amount",
      value: "bill_amount",
      isSelected: true,
    },
    bill_fuel_surcharge: {
      label: "Billing Fuel Surcharge%",
      value: "bill_fuel_surcharge",
      isSelected: true,
    },
    bill_fuel_amount: {
      label: "Billing Fuel Amount",
      value: "bill_fuel_amount",
      isSelected: true,
    },
    bill_toll_amount: {
      label: "Billing Toll Amount",
      value: "bill_toll_amount",
      isSelected: true,
    },
    bill_extra_miles: {
      label: "Billing Extra Miles",
      value: "bill_extra_miles",
      isSelected: true,
    },
    bill_rate_per_extra_miles: {
      label: "Billing Rate Per Extra Mile",
      value: "bill_rate_per_extra_miles",
      isSelected: true,
    },
    bill_extra_mile_amount: {
      label: "Billing Extra Miles Amount",
      value: "bill_extra_mile_amount",
      isSelected: true,
    },
    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },

    pay_rate: {
      label: "Pay Rate",
      value: "pay_rate",
      isSelected: true,
    },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_amount: {
      label: "Pay Amount",
      value: "pay_amount",
      isSelected: true,
    },
    pay_fuel_surcharge: {
      label: "Pay Fuel Surcharge%",
      value: "pay_fuel_surcharge",
      isSelected: true,
    },
    pay_fuel_amount: {
      label: "Pay Fuel Amount",
      value: "pay_fuel_amount",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    pay_rate_per_extra_miles: {
      label: "Pay Rate Per Extra Mile",
      value: "pay_rate_per_extra_miles",
      isSelected: true,
    },
    pay_extra_mile_amount: {
      label: "Pay Extra Miles Amount",
      value: "pay_extra_mile_amount",
      isSelected: true,
    },
    reimbursement: {
      label: "Incentive",
      value: "reimbursement",
      isSelected: true,
    },
    deduction: {
      label: "Deduction",
      value: "deduction",
      isSelected: true,
    },
    deduction_reason: {
      label: "Deduction Reason",
      value: "deduction_reason",
      isSelected: true,
    },
    office: {
      label: "Office",
      value: "office",
      isSelected: true,
    },
    mgr: {
      label: "Manager",
      value: "mgr",
      isSelected: true,
    },
    profit_percentage: {
      label: "Profit Percentage",
      value: "profit_percentage",
      isSelected: true,
    },
    submitted_by: {
      label: "Submitted By",
      value: "submitted_by",
      isSelected: true,
    },
    updated_by: {
      label: "Last Updated By",
      value: "updated_by",
      isSelected: true,
    },
    updated_at: {
      label: "Last Updated At",
      value: "updated_at",
      isSelected: true,
    },
  },
};

let gridHelper = null;

const ViewBillingSettlementScheduleReview = ({ match, history }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const actionRef = useRef(null);
  const isActionBarOnScreen = useOnScreen(actionRef);
  const pageConfig = getPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW);
  const isAdminRole = (userData.role || "").toLowerCase() === ROLE.ADMIN;
  const { appData, updateContextData } = useContext(AppContext);
  const location = useLocation();
  const data = location.state || {};
  const isRoleMGR = (userData?.role || "").toLowerCase() === ROLE.MGR;
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const tableHeadRef = useRef(null);
  const selectedRef = useRef(null);

  const handleRowOpen = (value) =>
    setState((prevState) => ({
      ...prevState,
      isRowOpen: value || defaultState.isRowOpen,
    }));

  const handleScroll = (event) => {
    const targetDiv = event.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );
    const IC = appData.IC;
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        mgr: [],
        aa: [],
      }
    );

    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      customers,
      users,
      IC,
      icList,
      ICOptions,
    };
  }, [appData]);

  const kamOptions = appDataList.users.kam?.sort(
    (a, b) => b.is_active - a.is_active
  );

  const fetchMasterIcList = async (id) => {
    let queryString = `?filter[where][and][0][or][0][master_ic_id]=${id}`;

    const { data, error } = await SharedService.getIcList(queryString);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      contractorList:
        data?.contractors?.filter((item) => item.id !== id) ||
        defaultState.contractorList,
    }));
  };

  useEffect(() => {
    if (
      appDataList.users?.kam.length &&
      appDataList.customers?.length &&
      data?.kam_id
    ) {
      const kam = appDataList.users?.kam.find((kam) => kam.id == data.kam_id);
      const customer = appDataList.customers.find(
        (customer) => customer.id == data.customer_id
      );

      setState((prevState) => ({
        ...prevState,
        kam,
        customer,
      }));
      window.history.replaceState(null, document.title);
      history.replace({ ...history.location, state: null });
    }
  }, [appDataList.users?.kam, appDataList.customers]);

  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    manager: isRoleMGR
      ? pageConfig?.managerFilter === undefined
        ? userData
        : pageConfig?.managerFilter || defaultState.manager
      : (pageConfig && pageConfig?.managerFilter) || defaultState.manager,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    customer:
      (pageConfig && pageConfig?.customerFilter) || defaultState.customer,
    clickedRowId:
      (pageConfig && pageConfig?.clickedRowId?.id) || defaultState.clickedRowId,
    clickedRowCustomerName:
      (pageConfig && pageConfig?.clickedRowId?.customerName) ||
      defaultState.clickedRowCustomerName,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter) ||
      defaultState.selectedLocation,
    kam: (pageConfig && pageConfig?.kamFilter) || defaultState.kam,
    scheduleDate: data?.schedule_date
      ? getDateString(data.schedule_date)
      : (pageConfig && pageConfig?.scheduleDateFilter) || null,
  });

  useEffect(() => {
    if (tableHeadRef.current) {
      setState((prevState) => ({
        ...prevState,
        dimensions:
          tableHeadRef?.current?.clientWidth +
          tableHeadRef?.current?.clientHeight,
      }));
    }
  }, [state.isRowOpen]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (selectedRef.current && !!state.clickedRowId) {
        gridHelper && gridHelper.toggle(true, state.clickedRowCustomerName);

        setState((prevState) => ({
          ...prevState,
          isRowOpen: [0],
        }));

        requestAnimationFrame(() => {
          setTimeout(() => {
            selectedRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });

            const rowElement = selectedRef.current;
            const rowOffsetTop = rowElement.offsetTop + 91;
            window.scrollTo({
              top: rowOffsetTop,
              behavior: "smooth",
            });

            observer.disconnect();
          }, 0);
        });
      }
    });

    if (document.body) {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
        clickedRowId: null,
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchList = async (listType = "users", callback = noop, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      fetching: {
        ...prevState.fetching,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        fetching: {
          ...prevState.fetching,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((rowA, rowB) => {
            const valueA = (rowA?.name || "").toLowerCase();
            const valueB = (rowB?.name || "").toLowerCase();
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          });
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      fetching: {
        ...prevState.fetching,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      reviewNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt, value) => {
    const field = "notes";
    let inputValue = evt.currentTarget?.value || evt.target?.value;

    if (value) {
      inputValue = value;
    }

    const errorMessage = validate(field, inputValue) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: inputValue.value === undefined ? inputValue : inputValue.value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };
  const handleShowAddLookupDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: true,
      selectedRowId: id,
    }));
  };

  const quantityExceedColumnConfig = useMemo(
    () => [
      {
        id: "schedule_date",
        label: "Schedule Date",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          const scheduleDate = getUTCDateString(row.schedule_date);
          return (
            <Tooltip title={scheduleDate || ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {scheduleDate || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "customer",
        label: "Customer Name",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.customer} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "location",
        label: "Location",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.location} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.location}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "route",
        label: "Route",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.route} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.route}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const filterConfig = useMemo(
    () => [
      // {
      //   field: "location",
      //   fieldToDisplay: "Location",
      //   operatorType: "string",
      //   options: Object.entries(state.customerlocationList)
      //     .map(([state, value]) =>
      //       value.map((item) => ({
      //         label: `${item}-(${state})`,
      //         value: item,
      //       }))
      //     )
      //     .flat()
      //     .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      // },
      {
        field: "customer_route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "price_type",
        fieldToDisplay: "Price Type",
        operatorType: "string",
        options: [
          { label: "Standard", value: "Standard" },
          { label: "Price Matrix", value: "Matrix" },
        ],
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle_type",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList?.map((vehicle) => ({
          ...vehicle,
          label: vehicle.value,
          value: vehicle.value,
        })),
      },
      {
        field: "ic_name",
        fieldToDisplay: "ICID/IC Name",
        operatorType: "string",
        options: appDataList.icList.map((ic) => ({
          ...ic,
          label: `${ic.icid}/${ic.last_name || ""} ${ic.first_name || ""}`,
          value: `${ic.icid}/${ic.last_name || ""} ${ic.first_name || ""}`,
        })),
      },
      {
        field: "driver_name",
        fieldToDisplay: "WCP-Driver",
        operatorType: "contain",
      },
      {
        field: "settlement_co_name",
        fieldToDisplay: "Settlement Company",
        operatorType: "string",
        options: state.settlementCompanyList.map((company) => ({
          ...company,
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "upd_bill_rate_type",
        fieldToDisplay: "Billing Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "Daily" },
          { label: "Hourly", value: "Hourly" },
          { label: "Per Miles", value: "Per Miles" },
          { label: "Per Tire", value: "Per Tire" },
          { label: "Per Run", value: "Per Run" },
        ],
      },
      {
        field: "upd_bill_rate",
        fieldToDisplay: "Billing Rate",
        operatorType: "number",
      },
      {
        field: "upd_bill_quantity",
        fieldToDisplay: "Billing Quantity",
        operatorType: "number",
      },
      {
        field: "bill_amount",
        fieldToDisplay: "Billing Amount",
        operatorType: "number",
      },
      {
        field: "bill_fuel_surcharge",
        fieldToDisplay: "Billing Fuel Surcharge%",
        operatorType: "number",
      },
      {
        field: "bill_fuel_amount",
        fieldToDisplay: "Billing Fuel Amount",
        operatorType: "number",
      },
      {
        field: "upd_bill_toll_amount",
        fieldToDisplay: "Billing Toll Amount",
        operatorType: "number",
      },
      {
        field: "bill_extra_miles",
        fieldToDisplay: "Billing Extra Miles",
        operatorType: "number",
      },
      {
        field: "bill_rate_per_extra_mile",
        fieldToDisplay: "Billing Rate Per Extra Mile",
        operatorType: "number",
      },
      {
        field: "bill_extra_mile_amount",
        fieldToDisplay: "Billing Extra Miles Amount",
        operatorType: "number",
      },

      {
        field: "upd_pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: Array.from(
          new Map(
            [
              { label: "Daily", value: "Daily" },
              { label: "Hourly", value: "Hourly" },
              { label: "Per Miles", value: "Per Miles" },
              { label: "Per Tire", value: "Per Tire" },
              { label: "Per Run", value: "Per Run" },
              ...state.standByRateTypeList?.map((standRateType) => ({
                label: standRateType.value,
                value: standRateType.value,
              })),
            ].map((item) => [item.value, item])
          ).values()
        ),
      },
      {
        field: "upd_pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "number",
      },
      {
        field: "upd_pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "number",
      },
      {
        field: "pay_amount",
        fieldToDisplay: "Pay Amount",
        operatorType: "number",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "number",
      },
      {
        field: "pay_fuel_amount",
        fieldToDisplay: "Pay Fuel Amount",
        operatorType: "number",
      },
      {
        field: "upd_toll_amount",
        fieldToDisplay: "Pay Toll Amount",
        operatorType: "number",
      },
      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "number",
      },
      {
        field: "pay_rate_per_extra_mile",
        fieldToDisplay: "Pay Rate Per Extra Mile",
        operatorType: "number",
      },
      {
        field: "pay_extra_mile_amount",
        fieldToDisplay: "Pay Extra Miles Amount",
        operatorType: "number",
      },
      {
        field: "reimbursement",
        fieldToDisplay: "Incentive",
        operatorType: "number",
      },
      {
        field: "deduction",
        fieldToDisplay: "Deduction",
        operatorType: "number",
      },
      {
        field: "aa_name",
        fieldToDisplay: "Office",
        operatorType: "string",
        options: appDataList?.users?.aa?.map((office) => ({
          ...office,
          label: `${office.first_name || ""} ${office.last_name || ""}-(${
            office.username
          })`,
          value: `${office.first_name || ""} ${office.last_name || ""}`,
        })),
      },
      {
        field: "manager_name",
        fieldToDisplay: "Manager",
        operatorType: "string",
        options: appDataList?.users?.mgr?.map((manager) => ({
          ...manager,
          label: `${manager.first_name || ""} ${manager.last_name || ""}-(${
            manager.username
          })`,
          value: `${manager.first_name || ""} ${manager.last_name || ""}`,
        })),
      },
      {
        field: "profit_percentage",
        fieldToDisplay: "Profit Percentage",
        operatorType: "number",
      },
      {
        field: "DS_submitted_by",
        fieldToDisplay: "Submitted By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "last_updated_by",
        fieldToDisplay: "Last Updated By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "last_updated_at",
        fieldToDisplay: "Last Updated At",
        operatorType: "range",
        isDateField: true,
      },
    ],
    [
      appDataList,
      state.vehicleTypeList,
      state.settlementCompanyList,
      appDataList.icList,
      state.customerlocationList,
      state.stateList,
      state.pdxCompanyList,
      appDataList?.customers,
      appDataList?.icList,
    ]
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      entries: defaultState.entries,
    }));
    setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
      filters: pageFilters,
    });
  }, []);

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();

    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };
  const fetchReviewNotesList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: true,
      },
    }));

    const { data, error } = await SharedService.getReviewNotesList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          reviewNotes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      reviewNotesList:
        data?.rows.filter((reviewNotes) => reviewNotes.is_active) ||
        defaultState.reviewNotesList,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: false,
      },
    }));
  };
  const newReviewNotes = state.entries.find(
    (entry) => entry.id === state.selectedRowId
  );

  const addLookupValue = async () => {
    setState((prevState) => ({ ...prevState, isLookupLoading: true }));

    const payload = {
      type: "Review Notes",
      value:
        state.notes ||
        (newReviewNotes
          ? newReviewNotes.billing_and_settlement.sharedNotes
          : ""),
      is_active: true,
      description: "hii",
    };

    const { error } = await Service.addNotesInLookup(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLookupLoading: false }));
      if (error.message === "This record already exists.") {
        error.message = "This Value already exists in Lookup(Review Notes).";
      }
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Value added in Lookup(Review Notes) successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLookupLoading: false,
      showAddLookupDialog: false,
    }));
  };
  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchSettlementCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: false,
      },
    }));
  };

  const fetchEntries = useCallback(
    async (
      scheduleDate,
      kam,
      location,
      manager,
      customer,
      pageFilters = [],
      hasLoader = false
    ) => {
      const filters = [
        ...pageFilters.map((filter) => {
          if (filter?.field === "last_updated_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "last_updated_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "gte",
                value: `${startDate}  00:00:00`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();
      scheduleDate &&
        filters.push({
          field: "schedule_date",
          type: "=",
          value: scheduleDate,
        });
      kam &&
        filters.push({
          field: "kam_id",
          type: "=",
          value: kam.id,
        });
      manager &&
        filters.push({ field: "mgr_id", type: "=", value: manager.id });
      customer &&
        filters.push({
          field: "customer_name",
          type: "=",
          value: customer.name,
        });
      location &&
        filters.push({
          field: "location",
          type: "=",
          value: location.value,
        });

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, false);
      if (queryString.includes("last_updated_at_gte")) {
        queryString = queryString.replace(
          /last_updated_at_gte/g,
          "last_updated_at"
        );
      }
      setState((prevState) => ({ ...prevState, isFetching: true }));
      gridHelper && gridHelper.toggleAll(false);
      const { data, error } = await Service.get(queryString);
      if (error) {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        setState((prevState) => ({ ...prevState, isFetching: false }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
        const sortedEntries = (data.rows || defaultState.entries).sort(
          (rowA, rowB) => {
            const rowAcustomerName = (
              rowA?.billing_and_settlement?.customer_branch?.customer?.name ||
              ""
            ).toLowerCase();
            // const rowAlocation = (
            //   rowA?.billing_and_settlement?.customer_branch?.location || ""
            // ).toLowerCase();
            // const rowAroute = (rowA?.billing_and_settlement?.route || "").toLowerCase();
            const rowBcustomerName = (
              rowB?.billing_and_settlement?.customer_branch?.customer?.name ||
              ""
            ).toLowerCase();
            // const rowBlocation = (
            //   rowB?.billing_and_settlement?.customer_branch?.location || ""
            // ).toLowerCase();
            // const rowBroute = (rowB?.billing_and_settlement?.route || "").toLowerCase();
            // const valueA = `${rowAcustomerName}${rowAlocation}${rowAroute}`;
            // const valueB = `${rowBcustomerName}${rowBlocation}${rowBroute}`;
            return Intl.Collator().compare(rowAcustomerName, rowBcustomerName);
            // return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          }
        );

        const mergedRows = (sortedEntries || []).reduce((acc, row) => {
          const {
            billing_and_settlement,
            original_billing_settlement,

            ...remaining_rows
          } = row;
          acc.push({
            ...remaining_rows,
            billing_and_settlement,
            original_billing_settlement,
            notesRow: { ...(billing_and_settlement || {}) },
            keyType: "Updated",
            uniqueKey: billing_and_settlement?.customer_branch,
          });
          acc.push({
            ...remaining_rows,
            original_billing_settlement,
            billing_and_settlement:
              original_billing_settlement !== null
                ? original_billing_settlement
                : {
                    ...original_billing_settlement,
                    customer_branch: billing_and_settlement?.customer_branch,
                  },

            notesRow: { ...(billing_and_settlement || {}) },
            keyType: "original",
            uniqueKey: billing_and_settlement?.customer_branch,
          });
          return acc;
        }, []);

        setState((prevState) => ({
          ...prevState,
          entries:
            mergedRows.map((entry, rowIndex) => ({
              ...entry,
              initialReviewNotes: entry.billing_and_settlement?.review_notes,
            })) || defaultState.entries,

          totalEntries: data.count || defaultState.totalEntries,
          isFetching: false,
        }));
      }
    },
    []
  );

  const fetchStandByRateTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: true,
      },
    }));
    const { data, error } = await SharedService.getstandByRateTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          standByRateTypes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      standByRateTypeList:
        data?.rows.filter((standByRateType) => standByRateType.is_active) ||
        defaultState.standByRateTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: false,
      },
    }));
  };

  const handleDownload = async (
    scheduleDate,
    kam,
    location,
    manager,
    customer,
    pageFilters = [],
    hasLoader = false
  ) => {
    const filters = [
      ...pageFilters.map((filter) => {
        if (filter?.field === "last_updated_at" && filter?.type === "eq") {
          return [
            {
              ...filter,
              field: "last_updated_at",
              type: "lte",
              value: `${filter?.value} 23:59:59.999999`,
            },
            {
              ...filter,
              field: "last_updated_at_gte",
              type: "gte",
              value: `${filter?.value} 00:00:00`,
            },
          ];
        } else if (
          filter?.field === "last_updated_at" &&
          filter?.type === "ra"
        ) {
          const [startDate, endDate] = filter?.value.split(" - ");
          return [
            {
              ...filter,
              field: "last_updated_at",
              type: "gte",
              value: `${startDate}  00:00:00`,
            },
            {
              ...filter,
              field: "last_updated_at_gte",
              type: "lte",
              value: `${endDate} 23:59:59.999999`,
            },
          ];
        } else {
          return {
            ...filter,
            value: filter?.value?.value || filter?.value,
          };
        }
      }),
    ].flat();
    scheduleDate &&
      filters.push({
        field: "schedule_date",
        type: "=",
        value: scheduleDate,
      });
    kam &&
      filters.push({
        field: "kam_id",
        type: "=",
        value: kam.id,
      });
    manager && filters.push({ field: "mgr_id", type: "=", value: manager.id });
    customer &&
      filters.push({
        field: "customer_name",
        type: "=",
        value: customer.name,
      });
    location &&
      filters.push({
        field: "location",
        type: "=",
        value: location.value,
      });

    let queryString = queryStringBuilderNew(0, 0, [], filters, {}, false);
    if (queryString.includes("last_updated_at_gte")) {
      queryString = queryString.replace(
        /last_updated_at_gte/g,
        "last_updated_at"
      );
    }
    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));
    const { data, error } = await Service.download(queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success(
        "Billing and Settlement Review records downloaded successfully."
      );
      exportBase64ToFile(data, "Billing and Settlement Review");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const getPzriceMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingPriceMatrix: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingPriceMatrix: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingPriceMatrix: false,
      priceMatrix: data.rows || defaultState.priceMatrix,
    }));
  }, []);

  useEffect(() => {
    getPzriceMatrix();
  }, []);

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const handleInlineEdit = async (updatedData, id) => {
    setState((prevState) => ({
      ...prevState,

      isLoading: true,
    }));

    const payload = {
      ...updatedData,
      id,
    };
    const { data, error } = await Service.patch(payload);

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    } else {
      toast.success("Updated successfully.");

      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchEntries(
        state.scheduleDate,
        state.kam,
        state.selectedLocation,
        state.manager,
        state.customer,
        state.pageFilters,
        true
      );
    }
  };

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: true,
      confirmDontBillValue: isCheckBoxChecked,
    }));
  };

  const calculateBill = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.bill_rate = "0.00";
          entry.billing_and_settlement.dont_bill = isCheckBoxChecked;
          entry.billing_and_settlement.bill_amount = 0;
          entry.billing_and_settlement.bill_fuel_amount = 0;
          entry.billing_and_settlement.dont_bill_notes = null;
          entry.billing_and_settlement.dont_bill_miles = false;
          entry.billing_and_settlement.bill_extra_miles = "0";
          entry.billing_and_settlement.bill_extra_mile_amount = "0";
          entry.billing_and_settlement.dont_bill_miles_notes = null;
          entry.billing_and_settlement.dont_bill_tolls = false;
          entry.billing_and_settlement.bill_toll_amount = 0;
          entry.billing_and_settlement.dont_bill_tolls_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: true,
      confirmDontPayValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
    }));
  };

  const calculatePay = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.pay_rate = "0.00";
          entry.billing_and_settlement.dont_pay = isCheckBoxChecked;
          entry.billing_and_settlement.pay_amount = 0;
          entry.billing_and_settlement.pay_fuel_amount = 0;
          entry.billing_and_settlement.dont_pay_notes = null;
          entry.billing_and_settlement.dont_pay_tolls = false;
          entry.billing_and_settlement.pay_toll_amount = 0;
          entry.billing_and_settlement.dont_pay_tolls_notes = null;
          entry.billing_and_settlement.dont_pay_miles = false;
          entry.billing_and_settlement.pay_extra_miles = "0";
          entry.billing_and_settlement.pay_extra_mile_amount = "0";
          entry.billing_and_settlement.dont_pay_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculateBillToll = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.dont_bill_tolls = isCheckBoxChecked;
          entry.billing_and_settlement.bill_toll_amount = 0;
          entry.billing_and_settlement.dont_bill_tolls_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculatePayToll = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.dont_pay_tolls = isCheckBoxChecked;
          entry.billing_and_settlement.pay_toll_amount = 0;
          entry.billing_and_settlement.dont_pay_tolls_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculateBillMiles = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.dont_bill_miles = isCheckBoxChecked;
          entry.billing_and_settlement.bill_extra_miles = "0";
          entry.billing_and_settlement.bill_extra_mile_amount = "0";
          entry.billing_and_settlement.dont_bill_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculatePayMiles = (rowID, rowKeyType, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID && entry.keyType === rowKeyType) {
          entry.billing_and_settlement.dont_pay_miles = isCheckBoxChecked;
          entry.billing_and_settlement.pay_extra_miles = "0";
          entry.billing_and_settlement.pay_extra_mile_amount = "0";
          entry.billing_and_settlement.dont_pay_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };

  const handleApprove = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const payloadEntries = state.entries.filter((entry) =>
      state.selectedRows.includes(entry.id)
    );

    const found = payloadEntries.some(
      (entry) => entry.billing_and_settlement.review_notes?.trim()?.length > 0
    );
    if (!found) {
      toast.error("All selected entries must contain notes.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      return;
    }

    const payload = payloadEntries
      .filter((entry) => entry.keyType === "Updated")
      .map((entry) => ({
        id: entry.id,
        bill_amount: entry.billing_and_settlement.bill_amount,
        pay_amount: entry.billing_and_settlement.pay_amount,
        kam_notes: entry.billing_and_settlement.kam_notes,
        review_notes:
          entry.billing_and_settlement?.sharedNotes === undefined
            ? ""
            : entry.billing_and_settlement.sharedNotes,
        bill_rate: entry.billing_and_settlement.bill_rate,
        bill_fuel_amount: entry.billing_and_settlement.bill_fuel_amount,
        pay_fuel_amount: entry.billing_and_settlement.pay_fuel_amount,
        bill_rate_type: entry.billing_and_settlement.bill_rate_type,
        bill_quantity: entry.billing_and_settlement.bill_quantity,
        dont_bill: entry.billing_and_settlement.dont_bill,
        dont_bill_notes: entry.billing_and_settlement.dont_bill_notes,
        dont_pay: entry.billing_and_settlement.dont_pay,
        pay_rate: entry.billing_and_settlement.pay_rate,
        pay_rate_type: entry.billing_and_settlement.pay_rate_type,
        pay_quantity: entry.billing_and_settlement.pay_quantity,
        pay_toll_amount: entry.billing_and_settlement.pay_toll_amount,
        bill_toll_amount: entry.billing_and_settlement.bill_toll_amount,
        dont_pay_tolls: entry.billing_and_settlement.dont_pay_tolls,
        dont_pay_miles: entry.billing_and_settlement.dont_pay_miles,
        dont_bill_tolls: entry.billing_and_settlement.dont_bill_tolls,
        reimbursement: entry.billing_and_settlement.reimbursement,
        dont_bill_miles: entry.billing_and_settlement.dont_bill_miles,
        dont_bill_tolls_notes:
          entry.billing_and_settlement.dont_bill_tolls_notes,
        dont_bill_miles_notes:
          entry.billing_and_settlement.dont_bill_miles_notes,
        dont_pay_tolls_notes: entry.billing_and_settlement.dont_pay_tolls_notes,
        dont_pay_miles_notes: entry.billing_and_settlement.dont_pay_miles_notes,
      }));

    const { data, error } = await Service.approve(payload);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    } else {
      toast.success("Approved successfully.");

      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchEntries(
        state.scheduleDate,
        state.kam,
        state.selectedLocation,
        state.manager,
        state.customer,
        state.pageFilters,
        true
      );
    }
  };

  const handleReject = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const payloadEntries = state.entries.filter((entry) =>
      state.selectedRows.includes(entry.id)
    );

    const found = payloadEntries.some(
      (entry) => entry.billing_and_settlement.review_notes?.trim()?.length > 0
    );
    if (!found) {
      toast.error("All selected entries must contain notes.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      return;
    }

    const payload = payloadEntries
      .filter((entry) => entry.keyType === "Updated")
      .map((entry) => ({
        id: entry.id,
        bill_amount: entry.billing_and_settlement.bill_amount,
        bill_fuel_amount: entry.billing_and_settlement.bill_fuel_amount,
        pay_fuel_amount: entry.billing_and_settlement.pay_fuel_amount,
        pay_amount: entry.billing_and_settlement.pay_amount,
        kam_notes: entry.billing_and_settlement.kam_notes,
        review_notes:
          entry.billing_and_settlement?.sharedNotes === undefined
            ? ""
            : entry.billing_and_settlement.sharedNotes,
        bill_rate: entry.billing_and_settlement.bill_rate,
        bill_rate_type: entry.billing_and_settlement.bill_rate_type,
        bill_quantity: entry.billing_and_settlement.bill_quantity,
        dont_bill: entry.billing_and_settlement.dont_bill,
        dont_bill_notes: entry.billing_and_settlement.dont_bill_notes,
        dont_pay: entry.billing_and_settlement.dont_pay,
        pay_rate: entry.billing_and_settlement.pay_rate,
        pay_rate_type: entry.billing_and_settlement.pay_rate_type,
        pay_quantity: entry.billing_and_settlement.pay_quantity,
        pay_toll_amount: entry.billing_and_settlement.pay_toll_amount,
        bill_toll_amount: entry.billing_and_settlement.bill_toll_amount,
        dont_pay_tolls: entry.billing_and_settlement.dont_pay_tolls,
        dont_pay_miles: entry.billing_and_settlement.dont_pay_miles,
        dont_bill_tolls: entry.billing_and_settlement.dont_bill_tolls,
        reimbursement: entry.billing_and_settlement.reimbursement,
        dont_bill_miles: entry.billing_and_settlement.dont_bill_miles,
        dont_bill_tolls_notes:
          entry.billing_and_settlement.dont_bill_tolls_notes,
        dont_bill_miles_notes:
          entry.billing_and_settlement.dont_bill_miles_notes,
        dont_pay_tolls_notes: entry.billing_and_settlement.dont_pay_tolls_notes,
        dont_pay_miles_notes: entry.billing_and_settlement.dont_pay_miles_notes,
      }));

    const { data, error } = await Service.reject(payload);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    } else {
      toast.success("Rejected successfully.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        isLoading: false,
        showConfirmationPopup: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchEntries(
        state.scheduleDate,
        state.kam,
        state.selectedLocation,
        state.manager,
        state.customer,
        state.pageFilters,
        true
      );
    }
  };

  const renderGridActions = () => {
    const isRowSelected = !!state.selectedRows?.length;
    return [
      {
        label: "Reject",
        className: classes.rejectBtn,
        disabled: !isRowSelected,
        onClick: () => {
          setState((prevState) => ({
            ...prevState,
            showRejectConfirmationPopup: true,
          }));
        },
      },
      {
        label: "Approve",
        disabled: !isRowSelected,
        onClick: () => {
          const exceededEntries = state.entries.filter(
            (data) =>
              state.selectedRows?.includes(data.id) &&
              ((data.billing_and_settlement.pay_rate_type === "Daily" &&
                +data.billing_and_settlement.pay_quantity > 1.99) ||
                (data.billing_and_settlement.bill_rate_type === "Daily" &&
                  +data.billing_and_settlement.bill_quantity > 1.99))
          );

          if (exceededEntries.length > 0) {
            const locationDetails = exceededEntries.map((entry) => ({
              customer:
                entry.billing_and_settlement.customer_branch.customer.name,
              route: entry.billing_and_settlement.route,
              schedule_date: entry.schedule_date,
              location: entry.billing_and_settlement.customer_branch.location,
            }));

            setState((prevState) => ({
              ...prevState,
              payQuantityExceedDaily: locationDetails,
            }));

            return;
          }
          setState((prevState) => ({
            ...prevState,
            showConfirmationPopup: true,
          }));
        },
      },
    ].map((action, index) => (
      <Button
        key={index}
        variant="contained"
        color={action.color || "primary"}
        disabled={action.disabled}
        onClick={action.onClick}
        className={clsx(action.className, "mr-2")}
      >
        {action.label}
      </Button>
    ));
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const getFormattedDate = (date) => {
    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.row_type?.isSelected,
      id: "row_type",
      label: "Schedule Status",
      fieldName: "row_type",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip title={row.keyType || ""} placement="top-start">
          <Typography variant="body2" noWrap>
            {row.keyType}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.price_type?.isSelected,
      id: "billing_and_settlement.routeDetails.price_type",
      label: "Price Type",
      fieldName: "billing_and_settlement.routeDetails.price_type",
      headerClassName: classes.wrapTableTitle,
      render: (row) => {
        const routeDetails = row.billing_and_settlement?.routeDetails;
        const priceType = routeDetails?.price_type;
        const matrixName = routeDetails?.matrix_name;

        const titleTooltip =
          priceType === "Matrix"
            ? `Matrix Name: ${matrixName}`
            : priceType === "Standard"
            ? "Standard"
            : null;

        const title =
          priceType === "Matrix"
            ? "Price Matrix"
            : priceType === "Standard"
            ? "Standard"
            : null;

        return (
          <Tooltip title={titleTooltip ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill",
      label: "Do Not Bill",
      field: "dont_bill",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement?.dont_bill ? (
                row.billing_and_settlement?.dont_bill_notes === null ||
                row.billing_and_settlement?.dont_bill_notes === "" ? (
                  "This record will not be included in billing"
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.billing_and_settlement?.dont_bill_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              checked={row.billing_and_settlement?.dont_bill}
              disabled={
                row.keyType === "original" &&
                row.original_billing_settlement === null
                //    ||
                // row.billing_and_settlement?.routeDetails?.price_type ===
                //   "Matrix"
              }
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBill(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillRowID: row.id,
                    doNotBillType: row.keyType,
                  }));
                } else {
                  calculateBill(row.id, row.keyType, evt.target.checked);
                }
              }}
              name="Bill"
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay",
      label: "Do Not Pay",
      field: "dont_pay",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement?.dont_pay ? (
                row.billing_and_settlement?.dont_pay_notes === null ||
                row.billing_and_settlement?.dont_pay_notes === "" ? (
                  "This record will not be included in settlement"
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.billing_and_settlement?.dont_pay_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              checked={row.billing_and_settlement?.dont_pay}
              disabled={
                row.keyType === "original" &&
                row.original_billing_settlement === null
                //   ||
                // row.billing_and_settlement?.routeDetails?.price_type ===
                //   "Matrix"
              }
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPay(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayRowID: row.id,
                    doNotpayType: row.keyType,
                  }));
                } else {
                  calculatePay(row.id, row.keyType, evt.target.checked);
                }
              }}
              name="Bill"
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_miles",
      label: "Do Not Bill Extra Miles",
      field: "dont_bill_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement?.dont_bill_miles ? (
                row.billing_and_settlement?.dont_bill_miles_notes === null ||
                row.billing_and_settlement?.dont_bill_miles_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.billing_and_settlement.dont_bill_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              disabled={
                !!isRoleView ||
                !!row.billing_and_settlement?.dont_bill ||
                (row.keyType === "original" &&
                  row.original_billing_settlement === null)
              }
              color="primary"
              checked={row.billing_and_settlement?.dont_bill_miles}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBillExtraMiles(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillExtraMilesID: row.id,
                    doNotBillMilesType: row.keyType,
                  }));
                } else {
                  calculateBillMiles(row.id, row.keyType, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_miles",
      label: "Do Not Pay Extra Miles",
      field: "dont_pay_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement.dont_pay_miles ? (
                row.billing_and_settlement?.dont_pay_miles_notes === null ||
                row.billing_and_settlement?.dont_pay_miles_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.billing_and_settlement.dont_pay_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              disabled={
                !!row.billing_and_settlement?.dont_pay ||
                (row.keyType === "original" &&
                  row.original_billing_settlement === null)
              }
              color="primary"
              checked={row.billing_and_settlement.dont_pay_miles}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPayExtraMiles(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayExtraMilesID: row.id,
                    doNotpayMilesType: row.keyType,
                  }));
                } else {
                  calculatePayMiles(row.id, row.keyType, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_tolls",
      label: "Do Not Bill Toll",
      field: "dont_bill_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement.dont_bill_tolls ? (
                row.billing_and_settlement?.dont_bill_tolls_notes === null ||
                row.billing_and_settlement?.dont_bill_tolls_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.billing_and_settlement.dont_bill_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              color="primary"
              disabled={
                !!isRoleView ||
                !!row.billing_and_settlement?.dont_bill ||
                (row.keyType === "original" &&
                  row.original_billing_settlement === null)
              }
              checked={row.billing_and_settlement.dont_bill_tolls}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBillToll(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillTollID: row.id,
                    doNotBillTollType: row.keyType,
                  }));
                } else {
                  calculateBillToll(row.id, row.keyType, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_tolls",
      label: "Do Not Pay Toll",
      field: "dont_pay_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement.dont_pay_tolls ? (
                row.billing_and_settlement?.dont_pay_tolls_notes === null ||
                row.billing_and_settlement?.dont_pay_tolls_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.billing_and_settlement.dont_pay_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={`${isRoleView ? "no-events" : ""}`}
              disabled={
                !!row.billing_and_settlement?.dont_pay ||
                (row.keyType === "original" &&
                  row.original_billing_settlement === null)
              }
              color="primary"
              checked={row.billing_and_settlement.dont_pay_tolls}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPayToll(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayTollID: row.id,
                    doNotpayTollType: row.keyType,
                  }));
                } else {
                  calculatePayToll(row.id, row.keyType, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "billing_and_settlement.review_reason",
      label: "Review Reason",
      fieldName: "billing_and_settlement.review_reason",
      headerClassName: classes.tableHeaderNotesWidth,
      render: (row) =>
        row.keyType !== "original" ? (
          <Tooltip title={row.review_reason || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.review_reason || "-"}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body2" noWrap>
            {"-"}
          </Typography>
        ),
    },
    {
      isHidden: !state.dynamicColumns?.change_notes?.isSelected,
      headerClassName: classes.tableHeaderNotesWidth,
      id: "billing_and_settlement.change_notes",
      label: "AA/MGR Notes",
      fieldName: "billing_and_settlement.change_notes",
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.billing_and_settlement?.change_notes ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.billing_and_settlement?.change_notes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      headerClassName: classes.tableHeaderNotesWidth,
      id: "kam_notes",
      label: "KAM Notes",
      fieldName: "kam_notes",
      render: (row, rowIndex) => (
        <Tooltip
          title={row.billing_and_settlement?.kam_notes || "-"}
          placement="top-start"
          disableFocusListener
        >
          {row.billing_and_settlement !== null ? (
            <Typography variant="body2" noWrap>
              {row.billing_and_settlement?.kam_notes || "-"}
            </Typography>
          ) : (
            <Typography>{"-"}</Typography>
          )}
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes ",
      fieldName: "review_notes",
      headerClassName: classes.addNewreviewNotesHeaderCell,
      render: (row, rowIndex) => {
        let finalNotes;

        if (row.notesRow.review_notes !== null) {
          let splitNotes = row.notesRow?.review_notes?.split("||");

          finalNotes = splitNotes?.map((note) => {
            let str = note;

            const startIndexOfDate = str?.indexOf("-");
            const lastIndexOfDate = str?.indexOf(": ");
            const formattedDate = new Date(
                str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
              )?.toLocaleString(),
              str1 =
                str.substring(0, startIndexOfDate) +
                "-" +
                formattedDate +
                str.substring(lastIndexOfDate);
            return str1;
          });
        }

        const title = (
          <div
            dangerouslySetInnerHTML={{
              __html: (finalNotes || []).join("<br>"),
            }}
          />
        );

        let lastNote = !!finalNotes ? finalNotes[0] : "";
        let x = lastNote.split(": ");
        const userName = x[0].split("-")[0];
        const noteTime = x[0].split("-")[1]?.split(",");
        delete x[0];
        const message = x.join(": ");

        return (
          <div>
            {row.keyType !== "original" ? (
              <div>
                {!isRoleView && (
                  <div className="d-flex" style={{ width: 290, gap: "6px" }}>
                    <Autocomplete
                      required
                      fullWidth
                      size="small"
                      freeSolo
                      options={state.reviewNotesList}
                      getOptionLabel={(option) => option.value}
                      disableClearable
                      inputValue={row.billing_and_settlement.sharedNotes || ""}
                      onInputChange={(event, value) => {
                        setState((prevState) => {
                          const updatedEntries = prevState.entries?.map(
                            (entry) => {
                              if (entry.id === row.id) {
                                entry.billing_and_settlement.review_notes =
                                  value || "";
                                entry.billing_and_settlement.sharedNotes =
                                  value || "";
                              }
                              return entry;
                            }
                          );
                          return {
                            ...prevState,
                            entries: updatedEntries,
                          };
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          variant="outlined"
                          label="Add New Review Note"
                          name="reviewNotes"
                          error={!!state.errors[row.id]?.trim()}
                          helperText={state.errors[row.id]}
                          onChange={(evt) => {
                            const field = evt.target.name;
                            const value = evt.target.value;
                            const errorMessage = validate(field, value) || "";

                            setState((prevState) => {
                              const updatedEntries = prevState.entries.map(
                                (entry) => {
                                  if (entry.id === row.id) {
                                    entry.billing_and_settlement.review_notes =
                                      value || "";
                                    entry.billing_and_settlement.sharedNotes =
                                      value || "";
                                  }
                                  return entry;
                                }
                              );

                              return {
                                ...prevState,
                                errors: {
                                  ...prevState.errors,
                                  [row.id]: errorMessage,
                                },
                                entries: updatedEntries,
                              };
                            });
                          }}
                        />
                      )}
                    />
                    {!isRoleMGR && (
                      <Tooltip
                        title="Add To Lookup(Review Notes)"
                        placement="top-end"
                      >
                        <Button
                          type="submit"
                          onClick={() => handleShowAddLookupDialog(row.id)}
                          variant="contained"
                          color="primary"
                          disabled={
                            !row.billing_and_settlement.sharedNotes ||
                            state.errors?.[row.id]?.length > 1
                          }
                          className={classes.buttonStyle}
                        >
                          <PostAddIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            ) : !finalNotes ? (
              <span>-</span>
            ) : !userName ? (
              "-"
            ) : (
              <Tooltip title={title || "-"} placement="top-start">
                <div>
                  <Typography
                    style={{ display: "inline" }}
                    variant="caption"
                    className="text-bold"
                  >
                    {userName}{" "}
                  </Typography>
                  <Typography variant="caption">{`${noteTime[0]}, ${noteTime[1]}:`}</Typography>
                  <Typography variant="body2">
                    {message?.length > 50
                      ? message?.slice(2, 50) + "..."
                      : !finalNotes[1]?.length
                      ? message?.slice(2, 100)
                      : message?.slice(2, message?.length) + "..."}
                  </Typography>
                </div>
              </Tooltip>
            )}{" "}
          </div>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.schedule_date?.isSelected,
      id: "schedule_date",
      fieldName: "schedule_date",
      label: "Schedule Date",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.schedule_date
              ? getFormattedDate(
                  getUTCDateString(row.billing_and_settlement?.schedule_date)
                )
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.schedule_date
              ? getFormattedDate(
                  getUTCDateString(row.billing_and_settlement?.schedule_date)
                )
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.customer_name?.isSelected,
      id: "customer_name",
      label: "Customer Name",
      fieldName: "customer_name",
      headerClassName: classes.wrapTableTitle,
      render: (row) => {
        const customerName = `${
          row.billing_and_settlement?.customer_branch?.customer?.name || ""
        }`;
        return (
          <Tooltip title={customerName} placement="top-start">
            <Typography variant="body2" noWrap>
              {customerName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam?.isSelected,
      id: "kamUser",
      label: "KAM",
      fieldName: "kamUser",
      headerClassName: classes.wrapTableTitle,
      render: (row) => {
        const kamName = `${
          row.billing_and_settlement?.kamUser?.first_name || ""
        } ${row.billing_and_settlement?.kamUser?.last_name || ""}`;
        const title = row.billing_and_settlement?.kamUser?.username
          ? `${kamName}-(${row.billing_and_settlement?.kamUser?.username})`
          : "";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {!!row.billing_and_settlement?.kamUser ? kamName || "-" : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "customer_branch.location",
      label: "Location",
      fieldName: "billing_and_settlement.customer_branch.location",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        const title = !!row.billing_and_settlement?.customer_branch_id
          ? row.billing_and_settlement?.customer_branch?.location
          : // `State: ${
            //     row.billing_and_settlement?.customer_branch?.state?.name || "-"
            //   }, PDX Company: ${
            //     row.billing_and_settlement?.customer_branch?.pdxCompany?.value ||
            //     "-"
            //   }`
            "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {!!row.billing_and_settlement?.customer_branch_id
                ? row.billing_and_settlement?.customer_branch?.location || "-"
                : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "route",
      label: "Route",
      fieldName: "billing_and_settlement.route",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={row.billing_and_settlement?.routeDetails?.name || "-"}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.billing_and_settlement?.routeDetails?.name || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "state",
      label: "State",
      field: "billing_and_settlement.state",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement?.customer_branch_id
                ? row.billing_and_settlement?.customer_branch?.state?.name ||
                  "-"
                : "-"
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {!!row.billing_and_settlement?.customer_branch_id
                ? row.billing_and_settlement?.customer_branch?.state?.name ||
                  "-"
                : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "billing_and_settlement.pdx_company_name",
      headerClassName: classes.pdxCompanyHeader,
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.billing_and_settlement?.customer_branch_id
                ? row.billing_and_settlement?.customer_branch?.pdxCompany
                    ?.value || "-"
                : "-"
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {!!row.billing_and_settlement?.customer_branch_id
                ? row.billing_and_settlement?.customer_branch?.pdxCompany
                    ?.value || "-"
                : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicle_type",
      label: "Vehicle",
      fieldName: "billing_and_settlement.vehicle_type",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={row.billing_and_settlement?.vehicleType?.value || "-"}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.billing_and_settlement?.vehicleType?.value || "-"}
          </Typography>
        </Tooltip>
      ),
    },

    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "contractor_first_name",
      label: "ICID/IC Name",
      field: "contractor_first_name",
      render: (row) => {
        const handleLabelClick = () => {
          if (!!row.billing_and_settlement?.individual_contractor) {
            fetchList(
              "IC",
              noop,
              `?filter[limit]=1&filter[where][icid]=${row.billing_and_settlement?.individual_contractor?.icid}`
            );
            setState((prevState) => ({
              ...prevState,
              showIcDetails: true,
            }));
          }
        };
        let title = !!row.billing_and_settlement?.individual_contractor
          ? `
          IC Settlement Company Name: ${
            row.billing_and_settlement?.settlementCoName || "-"
          }, 
       
          ICID: ${
            row.billing_and_settlement?.individual_contractor?.icid || "-"
          },
          IC Name: ${
            row.billing_and_settlement?.individual_contractor?.last_name || ""
          } ${
              row.billing_and_settlement?.individual_contractor?.first_name ||
              ""
            },Contractor Type: ${
              row.billing_and_settlement?.individual_contractor
                ?.contractorType || ""
            }
        `
          : "";
        if (
          row.billing_and_settlement?.individual_contractor_changed &&
          row.billing_and_settlement?.original_individual_contractor_id
        ) {
          const orginalIC = appData.icList.find(
            (contractor) =>
              contractor?.id ===
              row.billing_and_settlement?.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }
        const label = !!row.billing_and_settlement?.individual_contractor
          ? `${row.billing_and_settlement?.individual_contractor?.icid || ""}/${
              row.billing_and_settlement?.individual_contractor?.last_name || ""
            } ${
              row.billing_and_settlement?.individual_contractor?.first_name ||
              ""
            }`
          : "-";

        const flagLabel =
          (!!row.billing_and_settlement?.individual_contractor?.is_flagged &&
            !!row.billing_and_settlement?.individual_contractor
              ?.is_cautious_flagged) ||
          !!row.billing_and_settlement?.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.billing_and_settlement?.individual_contractor
              ?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const cautionFlaggedBy = userInfo(
          row.billing_and_settlement?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row.billing_and_settlement?.individual_contractor?.ctn_flag_rsn
        );

        const flagTitles = !row.billing_and_settlement
          ? "-"
          : `Flagged Reason: ${
              row.billing_and_settlement?.individual_contractor?.flagReason
                ?.value || cautionFlaggedReason?.value
            }, Flagged By: ${
              row.billing_and_settlement?.individual_contractor?.flaggedBy
                ?.first_name ||
              cautionFlaggedBy?.first_name ||
              ""
            } ${
              row.billing_and_settlement?.individual_contractor?.flaggedBy
                ?.last_name ||
              cautionFlaggedBy?.last_name ||
              ""
            }, USERNAME: ${
              row.billing_and_settlement?.individual_contractor?.flaggedBy
                ?.username ||
              cautionFlaggedBy?.username ||
              ""
            }, ROLE: ${
              row.billing_and_settlement?.individual_contractor?.flaggedBy
                ?.role ||
              cautionFlaggedBy?.role ||
              ""
            }, Flagged At: ${getDateString(
              row.billing_and_settlement?.individual_contractor?.flagged_at ||
                row?.individual_contractor?.ctn_flagged_at
            )}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    style={{
                      textDecoration:
                        !!row.billing_and_settlement?.individual_contractor &&
                        "underline",
                      cursor:
                        !!row.billing_and_settlement?.individual_contractor &&
                        "pointer",
                    }}
                    onClick={
                      !!row.billing_and_settlement?.individual_contractor &&
                      handleLabelClick
                    }
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.WCP_Driver?.isSelected,
      id: "WCP_Driver",
      label: "WCP-Driver",
      field: "WCP_Driver",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const billingRow =
          row.keyType === "original"
            ? row.original_billing_settlement
            : row.billing_and_settlement;
        const driver = billingRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""}`
          : null;
        return (
          <Tooltip title={driverName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {driverName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.settlement_company?.isSelected,
      id: "settlement_co_name",
      label: "Settlement Company",
      field: "settlement_co_name",
      headerClassName: classes.wrapTableTitle,
      render: (row) => {
        return (
          <Tooltip
            title={row.billing_and_settlement?.settlementCoName || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.billing_and_settlement?.settlementCoName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate_type?.isSelected,
      id: "bill_rate_type",
      label: "Billing Rate Type",
      fieldName: "billing_and_settlement.bill_rate_type",
      headerClassName: classes.tableExtraMilesWidth,

      render: (row, rowIndex) => (
        <InlineFieldForm
          dropdownLabel={
            !!row.billing_and_settlement?.bill_rate_type
              ? row.billing_and_settlement?.bill_rate_type
              : "-"
          }
          name="bill_rate_type"
          initialValue={{
            label: row.billing_and_settlement?.bill_rate_type || "-",
          }}
          enableFlip={true}
          disablePortal={false}
          disabled={
            !!isRoleView ||
            row.keyType === "original" ||
            row.billing_and_settlement?.routeDetails?.price_type === "Matrix"
          }
          type="dropdown"
          dropdownLabelClassName={
            row.original_billing_settlement !== null &&
            row.billing_and_settlement?.bill_rate_type !=
              row.original_billing_settlement?.bill_rate_type &&
            "color-error"
          }
          options={RATE_TYPES.map((rateType) => ({
            value: rateType,
            label: rateType,
          }))}
          onUpdate={(updatedData) => {
            setState((prevState) => {
              const updatedEntries = prevState.entries.map((entry) => {
                if (entry.id === row.id && entry.keyType === row.keyType) {
                  entry.billing_and_settlement.bill_rate_type =
                    updatedData.value;
                }

                return entry;
              });
              return {
                ...prevState,
                entries: updatedEntries,
              };
            });
          }}
        />
      ),
    },

    {
      isHidden: !state.dynamicColumns?.bill_rate?.isSelected,
      id: "billing_and_settlement.bill_rate",
      label: "Billing Rate",
      fieldName: "billing_and_settlement.bill_rate",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let billPayPrice = [];

        if (row.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
          billPayPrice = state.priceMatrix?.filter(
            (item) =>
              row.billing_and_settlement?.customer_branch?.id ===
                item?.customer_branch_id &&
              item.name ===
                row?.billing_and_settlement?.routeDetails?.matrix_name
          );
        }
        return row.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix" ? (
          <InlineFieldForm
            dropdownLabel={
              row.billing_and_settlement?.bill_rate
                ? (() => {
                    const matchingBillOption = billPayPrice?.find(
                      (billOption) =>
                        billOption?.bill_price ==
                        row.billing_and_settlement?.bill_rate
                    );

                    if (matchingBillOption) {
                      return `$ ${row.billing_and_settlement?.bill_rate} (${matchingBillOption.quantity})`;
                    } else {
                      return `$ ${row.billing_and_settlement?.bill_rate}`;
                    }
                  })()
                : "-"
            }
            enableFlip={true}
            disablePortal={false}
            dropdownLabelClassName={
              row.original_billing_settlement !== null &&
              row.billing_and_settlement?.bill_rate !=
                row.original_billing_settlement?.bill_rate &&
              "color-error"
            }
            dropdownPaperClass={
              !!row.billing_and_settlement?.dont_bill && "no-events"
            }
            name="bill_rate"
            startAdornment="$"
            disabled={!!isRoleView || row.keyType === "original"}
            initialValue={row.billing_and_settlement?.bill_rate}
            type="dropdown"
            options={billPayPrice?.map((item) => ({
              value: item.bill_price,
              label: `$ ${item.bill_price} (${item.quantity})`,
            }))}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const correspondingSettlement = row.billing_and_settlement
                  ?.dont_pay
                  ? { pay_price: "0.00" }
                  : billPayPrice.find(
                      (item) => item.bill_price === updatedData.value
                    );
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.bill_rate = updatedData.value;
                    entry.billing_and_settlement.bill_amount =
                      updatedData.value *
                      entry.billing_and_settlement.bill_quantity;
                    entry.billing_and_settlement.bill_fuel_amount = (
                      entry.billing_and_settlement.bill_amount *
                      (entry.billing_and_settlement.bill_fuel_surcharge / 100)
                    ).toFixed(2);
                    entry.billing_and_settlement.pay_rate =
                      correspondingSettlement.pay_price;
                    entry.billing_and_settlement.pay_amount =
                      correspondingSettlement.pay_price *
                      entry.billing_and_settlement.pay_quantity;
                    entry.billing_and_settlement.pay_fuel_amount = (
                      entry.billing_and_settlement.pay_amount *
                      (entry.billing_and_settlement.pay_fuel_surcharge / 100)
                    ).toFixed(2);
                  }

                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        ) : (
          <InlineFieldForm
            labelClassName={
              row.original_billing_settlement !== null &&
              row.billing_and_settlement?.bill_rate !=
                row.original_billing_settlement?.bill_rate &&
              "color-error"
            }
            name="bill_rate"
            startAdornment="$"
            disabled={!!isRoleView || !!row.billing_and_settlement?.dont_bill}
            initialValue={row.billing_and_settlement?.bill_rate}
            inputType="number"
            validations={[
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ]}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.bill_rate = updatedData.value;
                    entry.billing_and_settlement.bill_amount =
                      updatedData.value *
                      entry.billing_and_settlement.bill_quantity;
                    entry.billing_and_settlement.bill_fuel_amount = (
                      entry.billing_and_settlement.bill_amount *
                      (entry.billing_and_settlement.bill_fuel_surcharge / 100)
                    ).toFixed(2);
                  }

                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_quantity?.isSelected,
      id: "billing_and_settlement.bill_quantity",
      label: " Billing Quantity",
      fieldName: "billing_and_settlement.bill_quantity",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];

        if (row.billing_and_settlement?.bill_rate_type === "Hourly") {
          validations = [
            {
              type: VALIDATIONS.MAX,
              value: 24,
            },
            ...validations,
          ];
        }
        if (row.billing_and_settlement?.bill_rate_type === "Daily") {
          validations = [
            {
              type: VALIDATIONS.DAILY_QUANTITY_LIMIT,
              value: 1.99,
            },
            ...validations,
          ];
        }
        return (
          <InlineFieldForm
            name="bill_quantity"
            labelClassName={
              row.original_billing_settlement !== null &&
              row.billing_and_settlement?.bill_quantity !=
                row.original_billing_settlement?.bill_quantity &&
              "color-error"
            }
            disabled={
              !!isRoleView ||
              row.billing_and_settlement?.routeDetails?.price_type === "Matrix"
            }
            initialValue={row.billing_and_settlement?.bill_quantity}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.bill_quantity =
                      updatedData.value;
                    entry.billing_and_settlement.bill_amount =
                      updatedData.value *
                      entry.billing_and_settlement.bill_rate;
                    entry.billing_and_settlement.bill_fuel_amount = (
                      entry.billing_and_settlement.bill_amount *
                      (entry.billing_and_settlement.bill_fuel_surcharge / 100)
                    ).toFixed(2);
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.bill_amount?.isSelected,
      id: "billing_and_settlement.bill_amount",
      label: "Billing Amount",
      fieldName: "billing_and_settlement.bill_amount",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <>
          <Tooltip
            title={
              !!row.billing_and_settlement?.bill_amount
                ? row.billing_and_settlement?.bill_amount || ""
                : "$0"
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {!!row.billing_and_settlement?.bill_amount
                ? `$${row.billing_and_settlement?.bill_amount}`
                : "$0"}
            </Typography>
          </Tooltip>
        </>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_surcharge?.isSelected,
      id: "billing_and_settlement.bill_fuel_surcharge",
      label: "Billing Fuel surcharge%",
      fieldName: "billing_and_settlement.bill_fuel_surcharge",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.bill_fuel_surcharge
              ? `${row.billing_and_settlement?.bill_fuel_surcharge}%` || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.bill_fuel_surcharge
              ? `${row.billing_and_settlement?.bill_fuel_surcharge}%`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_amount?.isSelected,
      id: "billing_and_settlement.bill_fuel_amount",
      label: "Billing Fuel Amount",
      fieldName: "billing_and_settlement.bill_fuel_amount",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.bill_fuel_amount
              ? row.billing_and_settlement?.bill_fuel_amount || ""
              : "$0"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.bill_fuel_amount
              ? `$${row.billing_and_settlement?.bill_fuel_amount}`
              : "$0"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_toll_amount?.isSelected,
      id: "billing_and_settlement.bill_toll_amount",
      label: "Billing Toll Amount",
      fieldName: "billing_and_settlement.bill_toll_amount",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];
        return (
          <InlineFieldForm
            name="bill_toll_amount"
            startAdornment="$"
            labelClassName={
              row.original_billing_settlement !== null &&
              (row.billing_and_settlement?.bill_toll_amount > 999.99 ||
                +row.billing_and_settlement?.bill_toll_amount !==
                  +row.original_billing_settlement?.bill_toll_amount) &&
              "color-error"
            }
            disabled={
              !!row.billing_and_settlement?.dont_bill_tolls ||
              !!row.billing_and_settlement?.dont_bill
            }
            initialValue={row.billing_and_settlement?.bill_toll_amount}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.bill_toll_amount =
                      updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_extra_miles?.isSelected,
      id: "billing_and_settlement.bill_extra_miles",
      label: "Billing Extra Miles",
      fieldName: "billing_and_settlement.bill_extra_miles",
      headerClassName: classes.tableExtraMilesWidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.bill_extra_miles
              ? row.billing_and_settlement?.bill_extra_miles || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.billing_and_settlement?.bill_extra_miles
              ? row.billing_and_settlement?.bill_extra_miles
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate_per_extra_miles?.isSelected,
      id: "billing_and_settlement.bill_rate_per_extra_mile",
      label: "Billing Rate Per Extra Mile",
      fieldName: "billing_and_settlement.bill_rate_per_extra_mile",
      headerClassName: classes.tableExtraMilesWidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.bill_rate_per_extra_mile
              ? row.billing_and_settlement?.bill_rate_per_extra_mile || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.bill_rate_per_extra_mile
              ? `$${row.billing_and_settlement?.bill_rate_per_extra_mile}`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_extra_mile_amount?.isSelected,
      id: "billing_and_settlement.bill_extra_mile_amount",
      label: "Billing Extra Miles Amount",
      fieldName: "billing_and_settlement.bill_extra_mile_amount",
      headerClassName: classes.tableExtraMilesWidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.bill_extra_mile_amount
              ? row.billing_and_settlement?.bill_extra_mile_amount || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.bill_extra_mile_amount
              ? `$${row.billing_and_settlement?.bill_extra_mile_amount}`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      fieldName: "billing_and_settlement.pay_rate_type",
      headerClassName: classes.tableExtraMilesWidth,

      render: (row, rowIndex) => (
        <InlineFieldForm
          dropdownLabel={
            !!row.billing_and_settlement?.pay_rate_type
              ? row.billing_and_settlement?.pay_rate_type
              : "-"
          }
          name="pay_rate_type"
          initialValue={{
            label: !!row.billing_and_settlement?.pay_rate_type
              ? row.billing_and_settlement?.pay_rate_type
              : "-",
          }}
          disabled={
            isRoleView ||
            row.keyType === "original" ||
            row.billing_and_settlement?.routeDetails?.price_type === "Matrix"
          }
          type="dropdown"
          enableFlip={true}
          disablePortal={false}
          dropdownLabelClassName={
            row.original_billing_settlement !== null &&
            row.billing_and_settlement?.pay_rate_type !=
              row.original_billing_settlement?.pay_rate_type &&
            "color-error"
          }
          options={
            row.billing_and_settlement?.individual_contractor
              ?.contractorType === "Stand-by"
              ? appDataList.icList
                  ?.find(
                    (ele) =>
                      ele.id ===
                      row?.billing_and_settlement?.individual_contractor?.id
                  )
                  ?.StandByRates?.map((rate) => ({
                    value: rate,
                    label: rate?.rateType?.value,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))
              : RATE_TYPES.map((rateType) => ({
                  value: rateType,
                  label: rateType,
                }))
          }
          onUpdate={(updatedData) => {
            setState((prevState) => {
              const updatedEntries = prevState.entries.map((entry) => {
                if (entry.id === row.id && entry.keyType === row.keyType) {
                  entry.billing_and_settlement.pay_rate_type =
                    updatedData?.value?.rateType?.value || updatedData?.value;
                  entry.billing_and_settlement.pay_rate =
                    updatedData?.value?.rate_value || updatedData?.value;
                }
                return entry;
              });
              return {
                ...prevState,
                entries: updatedEntries,
              };
            });
            // handleInlineEdit(updatedData, row.id);
          }}
        />
      ),
    },

    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "billing_and_settlement.pay_rate",
      label: "Pay Rate",
      fieldName: "billing_and_settlement.pay_rate",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let billPayPrice = [];
        if (row.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
          billPayPrice = state.priceMatrix?.filter(
            (item) =>
              row.billing_and_settlement?.customer_branch?.id ===
                item?.customer_branch_id &&
              item.name ===
                row?.billing_and_settlement?.routeDetails?.matrix_name
          );
        }
        return row.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix" ? (
          <InlineFieldForm
            dropdownLabel={
              row.billing_and_settlement?.pay_rate
                ? (() => {
                    const matchingBillOption = billPayPrice?.find(
                      (billOption) =>
                        billOption?.pay_price ==
                        row.billing_and_settlement?.pay_rate
                    );

                    if (matchingBillOption) {
                      return `$ ${row.billing_and_settlement?.pay_rate} (${matchingBillOption.quantity})`;
                    } else {
                      return `$ ${row.billing_and_settlement?.pay_rate}`;
                    }
                  })()
                : "-"
            }
            enableFlip={true}
            disablePortal={false}
            dropdownLabelClassName={
              row.keyType === "Updated"
                ? row.original_billing_settlement !== null &&
                  row.billing_and_settlement?.pay_rate !=
                    row.original_billing_settlement?.pay_rate &&
                  "color-error"
                : null
            }
            dropdownPaperClass={
              !!row.billing_and_settlement?.dont_pay && "no-events"
            }
            name="pay_rate"
            startAdornment="$"
            disabled={isRoleView || row.keyType === "original"}
            initialValue={row.billing_and_settlement?.pay_rate}
            type="dropdown"
            options={billPayPrice?.map((item) => ({
              value: item.pay_price,
              label: `${item.pay_price} (${item.quantity})`,
            }))}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const correspondingBilling = row.billing_and_settlement
                  ?.dont_bill
                  ? { bill_price: "0.00" }
                  : billPayPrice?.find(
                      (item) => item.pay_price === updatedData.value
                    );
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.pay_rate = updatedData.value;
                    entry.billing_and_settlement.pay_amount =
                      updatedData.value *
                      entry.billing_and_settlement.pay_quantity;
                    entry.billing_and_settlement.pay_fuel_amount = (
                      entry.billing_and_settlement.pay_amount *
                      (entry.billing_and_settlement.pay_fuel_surcharge / 100)
                    ).toFixed(2);
                    entry.billing_and_settlement.bill_rate =
                      correspondingBilling.bill_price;
                    entry.billing_and_settlement.bill_amount =
                      correspondingBilling.bill_price *
                      entry.billing_and_settlement.bill_quantity;
                    entry.billing_and_settlement.bill_fuel_amount = (
                      entry.billing_and_settlement.bill_amount *
                      (entry.billing_and_settlement.bill_fuel_surcharge / 100)
                    ).toFixed(2);
                  }

                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        ) : (
          <InlineFieldForm
            labelClassName={
              row.keyType === "Updated"
                ? row.original_billing_settlement !== null &&
                  row.billing_and_settlement?.pay_rate !==
                    row.original_billing_settlement?.pay_rate &&
                  "color-error"
                : null
            }
            name="pay_rate"
            startAdornment="$"
            disabled={isRoleView || !!row.billing_and_settlement?.dont_pay}
            initialValue={row.billing_and_settlement?.pay_rate}
            inputType="number"
            validations={[
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ]}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.pay_rate = updatedData.value;
                    entry.billing_and_settlement.pay_amount =
                      updatedData.value *
                      entry.billing_and_settlement.pay_quantity;
                    entry.billing_and_settlement.pay_fuel_amount = (
                      entry.billing_and_settlement.pay_amount *
                      (entry.billing_and_settlement.pay_fuel_surcharge / 100)
                    ).toFixed(2);
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
              // handleInlineEdit(updatedData, row.id);
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "billing_and_settlement.pay_quantity",
      label: " Pay Quantity",
      fieldName: "billing_and_settlement.pay_quantity",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];

        if (row.billing_and_settlement?.pay_rate_type === "Hourly") {
          validations = [
            {
              type: VALIDATIONS.MAX,
              value: 24,
            },
            ...validations,
          ];
        }
        if (row.billing_and_settlement?.pay_rate_type === "Daily") {
          validations = [
            {
              type: VALIDATIONS.DAILY_QUANTITY_LIMIT,
              value: 1.99,
            },
            ...validations,
          ];
        }
        return (
          <InlineFieldForm
            name="pay_quantity"
            labelClassName={
              row.keyType === "Updated"
                ? row.original_billing_settlement !== null &&
                  row.billing_and_settlement?.pay_quantity !=
                    row.original_billing_settlement?.pay_quantity &&
                  "color-error"
                : null
            }
            disabled={
              isRoleView ||
              row.billing_and_settlement?.routeDetails?.price_type === "Matrix"
            }
            initialValue={row.billing_and_settlement?.pay_quantity}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.pay_quantity =
                      updatedData.value;
                    entry.billing_and_settlement.pay_amount =
                      updatedData.value * entry.billing_and_settlement.pay_rate;
                    entry.billing_and_settlement.pay_fuel_amount = (
                      entry.billing_and_settlement.pay_amount *
                      (entry.billing_and_settlement.pay_fuel_surcharge / 100)
                    ).toFixed(2);
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
              // handleInlineEdit(updatedData, row.id);
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_amount?.isSelected,
      id: "billing_and_settlement.pay_amount",
      label: "Pay Amount",
      fieldName: "billing_and_settlement.pay_amount",
      headerClassName: classes.wrapTableTitle,

      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_amount
              ? row.billing_and_settlement?.pay_amount || ""
              : "$0"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.pay_amount
              ? `$${row.billing_and_settlement?.pay_amount}`
              : "$0"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_surcharge?.isSelected,
      id: "billing_and_settlement.pay_fuel_surcharge",
      label: "Pay Fuel Surcharge%",
      fieldName: "billing_and_settlement.pay_fuel_surcharge",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_fuel_surcharge
              ? `${row.billing_and_settlement?.pay_fuel_surcharge}%` || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.pay_fuel_surcharge
              ? `${row.billing_and_settlement?.pay_fuel_surcharge}%`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_amount?.isSelected,
      headerClassName: classes.tableExtraMilesWidth,
      id: "pay_fuel_amount",
      label: "Pay Fuel Amount",
      field: "pay_fuel_amount",
      startAdornment: "$",
      hasEllipses: true,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_fuel_amount
              ? row.billing_and_settlement?.pay_fuel_amount || ""
              : "$0"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.pay_fuel_amount
              ? `$${row.billing_and_settlement?.pay_fuel_amount}`
              : "$0"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "billing_and_settlement.pay_toll_amount",
      label: "Pay Toll Amount",
      fieldName: "billing_and_settlement.pay_toll_amount",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];
        return (
          <InlineFieldForm
            name="pay_toll_amount"
            startAdornment="$"
            labelClassName={
              row.original_billing_settlement !== null &&
              (row.billing_and_settlement?.pay_toll_amount > 999.99 ||
                +row.billing_and_settlement?.pay_toll_amount !==
                  +row.original_billing_settlement?.pay_toll_amount) &&
              "color-error"
            }
            disabled={
              !!row.billing_and_settlement?.dont_pay_tolls ||
              !!row.billing_and_settlement?.dont_pay
            }
            initialValue={row.billing_and_settlement?.pay_toll_amount}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.pay_toll_amount =
                      updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      headerClassName: classes.tableExtraMilesWidth,
      id: "billing_and_settlement.pay_extra_miles",
      label: "Pay Extra Miles",
      fieldName: "billing_and_settlement.pay_extra_miles",
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_extra_miles
              ? row.billing_and_settlement?.pay_extra_miles || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.billing_and_settlement?.pay_extra_miles
              ? row.billing_and_settlement?.pay_extra_miles
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_per_extra_miles?.isSelected,
      id: "billing_and_settlement.pay_rate_per_extra_mile",
      label: "Pay Rate Per Extra Mile",
      fieldName: "billing_and_settlement.pay_rate_per_extra_mile",
      headerClassName: classes.tableExtraMilesWidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_rate_per_extra_mile
              ? row.billing_and_settlement?.pay_rate_per_extra_mile || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.pay_rate_per_extra_mile
              ? `$${row.billing_and_settlement?.pay_rate_per_extra_mile}`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_mile_amount?.isSelected,
      id: "billing_and_settlement.pay_extra_mile_amount",
      label: "Pay Extra Miles Amount",
      fieldName: "billing_and_settlement.pay_extra_mile_amount",
      headerClassName: classes.tableExtraMilesWidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.pay_extra_mile_amount
              ? row.billing_and_settlement?.pay_extra_mile_amount || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.billing_and_settlement?.pay_extra_mile_amount
              ? `$${row.billing_and_settlement?.pay_extra_mile_amount}`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.reimbursement?.isSelected,
      id: "billing_and_settlement.reimbursement",
      label: "Incentive",
      fieldName: "billing_and_settlement.reimbursement",
      headerClassName: classes.wrapTableTitle,
      render: (row, rowIndex) => {
        let validations = [{ type: VALIDATIONS.MIN, value: 0 }];
        return (
          <InlineFieldForm
            name="reimbursement"
            startAdornment={
              !!row.billing_and_settlement?.reimbursement ? "$" : ""
            }
            initialValue={
              row.billing_and_settlement?.reimbursement
                ? (+row.billing_and_settlement.reimbursement).toFixed(2)
                : "-"
            }
            labelClassName={
              !!row.original_billing_settlement &&
              +row.billing_and_settlement?.reimbursement !==
                +row.original_billing_settlement?.reimbursement &&
              "color-error"
            }
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.billing_and_settlement.reimbursement =
                      updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.deduction?.isSelected,
      id: "billing_and_settlement.deduction",
      label: "Deduction",
      fieldName: "billing_and_settlement.deduction",
      headerClassName: classes.wrapTableTitle,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.deduction
              ? row.billing_and_settlement?.deduction || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.deduction
              ? `$${row.billing_and_settlement?.deduction}`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.deduction_reason?.isSelected,
      id: "billing_and_settlement.deduction_reason",
      label: "Deduction Reason",
      fieldName: "billing_and_settlement.deduction_reason",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.deduction_reason
              ? row.billing_and_settlement?.deduction_reason || ""
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.deduction_reason
              ? row.billing_and_settlement?.deduction_reason
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.office?.isSelected,
      id: "aaUser",
      label: "Office",
      fieldName: "aaUser",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const officeUser = !!row.billing_and_settlement?.aaUser
          ? `${row.billing_and_settlement?.aaUser?.first_name || ""} ${
              row.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";
        const title = row.billing_and_settlement?.aaUser?.username
          ? `${officeUser}-(${row.billing_and_settlement?.aaUser?.username})`
          : "-";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {officeUser}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.mgr?.isSelected,
      id: "mgrUser",
      label: "Manager",
      fieldName: "mgrUser",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const manager = !!row.billing_and_settlement?.mgrUser
          ? `${row.billing_and_settlement?.mgrUser?.first_name || ""} ${
              row.billing_and_settlement?.mgrUser?.last_name || ""
            }`
          : "-";
        const title = row.billing_and_settlement?.mgrUser?.username
          ? `${manager}-(${row.billing_and_settlement?.mgrUser?.username})`
          : "";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {manager}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.profit_percentage?.isSelected,
      id: "billing_and_settlement.profit_percentage",
      label: "Profit Percentage",
      fieldName: "billing_and_settlement.profit_percentage",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={
            !!row.billing_and_settlement?.profit_percentage
              ? `${row.billing_and_settlement?.profit_percentage}%`
              : "-"
          }
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {!!row.billing_and_settlement?.profit_percentage
              ? `${row.billing_and_settlement?.profit_percentage}%`
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.submitted_by?.isSelected,
      id: "submitted_by",
      label: "Submitted By",
      field: "submitted_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const kam = appData.users.find(
          (ele) =>
            ele?.id ===
              row.billing_and_settlement?.daily_schedule?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }
        return (
          <Tooltip title={submittedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submittedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updated_by?.isSelected,
      id: "billing_and_settlement.updated_by",
      label: "Last Updated By",
      field: "billing_and_settlement.updated_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const updatedBy = appData.users.find(
          (ele) => ele?.id === row?.billing_and_settlement?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updated_at?.isSelected,
      id: "billing_and_settlement.updated_at",
      label: "Last Updated At",
      fieldName: "billing_and_settlement.updated_at",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const updatedAt = !!row.billing_and_settlement.updated_at
          ? `${getDateString(
              row.billing_and_settlement.updated_at
            )} ${getFormattedTime(
              new Date(row.billing_and_settlement.updated_at)
            )}`
          : "";
        return (
          <Tooltip title={updatedAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {updatedAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const renderGridActionTab = (isBottomNav = false) => {
    return (
      <div
        className={clsx("w-100 d-flex f-align-center", {
          "f-justify-between": !isBottomNav,
          "f-justify-center": isBottomNav,
        })}
      >
        {!isRoleView && <div>{renderGridActions()}</div>}

        <div className="d-flex f-align-start f-justify-end">
          {" "}
          <Dropdown
            classes={{
              paper: classes.tunePaper,
            }}
            disablePortal={false}
            remainOpen
            isMultiSelect
            placement={isBottomNav ? "top-end" : "bottom-end"}
            options={Object.keys(state.dynamicColumns).map(
              (key) => state.dynamicColumns[key]
            )}
            customToggle={({ anchorRef, onClick }) => (
              <Tooltip title="Show/Hide column(s)" placement="top-start">
                <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            )}
            onChange={(options) => {
              const isSelectAll = options.some(
                (item) => item.value === "select_all"
              );
              const isCurrentSelectAll =
                state.dynamicColumns?.select_all?.isSelected;

              const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

              const isAllOptionSelected =
                Object.keys(state.dynamicColumns).length - 1 ===
                options.filter((item) => item.value !== "select_all").length;

              const updatedDynamicColumns = Object.keys(
                state.dynamicColumns
              ).reduce((acc, key) => {
                const isSelected = isClickedOnSelectAll
                  ? isSelectAll
                  : key === "select_all"
                  ? isAllOptionSelected
                  : !!options.some((item) => item.value === key);

                return {
                  ...acc,
                  [key]: {
                    ...state.dynamicColumns[key],
                    isSelected,
                  },
                };
              }, {});

              setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                dynamicColumns: updatedDynamicColumns,
              });
              setState((prevState) => ({
                ...prevState,
                dynamicColumns: {
                  ...prevState.dynamicColumns,
                  ...updatedDynamicColumns,
                },
              }));
            }}
          />
          <Tooltip placement="top-start" title="Filter">
            <IconButton
              color="primary"
              className={clsx({
                "bg-primary": !!state.pageFilters?.length,
              })}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  isFiltering: true,
                }));
              }}
            >
              <FilterListIcon
                className={clsx({
                  "color-white": !!state.pageFilters?.length,
                })}
              />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top-end" title="Reset All Filters">
            <IconButton
              disabled={pageConfig === undefined || pageConfig === null}
              color="primary"
              onClick={() => {
                updatePageConfig("billing_settlement-review");
                window.location.reload();
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCautionFlagTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    fetchSettlementCompanyList();
    fetchVehicleTypeList();
    fetchCautionFlagTypeList();
    fetchPDXCompanyList();
    fetchCustomerLocation();
    fetchLocalResources();
    fetchList("customers", noop);
    fetchList("icList", noop);
    fetchStandByRateTypeList();
    // fetchList("IC", noop);
    fetchReviewNotesList();
    fetchEntries(
      state.scheduleDate,
      state.kam,
      state.selectedLocation,
      state.manager,
      state.customer,
      state.pageFilters,
      true
    );
  }, [
    fetchEntries,
    state.scheduleDate,
    state.kam,
    state.selectedLocation,
    state.manager,
    state.customer,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchList("users");
  }, [match.params.path]);

  return (
    <>
      <div
        className="mr-5"
        ref={
          state.entries.some((row) => row.id === state.clickedRowId)
            ? selectedRef
            : null
        }
      >
        <div className={clsx("mb-3 mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Billing & Settlement Review
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleView
              ? " Approve/Reject Billing & Settlement Information."
              : " View Billing & Settlement Information."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between ">
          <div className="d-flex f-wrap">
            <div className="mb-2 mr-2">
              <Datepicker
                mask
                label="Schedule Date"
                selected={state.scheduleDate}
                placement={"bottom-start"}
                classes={{
                  datepickerWrapper: classes.scheduleDateWrapper,
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                isClearable
                onCalendarClose={() => {}}
                onCalendarOpen={() => {}}
                onMonthChange={() => {}}
                onChange={(scheduleDate) => {
                  setState((prevState) => ({
                    ...prevState,
                    scheduleDate: getDateString(scheduleDate),
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    scheduleDateFilter: getDateString(scheduleDate),
                  });
                }}
              />
            </div>

            <div className="mr-2 mb-2">
              <Autocomplete
                // fullWidth
                size="small"
                classes={{
                  root: classes.autocompleteFilters,
                  listbox: classes.autocompleteFilters,
                }}
                value={state.kam}
                options={kamOptions}
                getOptionLabel={({ first_name, last_name, email, username }) =>
                  `${first_name || " "} ${
                    last_name || ""
                  }-${email}-(${username})`
                }
                renderOption={({
                  first_name,
                  last_name,
                  email,
                  is_active,
                  username,
                }) => (
                  <Typography
                    variant="body1"
                    className={clsx({
                      "color-text-disabled": !is_active,
                    })}
                  >{`${first_name || " "} ${
                    last_name || ""
                  }-${email}-(${username})`}</Typography>
                )}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton
                      variant="rect"
                      width={148}
                      height={36}
                      className="ml-5 mr-4"
                    />
                  ) : (
                    <TextField
                      {...params}
                      label="KAM"
                      variant="outlined"
                      // className="ml-5"
                    />
                  )
                }
                onChange={(evt, kam) => {
                  setState((prevState) => ({
                    ...prevState,
                    kam,
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    kamFilter: kam,
                  });
                }}
              />
            </div>
            <div
              className={clsx("mr-2 mb-2", { "mt-2": appData.isTabletView })}
            >
              <Autocomplete
                fullWidth
                size="small"
                value={state.customer}
                classes={{
                  root: classes.autocompleteFilters,
                }}
                options={appDataList.customers}
                getOptionLabel={({ name }) => `${name}`}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton variant="rect" width={148} height={36} />
                  ) : (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      // className="ml-10 mr-10"
                    />
                  )
                }
                onChange={(evt, customer) => {
                  setState((prevState) => ({
                    ...prevState,
                    customer,
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    customerFilter: customer,
                  });
                }}
              />
            </div>
            <div
              className={clsx("mr-2 mb-2", { "mt-2": appData.isTabletView })}
            >
              <Autocomplete
                classes={{
                  root: classes.autocompleteFilters,
                }}
                size="small"
                fullWidth
                value={state.selectedLocation}
                options={locationOPtions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                    />
                  )
                }
                getOptionSelected={(option, value) =>
                  option.label === value.label
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedLocation: value,
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    locationFilter: value,
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
            </div>

            {(isAdminRole || isRoleMGR) && (
              <div
                className={clsx("mr-2 mb-2", {
                  "mt-2": appData.isTabletView,
                })}
              >
                <Autocomplete
                  fullWidth
                  size="small"
                  value={state.manager}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={appDataList.users.mgr.filter(
                    (mgr) => mgr.is_active === true
                  )}
                  getOptionLabel={({ first_name, last_name, email }) =>
                    `${first_name} ${last_name}-${email}`
                  }
                  renderInput={(params) =>
                    state.fetching.users ? (
                      <Skeleton variant="rect" width={148} height={36} />
                    ) : (
                      <TextField
                        {...params}
                        label="MGR/AA's Manager"
                        variant="outlined"
                        className="w-100"
                      />
                    )
                  }
                  onChange={(evt, manager) => {
                    setState((prevState) => ({
                      ...prevState,
                      manager,
                    }));
                    setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                      managerFilter: manager,
                    });
                    gridHelper && gridHelper.resetSelection();
                  }}
                />
              </div>
            )}

            {!appData.isTabletView && (
              <>
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumns).map(
                    (key) => state.dynamicColumns[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip
                      title="Show/Hide column(s)..."
                      placement="top-start"
                    >
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumns?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumns).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumns
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);
                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumns[key],
                          isSelected,
                        },
                      };
                    }, {});
                    setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                      dynamicColumns: updatedDynamicColumns,
                    });
                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumns: {
                        ...prevState.dynamicColumns,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    className={clsx({
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    disabled={state.fetching.users}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    // disabled={pageConfig === undefined || pageConfig === null}
                    color="primary"
                    onClick={() => {
                      updatePageConfig("billing_settlement-review");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
          {!appData.isTabletView && (
            <div
              className={clsx(
                "d-flex f-align-start f-justify-end",
                classes.rightAction
              )}
            >
              {/* <Typography variant="body2">
              <b>BR</b>- Base Roster <br></br>
              <b>DS</b>- Daily Schedule
            </Typography> */}
            </div>
          )}
        </div>
        {appData.isTabletView && (
          <div className="d-flex f-justify-end ml-2 mt-6">
            {" "}
            <Typography variant="body2">
              {/* <b>BR</b>- Base Roster <b>DS</b>- Daily Schedule */}
            </Typography>
          </div>
        )}

        <Paper elevation={2} className="p-4 mt-2 h-100 mb-4">
          <div className="d-flex f-justify-between" ref={actionRef}>
            <Button
              startIcon={<NoteAddIcon />}
              className="mr-5"
              variant="outlined"
              disabled={state.selectedRows.length < 1}
              color="primary"
              onClick={() =>
                setState((prevState) => ({ ...prevState, AddReviewNote: true }))
              }
            >
              Add Shared Note
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              startIcon={<CloudDownloadIcon />}
              disabled={
                state.isDownloadLoading ||
                !state.totalEntries ||
                state.isFetching
              }
              onClick={() => {
                handleDownload(
                  state.scheduleDate,
                  state.kam,
                  state.selectedLocation,
                  state.manager,
                  state.customer,
                  state.pageFilters
                );
              }}
            >
              Download
              {state.isDownloadLoading && (
                <CircularProgress size={24} className={classes.progressBtn} />
              )}
            </Button>
          </div>
          {appData.isTabletView && (
            <div className="d-flex f-justify-start f-align-end ml-4">
              {renderGridActionTab()}
            </div>
          )}

          <CollapsibleGrid
            wrapRef2={wrapRef2}
            isRowOpen={state.isRowOpen}
            tableHeadRef={tableHeadRef}
            handleRowOpen={handleRowOpen}
            selectedRef={selectedRef}
            boxborder={state.clickedRowId}
            handleScroll={handleScroll}
            columns={columnConfig}
            rows={state.entries.map((entry) => ({
              ...entry,
              customer_name:
                entry?.billing_and_settlement?.customer_branch?.customer?.name,
              unique_key: entry.uniqueKey?.customer?.name,
              className: clsx({
                [classes.rowChanges]: entry.keyType === "original",
                [classes.pointerEventsAuto]: entry.keyType !== "Updated",
              }),
            }))}
            isLoading={state.isFetching}
            uniqueBy="unique_key"
            hasSelection={!isRoleView}
            onReady={(instance) => (gridHelper = instance)}
            onSelectionChange={handleSelectionChange}
            isBillingAndSettelementReviewRowSelected={true}
            rowEvents={[
              {
                type: "onDoubleClick",
                handler: (row) => {
                  setState((prevState) => ({
                    ...prevState,
                    clickedRowId: row.id,
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    clickedRowId: {
                      id: row.id,
                      customerName: row.uniqueKey?.customer?.name,
                    },
                  });
                },
              },
              {
                type: "onClick",
                handler: (row) => {
                  setState((prevState) => ({
                    ...prevState,
                    clickedRowId: row.id,
                  }));
                  setPageConfig(PAGE_KEYS.BILLING_SETTLEMENT_REVIEW, {
                    clickedRowId: {
                      id: row.id,
                      customerName: row.uniqueKey?.customer?.name,
                    },
                  });
                },
              },
            ]}
            gridActions={
              !appData.isTabletView
                ? !isRoleView
                  ? renderGridActions()
                  : []
                : []
            }
            classes={{
              gridActions: "f-justify-end",
            }}
          />
        </Paper>
        {appData.isTabletView && (
          <div className={clsx({ "d-none mt-2": isActionBarOnScreen })}>
            <BottomNavigation
              component={Paper}
              elevation={4}
              className={classes.bottomNavigation}
            >
              <BottomNavigationAction
                component={() => renderGridActionTab(true)}
              />
            </BottomNavigation>
          </div>
        )}
        {state.showConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.showConfirmationPopup}
            contentText="Are you sure you want to approve the selected record's?"
            onConfirm={() => handleApprove()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.AddReviewNote && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.notesPaperSize,
            }}
            open={!!state.AddReviewNote}
            contentText={
              <>
                <Typography variant="body1" className="mt-2">
                  <strong>Add new review notes for selected records.</strong>
                </Typography>
                <br></br>{" "}
                <div className="d-flex" style={{ gap: "6px" }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    freeSolo
                    options={state.reviewNotesList}
                    getOptionLabel={(option) => option.value}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={!!state.errors?.notes?.trim()}
                        helperText={state.errors?.notes}
                        variant="outlined"
                        label="Add New Review Note"
                        onChange={handleFieldChange}
                      />
                    )}
                    onChange={handleFieldChange}
                  />
                  {!isRoleMGR && (
                    <Tooltip
                      title="Add To Lookup(Review Notes)"
                      placement="top-end"
                    >
                      <Button
                        type="submit"
                        onClick={handleShowAddLookupDialog}
                        variant="contained"
                        color="primary"
                        disabled={!state.notes || validate()}
                        className={classes.buttonStyle}
                      >
                        <PostAddIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </>
            }
            onConfirm={() => {
              if (state.selectedRows.length > 0) {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (state.selectedRows.includes(entry.id)) {
                      return {
                        ...entry,
                        billing_and_settlement: {
                          ...entry.billing_and_settlement,
                          review_notes: state.notes,
                          sharedNotes: state.notes,
                        },
                      };
                    }
                    return entry;
                  });

                  return {
                    ...prevState,
                    entries: updatedEntries,
                  };
                });
              }

              setState((prevState) => ({
                ...prevState,
                AddReviewNote: false,
                notes: defaultState.notes,
              }));
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                AddReviewNote: false,
                notes: defaultState.notes,
                errors: {
                  notes: "",
                },
              }))
            }
            isConfirmDisabled={validate() || !state.notes?.trim()}
            positiveActionLabel={
              <>
                Add
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.showIcDetails && (
          <IcForm
            open={!!state.showIcDetails}
            isTabletView={appData.isTabletView}
            isFetching={state.isIcFetching}
            entry={appDataList.IC[0]}
            usersList={appData.users}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                showIcDetails: false,
              }));
            }}
          />
        )}
        {state.confirmDontBill && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBill}
            contentText="By Checking, bill rate will become 0. This action will not be reverted once done"
            onConfirm={() => {
              calculateBill(
                state.doNotBillRowID,
                state.doNotBillType,
                state.confirmDontBillValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontBill: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBill: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPay && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPay}
            contentText="By Checking, pay rate will become 0. This action will not be reverted once done"
            onConfirm={() => {
              calculatePay(
                state.doNotPayRowID,
                state.doNotpayType,
                state.confirmDontPayValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontPay: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPay: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontBillExtraMiles && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBillExtraMiles}
            contentText=" By Checking, bill extra miles will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculateBillMiles(
                state.doNotBillExtraMilesID,
                state.doNotBillMilesType,
                state.confirmDontBillExtraMilesValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontBillExtraMiles: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBillExtraMiles: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPayExtraMiles && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPayExtraMiles}
            contentText="By Checking, pay extra miles will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculatePayMiles(
                state.doNotPayExtraMilesID,
                state.doNotpayMilesType,
                state.confirmDontPayExtraMilesValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontPayExtraMiles: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPayExtraMiles: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontBillToll && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBillToll}
            contentText=" By Checking, bill toll amount will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculateBillToll(
                state.doNotBillTollID,
                state.doNotBillTollType,
                state.confirmDontBillTollValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontBillToll: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBillToll: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPayToll && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPayToll}
            contentText=" By Checking, pay toll amount will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculatePayToll(
                state.doNotPayTollID,
                state.doNotpayTollType,
                state.confirmDontPayTollValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontPayToll: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPayToll: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.showExistingNotesModal && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.showExistingNotesModal}
            contentText={
              !!state.existingModalContent?.length
                ? state.existingModalContent
                : "No Existing Notes."
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                showExistingNotesModal: false,
              }))
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showExistingNotesModal: false,
              }))
            }
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={null}
          />
        )}
        {state.showAddLookupDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.addLookupEmailpaperSize,
            }}
            open={!!state.showAddLookupDialog}
            contentText={
              <>
                <Typography variant="body2" className="mt-2 text-bold">
                  {`Would you also like to add this review note `}
                  <span style={{ color: "Black" }}>
                    {state.notes ||
                      (newReviewNotes
                        ? newReviewNotes.billing_and_settlement.sharedNotes
                        : "")}
                  </span>
                  {` in the Lookup(Review Notes)?`}
                </Typography>
              </>
            }
            onConfirm={() => {
              addLookupValue(state.showAddLookupDialog);
              setTimeout(() => {
                fetchReviewNotesList();
              }, 2000);
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                showAddLookupDialog: false,
              }));
            }}
            isConfirmDisabled={state.isLookupLoading}
            positiveActionLabel={
              <>
                ADD TO LOOKUP
                {state.isLookupLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>CANCEL</>}
          />
        )}
        {state.showRejectConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.showRejectConfirmationPopup}
            contentText="Are you sure you want to reject the selected record's?"
            onConfirm={() => handleReject()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showRejectConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
      </div>
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={handleScroll}
          style={{
            overflowX: "auto",
            width: appData.isTabletView
              ? "calc(100vw - 42px)"
              : "calc(100vw - 110px)",
            height: "2%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              width: state.dimensions,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
      {state.payQuantityExceedDaily && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.quantityExceedpaperSize,
          }}
          open={state.payQuantityExceedDaily}
          contentText={
            <>
              <Typography variant="h6" color="error" className="mb-4 text-bold">
                Bill/Pay Quantity should not exceed 1.99 for Bill/Pay Rate Type
                'Daily' in the following entrie(s):
              </Typography>
              <Grid
                columns={quantityExceedColumnConfig}
                rows={state.payQuantityExceedDaily}
                actionBarConfig={null}
                isLoading={false}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              payQuantityExceedDaily: defaultState.payQuantityExceedDaily,
            }));
          }}
          showPositiveActionLabel={false}
          hasClose={true}
          negativeActionLabel="Close"
        />
      )}
    </>
  );
};

export default ViewBillingSettlementScheduleReview;
