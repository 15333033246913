import { http, responseFormatter } from "utils";

const sendBulkMails = (data) => {
  return responseFormatter(
    http.post("/bulk/send-bulk-email", data, {
      setAuthHeader: true,
    })
  );
};

const getBulkEmailHistory = (query) => {
  return responseFormatter(
    http.get(`/bulk/email-history${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getICData = (query) => {
  return responseFormatter(
    http.get(`/individual-contractors/get-ic-data/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerEmails = (query) => {
  return responseFormatter(
    http.get(`/bulk/all-customer-emails${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getContractorEmails = (query) => {
  return responseFormatter(
    http.get(`/bulk/all-contractor-emails${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const BulkMailService = {
  sendBulkMails,
  getBulkEmailHistory,
  getICData,
  getCustomerEmails,
  getContractorEmails,
};

export default BulkMailService;
