import { useState, useMemo, useContext, useEffect } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  exportBase64ToFile,
  getTokenData,
  getDateString,
  hasFeatureAccess,
} from "utils";
import Service from "../service";
import BulkUpload from "../bulk-upload";
import ErrorModal from "../error-modal";
import { PERMISSION, ROLE } from "../../shared/constants";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import RadioGroup from "@material-ui/core/RadioGroup";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import SharedService from "modules/shared/service";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { AppContext } from "shared/contexts";
import { isTransferScheduleEnable, noop } from "shared/constants";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";

const DEFAULT_EXCHANGE_TYPES = [
  { label: "Base Roster", value: "baseRosters" },
  { label: "Independent Contractors", value: "individualContractors" },
  { label: "WCP-Driver", value: "wcpDriver" },
];

const defaulState = {
  exchangeType: null,
  isUploadOpen: false,
  isLoading: false,
  isTemplateLoading: false,
  isTemplate: false,
  sheetError: null,
  openForceSubmitDialog: false,
};

const ViewExchange = ({ history }) => {
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);
  //const classes = useStyles();
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const userData = getTokenData();

  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;

  const isRoleMGR = (userData?.role || "").toLowerCase() === ROLE.MGR;
  const [state, setState] = useState({
    ...defaulState,
    exchangeType: isRoleKAM ? DEFAULT_EXCHANGE_TYPES[0] : null,
  });
  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    // if (listType === "customers") {
    //   listData.map((item = {}) => {
    //     (item.customer_branches || []).map((branch = {}) => {
    //       (branch.routes || []).sort((a, b) =>
    //         Intl.Collator().compare(a.name, b.name)
    //       );
    //     });
    //   });
    // }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const handleDownload = async (type, isTemplate = false) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: isTemplate ? prevState.isLoading : true,
      isTemplateLoading: isTemplate ? true : prevState.isTemplateLoading,
    }));
    const { data, error } = await Service.download(type, isTemplate);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      const label = (state.exchangeType?.label || "")
        .toLocaleLowerCase()
        .split("")
        .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
        .join("");
      isTemplate
        ? toast.success(`${label} template downloaded successfully.`)
        : toast.success(`${label} records downloaded successfully.`);
      const fileName = isTemplate ? label + " Template" : label;
      exportBase64ToFile(data, fileName);
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      isTemplateLoading: false,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
      fetchLeaveTypeList();
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
    };
  }, []);

  useEffect(() => {
    if (isRoleKAM) {
      fetchList("users");
    }
  }, []);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const permittedExchangeTypes = useMemo(() => {
    const permittedTypes = DEFAULT_EXCHANGE_TYPES.slice();
    // if (hasFeatureAccess(PERMISSION.MODIFY_IC)) {
    //   permittedTypes.push({
    //     label: "Independent Contractors",
    //     value: "individualContractors",
    //   });
    // }

    if (hasFeatureAccess(PERMISSION.MODIFY_CUSTOMER) || isRoleKAM) {
      permittedTypes.push({ label: "Customers", value: "customers" });
    }

    if (hasFeatureAccess(PERMISSION.MODIFY_USERS)) {
      permittedTypes.push({ label: "Users", value: "users" });
    }

    return permittedTypes;
  }, []);

  return (
    <>
      <div className={clsx("mb-4 mt-2 mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary">
          Upload/Download
          <Button
            style={{ float: "right", marginTop: "2.5px" }}
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
        </Typography>
      </div>
      <div
        className={clsx("w-75", {
          "w-100": appData.isTabletView,
        })}
      >
        <Paper elevation={4} className={clsx("p-4 pt-8 pl-8", classes.paper)}>
          {isRoleKAM ? (
            <FormControl>
              <FormLabel className="color-grey mb-4">
                Please select type of data you want to upload or download.
              </FormLabel>
              <RadioGroup
                value={state.exchangeType?.value}
                onChange={(evt) => {
                  const exchangeType = permittedExchangeTypes.find(
                    (type) => type.value === evt.target.value
                  );
                  setState((prevState) => ({ ...prevState, exchangeType }));
                }}
              >
                {permittedExchangeTypes.map(({ label, value }) => (
                  <FormControlLabel
                    disabled={state.isLoading}
                    value={value}
                    control={<Radio color="primary" />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <FormControl>
              <FormLabel className="color-grey mb-4">
                Please select type of data you want to upload or download.
              </FormLabel>
              <RadioGroup
                value={state.exchangeType?.value}
                onChange={(evt) => {
                  const exchangeType = permittedExchangeTypes.find(
                    (type) => type.value === evt.target.value
                  );
                  setState((prevState) => ({ ...prevState, exchangeType }));
                }}
              >
                {permittedExchangeTypes.map(({ label, value }) => (
                  <FormControlLabel
                    disabled={state.isLoading}
                    value={value}
                    control={<Radio color="primary" />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          <div className="d-flex mt-2 f-justify-end">
            {!isRoleView &&
              !(
                isRoleKAM &&
                (state.exchangeType?.value === "individualContractors" ||
                  state.exchangeType?.value === "wcpDriver" ||
                  state.exchangeType?.value === "customers")
              ) &&
              !(
                isRoleMGR &&
                (state.exchangeType?.value === "individualContractors" ||
                  state.exchangeType?.value === "wcpDriver")
              ) && (
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2"
                  startIcon={<CloudUploadIcon />}
                  disabled={
                    !state.exchangeType ||
                    state.isLoading ||
                    state.isTemplateLoading
                  }
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isUploadOpen: true,
                    }))
                  }
                >
                  Upload
                </Button>
              )}

            <Button
              variant="contained"
              color="secondary"
              className="ml-2 mr-2"
              startIcon={<CloudDownloadIcon />}
              disabled={
                !state.exchangeType ||
                state.isLoading ||
                state.isTemplateLoading
              }
              onClick={() => handleDownload(state.exchangeType?.value)}
            >
              Download
              {state.isLoading && (
                <CircularProgress size={24} className={classes.progressBtn} />
              )}
            </Button>
            {!isRoleView &&
              !(
                isRoleKAM &&
                (state.exchangeType?.value === "individualContractors" ||
                  state.exchangeType?.value === "wcpDriver" ||
                  state.exchangeType?.value === "customers")
              ) &&
              !(
                isRoleMGR &&
                (state.exchangeType?.value === "individualContractors" ||
                  state.exchangeType?.value === "wcpDriver")
              ) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="ml-2 mr-2"
                  startIcon={<CloudDownloadIcon />}
                  disabled={
                    !state.exchangeType ||
                    state.isTemplateLoading ||
                    state.isLoading
                  }
                  onClick={() =>
                    handleDownload(state.exchangeType?.value, true)
                  }
                >
                  Download Template
                  {state.isTemplateLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              )}
          </div>
        </Paper>
      </div>
      {state.isUploadOpen && (
        <BulkUpload
          open={state.isUploadOpen}
          exchangeType={state.exchangeType}
          onClose={() =>
            setState((prevState) => ({ ...prevState, isUploadOpen: false }))
          }
          onSheetError={(sheetError) =>
            setState((prevState) => ({ ...prevState, sheetError }))
          }
        />
      )}
      {!!state.sheetError && (
        <ErrorModal
          open={!!state.sheetError}
          error={state.sheetError}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              sheetError: null,
            }))
          }
        />
      )}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          onClose={handleClose}
          kamId={kamId}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onForceSubmit={() => fetchList("pendingItems", kamId)}
        />
      )}
    </>
  );
};

export default ViewExchange;
