import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 800,
    height: 600,
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  tunePaper: {
    minWidth: 156,
  },
  infoIcon: {
    cursor: "default",
    color: "#004700",
  },
  weekInputProps: { pointerEvents: "auto" },
}));

export default useStyles;
