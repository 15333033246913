/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useCallback, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { noop } from "shared";
import clsx from "clsx";
import Grid from "../grid";
import GridLoader from "../loader";
import useStyles from "../style";
import NoRecords from "assets/images/norecord.svg";

let gridHelpers = {};

const defaultState = {
  openStates: {},
  selectedRows: {},
};

let collapsedRows = [];

const DSCollapsibleGrid = ({
  rows = [],
  columns = [],
  entries = [],
  isLoading = false,
  gridActions = [],
  isTableLoading = false,
  hasSelection = true,
  gridClasses = {},
  rowEvents = [],
  onReady = noop,
  clearAllEntries = noop,
  onSelectionChange = noop,
  expandLocation = noop,
  gridRefs = null,
  wrapRef2,
  wrapRef1,
  tableHeadRef,
  handleScroll = noop,
  syncChildGridsScroll = noop,
  handleRowOpen = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const toggleCollapse = useCallback(
    (id, type, locationName, customerName) => {
      setState((prevState) => {
        const isOpen = !prevState.openStates[type]?.[id];

        if (isOpen && locationName && type === "location") {
          expandLocation(id, locationName, customerName);

          // Reset the scroll position when expanding a location
          if (wrapRef1.current) {
            wrapRef1.current.scrollLeft = 0;
          }
          if (wrapRef2.current) {
            wrapRef2.current.scrollLeft = 0;
          }
          syncChildGridsScroll(0);
        }

        let updatedOpenStates = { ...prevState.openStates };
        if (type === "customer" && !isOpen) {
          const customerLocations = rows.find(
            (row) => row.customer.id === id
          ).customer_locations;
          customerLocations.forEach((location) => {
            if (updatedOpenStates.location?.[location.id]) {
              collapsedRows = collapsedRows.filter(
                (item) => item !== `location - ${location.id}` && item !== null
              );
            }
            updatedOpenStates = {
              ...updatedOpenStates,
              location: {
                ...updatedOpenStates.location,
                [location.id]: false,
              },
            };
          });
        }

        collapsedRows = prevState.openStates[type]?.[id]
          ? collapsedRows.filter(
              (item) => item !== `location - ${id}` && item !== null
            )
          : type === "location"
          ? [...collapsedRows, `location - ${id}`]
          : collapsedRows;

        handleRowOpen(collapsedRows);

        return {
          ...prevState,
          openStates: {
            ...updatedOpenStates,
            [type]: {
              ...updatedOpenStates[type],
              [id]: isOpen,
            },
          },
        };
      });
    },
    [expandLocation, rows]
  );

  const handleSelectionChange = useCallback(
    (evt, locationId) => {
      setState((prevState) => {
        const updatedSelectedRows = {
          ...prevState.selectedRows,
          [locationId]: evt,
        };
        onSelectionChange(Object.values(updatedSelectedRows).flat());
        return {
          ...prevState,
          selectedRows: updatedSelectedRows,
        };
      });
    },
    [onSelectionChange]
  );

  useEffect(() => {
    onReady({
      resetSelection: () => {
        Object.keys(gridHelpers).map((key) => {
          gridHelpers[key] && gridHelpers[key].resetSelection();
        });
        setState((prevState) => ({
          ...prevState,
          selectedRows: {},
        }));
      },
      setSelection: (selectedRows) =>
        setState((prevState) => ({
          ...prevState,
          selectedRows,
        })),
      getSelection: () => state.selectedRows,
      toggleAll: (open = false) => {
        Object.keys(gridHelpers).forEach((key) => {
          if (gridHelpers[key]) {
            gridHelpers[key].toggle(open);

            if (!open) {
              clearAllEntries();
              setState((prevState) => {
                return {
                  ...prevState,
                  openStates: {},
                };
              });
            }
          }
        });
      },
    });
  }, [onReady, state.selectedRows]);

  if (rows?.length === 0 && !isLoading) {
    return (
      <div className="d-flex f-justify-center">
        <img alt="No record" className={classes.noRecordsImg} src={NoRecords} />
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        [...new Array(5).fill("Loading")].map((_, index) => (
          <GridLoader key={index} columns={columns.length} />
        ))
      ) : (
        <>
          {rows.map(({ customer, customer_locations }) => (
            <Fragment key={customer.id}>
              <CustomerRow
                classes={classes}
                customer={customer}
                isOpen={state.openStates.customer?.[customer.id] || false}
                toggleCollapse={() => toggleCollapse(customer.id, "customer")}
              />
              <div ref={wrapRef2} onScroll={handleScroll}>
                {state.openStates.customer?.[customer.id] &&
                  customer_locations.map((location) => (
                    <Fragment key={location.id}>
                      <LocationRow
                        classes={classes}
                        location={location}
                        entries={(!!entries && entries[location.id]) || []}
                        columns={columns}
                        isTableLoading={
                          !!entries && !!isTableLoading[location.id]
                        }
                        hasSelection={hasSelection}
                        gridClasses={gridClasses}
                        rowEvents={rowEvents}
                        customer={customer}
                        onReady={(gridHelper) =>
                          (gridHelpers[customer.customer_name] = gridHelper)
                        }
                        onSelectionChange={(evt) =>
                          handleSelectionChange(evt, location.id)
                        }
                        isOpen={
                          state.openStates.location?.[location.id] || false
                        }
                        toggleCollapse={() =>
                          toggleCollapse(
                            location.id,
                            "location",
                            location.location,
                            customer.customer_name
                          )
                        }
                        gridRefs={gridRefs}
                        handleScroll={handleScroll}
                        tableHeadRef={tableHeadRef}
                      />
                    </Fragment>
                  ))}
              </div>
            </Fragment>
          ))}
          {!!rows.length && (
            <div
              className={clsx("d-flex f-align-center", gridClasses.gridActions)}
            >
              {gridActions.map((action) => action)}
            </div>
          )}
        </>
      )}
    </>
  );
};

const CustomerRow = ({ classes, customer, isOpen, toggleCollapse }) => (
  <TableRow
    className={classes.tablePadding}
    hover
    onClick={() => toggleCollapse(customer.id, "customer")}
  >
    <TableCell className="p-2">
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          toggleCollapse(customer.id, "customer");
        }}
      >
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
    <TableCell colSpan={2} className={classes.fullWidthCell}>
      <Typography variant="body2" className={classes.customerName}>
        {customer.customer_name}
      </Typography>
    </TableCell>
  </TableRow>
);

const LocationRow = ({
  classes,
  location,
  isOpen,
  toggleCollapse,
  entries,
  columns,
  isTableLoading,
  hasSelection,
  gridClasses,
  rowEvents,
  customer,
  onReady,
  onSelectionChange,
  gridRefs,
  handleScroll,
  tableHeadRef,
}) => (
  <>
    <TableRow
      hover
      className={classes.tablePadding}
      onClick={() => {
        toggleCollapse(
          location.id,
          "location",
          location.location,
          customer.customer_name
        );
      }}
    >
      <TableCell></TableCell>
      <TableCell className="p-2">
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            toggleCollapse(
              location.id,
              "location",
              location.location,
              customer.customer_name
            );
          }}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell className={classes.fullWidthCell}>
        <div className="d-flex f-align-center f-justify-between">
          <Typography variant="body2">{location.location}</Typography>
          {!!entries && entries.length > 0 && (
            <Typography
              variant="body1"
              className="mr-6 text-bold"
              color="black"
            >
              {`${entries.length} Record(s)`}
            </Typography>
          )}
        </div>
      </TableCell>
    </TableRow>
    {isOpen && (
      <TableRow>
        <TableCell colSpan={3} className={classes.tableCellShadow}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {isTableLoading ? (
              <GridLoader
                open={isOpen}
                columns={columns.length}
                pageSize={entries.length}
              />
            ) : (
              entries.length > 0 && (
                <div className={classes.tableContainer}>
                  <Grid
                    rows={entries}
                    columns={columns.filter(
                      (column) => column.id !== location.id
                    )}
                    open={isOpen}
                    hasSelection={hasSelection}
                    classes={gridClasses}
                    rowEvents={rowEvents}
                    onReady={onReady}
                    onSelectionChange={onSelectionChange}
                    location={location}
                    gridRefs={gridRefs}
                    handleScroll={handleScroll}
                    tableHeadRef={tableHeadRef}
                  />
                </div>
              )
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    )}
  </>
);

export default DSCollapsibleGrid;
