/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Paper,
  TextField,
  Tooltip,
  Typography,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Chip,
  FormLabel,
  Link,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import useStyles from "./style";
import { ActionDialog, Datepicker, Dropdown, Grid } from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import {
  getDateString,
  getPageConfig,
  getReadableFileSizeString,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateLayout,
  updatePageConfig,
  updatePagination,
} from "utils";
import { AppContext, PAGE_KEYS, noop } from "shared";

import { SharedService } from "modules/shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const STATUS = [
  { label: "None", value: null },
  { label: "Both", value: "all" },
  { label: "Only Customers", value: "customer" },
  { label: "Only Contractors", value: "contractor" },
];

const defaultState = {
  entries: [],
  pdxCompanyList: [],
  dateFilterType: "single",
  customer: [],
  pdxCompany: [],
  IC: [],
  filters: {
    customer: [],
    pdxCompany: [],
    IC: [],
    endDate: [],
    startDate: [],
    status: [],
  },
  totalEntries: 0,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  isLoading: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    locations: false,
    IC: false,
  },
  email: "",
  from: "",
  emailSubject: "",
  emailBody: "",
  ccEmail: "",
  ccEmailArray: [],
  selectedCustomerEmails: [],
  expanded: false,
  icExpanded: false,
  filteredIcEmails: [],
};

let timeout = null;

const ViewBulkEmailHistory = ({ history, location }) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const pageConfig = getPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY);
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    dateFilterType:
      (pageConfig && pageConfig?.dateFilterType) || defaultState.dateFilterType,
    pdxCompany:
      (pageConfig && pageConfig?.pdxCompanyFilter?.value) ||
      defaultState.pdxCompany,
    customer:
      (pageConfig && pageConfig?.customerFilter?.value) ||
      defaultState.customer,
    IC: (pageConfig && pageConfig?.ICFilter?.value) || defaultState.IC,
    filters: {
      ...defaultState.filters,
      pdxCompany:
        (pageConfig && pageConfig?.pdxCompanyFilter?.pdxCompany) || [],
      customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      IC: (pageConfig && pageConfig?.ICFilter?.IC) || [],
      status: (pageConfig && pageConfig?.statusUser?.status) || [],
      startDate: [
        {
          field: "date",
          type: "=",
          value: (pageConfig && pageConfig?.startDateFilter) || null,
        },
      ],
      endDate:
        (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    },
  });

  const appDataList = useMemo(() => {
    const usersList = appData.users;
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        mgr: [],
        aa: [],
      }
    );
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const IC = appData.IC.filter((ic) => ic.is_active);
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));

    return {
      customers,
      users,
      usersList,
      IC,
      icList,
    };
  }, [appData]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      // eslint-disable-next-line array-callback-return
      listData.map((item = {}) => {
        // eslint-disable-next-line array-callback-return
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList: data?.rows || defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const getCustomerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some((branch) =>
        state.pdxCompany?.some(
          (company) =>
            company.id === branch.pdx_company_id &&
            branch.is_active &&
            !branch.is_deleted
        )
      )
    );
  }, [state.pdxCompany]);

  const fetchEntries = useCallback(
    async (filters, pageSize, pageNumber, order, orderBy, pageFilters = []) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const sortObj = { field: orderBy, order };

      let filtersArr = [];

      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.startDate];
      }

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (filters.IC.length && filters.IC[0].value) {
        filtersArr = [...filtersArr, ...filters.IC];
      }
      if (filters.status.length && filters.status) {
        filtersArr = [...filtersArr, ...filters.status];
      }

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        sortObj
      );

      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][date][gte]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }

      const { data, error } = await Service.getBulkEmailHistory(queryString);

      if (error) {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        return null;
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        totalEntries: data.count,
      }));
      return data;
    },
    [state.dateFilterType]
  );

  useEffect(() => {
    fetchEntries(
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
  ]);

  useEffect(() => {
    fetchList("customers", noop);
    fetchList("users", noop);
    fetchList("icList", noop);
    fetchPDXCompanyList();
  }, []);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
    }));
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View Detailed Email History" placement="top-start">
          <VisibilityIcon
            className={"ml-2 mr-2 c-pointer"}
            color="primary"
            onClick={() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  showSendBulkMailPopup: true,
                  filteredIcEmails: row?.to?.ic_emails || [],
                  selectedCustomerEmails: row?.to?.customer_emails || [],
                  ccEmailArray: row?.cc_emails || defaultState.ccEmailArray,
                  emailSubject: row?.subject || null,
                  emailBody: row?.email_body || null,
                  attachmentFiles: row?.attachments?.attachmentFiles || [],
                };
              });
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "date",
      label: "Sent Date",
      render: (row) => {
        return (
          <Tooltip
            title={getUTCDateString(row.date) || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getUTCDateString(row.date)}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentBy",
      label: "Sent By",
      render: (row) => {
        const sentBy = appDataList.usersList.find(
          (ele) => ele?.id === row.created_by
        );
        let user = "";
        if (!!sentBy) {
          user = `${sentBy?.first_name}, ${sentBy?.last_name || ""} (${
            sentBy?.email
          } - ${sentBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentTo",
      label: "Sent To",
      render: (row) => {
        return (
          <Tooltip
            title={
              row?.type === "customer"
                ? "Customers..."
                : row?.type === "contractor"
                ? "Contractors..."
                : "Customers..., Contractors..." ?? ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row?.type === "customer"
                ? "Customers..."
                : row?.type === "contractor"
                ? "Contractors..."
                : "Customers..., Contractors..."}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
  ];

  return (
    <>
      <div className="mr-5">
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Bulk Emails Sent History
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/bulk-email")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Send Emails
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            View All Emails History Sent in Bulk.
          </Typography>
        </div>
        <>
          <div className="d-flex f-justify-between f-align-center">
            <div className="d-flex f-wrap">
              <div
                className={clsx({
                  "d-flex f-align-center mt-1": !appData.isTabletView,
                })}
              >
                <FormControl>
                  <RadioGroup
                    row
                    value={state.dateFilterType}
                    onChange={(evt) => {
                      const { value } = evt.target;
                      setState((prevState) => ({
                        ...prevState,
                        dateFilterType: value,
                        filters: {
                          ...prevState.filters,
                          startDate: [
                            {
                              field: "date",
                              type: value === "single" ? "=" : "gte",
                              value:
                                (pageConfig && pageConfig?.startDateFilter) ||
                                null,
                            },
                          ],
                          endDate:
                            value === "single"
                              ? []
                              : [
                                  {
                                    field: "date",
                                    type: "lte",
                                    value:
                                      (pageConfig &&
                                        pageConfig?.endDateFilter) ||
                                      getDateString(new Date()),
                                  },
                                ],
                        },
                        pageNumber: defaultState.pageNumber,
                      }));
                      setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                        dateFilterType: value,
                        endDateFilter:
                          (pageConfig && pageConfig?.startDateFilter) ||
                          getDateString(new Date()),
                      });
                    }}
                  >
                    <FormControlLabel
                      value="single"
                      control={<Radio size="small" color="primary" />}
                      label="Specific date"
                    />
                    <FormControlLabel
                      value="multiple"
                      control={<Radio size="small" color="primary" />}
                      label="Date range"
                    />
                  </RadioGroup>
                </FormControl>
                <div className="d-flex mt-3">
                  <Datepicker
                    mask
                    preventClear
                    selected={
                      state.filters.startDate.length &&
                      state.filters.startDate[0]?.value
                    }
                    label={
                      state.dateFilterType === "single"
                        ? "Email Sent Date"
                        : "Email Sent start date"
                    }
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: clsx("mr-4", classes.datepickerWrapper),
                      },
                    }}
                    maxDate={
                      state.filters.endDate[0]?.value
                        ? new Date(state.filters.endDate[0]?.value)
                        : pageConfig &&
                          pageConfig?.dateFilterType === "multiple" &&
                          pageConfig?.endDateFilter
                        ? new Date(pageConfig.endDateFilter)
                        : new Date()
                    }
                    onChange={(startDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        filters: {
                          ...prevState.filters,
                          startDate: [
                            {
                              field: "date",
                              type:
                                state.dateFilterType === "single" ? "=" : "gte",
                              value: getDateString(startDate),
                            },
                          ],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                        startDateFilter: getDateString(startDate),
                        endDateFilter:
                          state.dateFilterType === "single"
                            ? getDateString(startDate)
                            : (pageConfig && pageConfig?.endDateFilter) ||
                              getDateString(new Date()),
                      });
                    }}
                  />
                  {state.dateFilterType === "multiple" && (
                    <Datepicker
                      mask
                      preventClear
                      selected={
                        state.filters?.endDate.length &&
                        (state.filters?.endDate[0]?.value ||
                          state.filters?.endDate)
                      }
                      label="Email Sent end date"
                      classes={{
                        datepickerWrapper: classes.scheduleDateWrapper,
                        input: {
                          root: clsx("mr-4", classes.datepickerWrapper),
                        },
                      }}
                      maxDate={new Date()}
                      minDate={new Date(state.filters.startDate[0]?.value)}
                      onChange={(endDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            endDate: [
                              {
                                field: "date",
                                type: "lte",
                                value: getDateString(endDate),
                              },
                            ],
                          },
                        }));
                        setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                          endDateFilter: getDateString(endDate),
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="mr-2">
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  size="small"
                  value={state.pdxCompany}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={state.pdxCompanyList}
                  getOptionLabel={(option) => option.value || ""}
                  renderInput={(params) =>
                    state.isFetchingList.pdxCompany ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        label="PDX Company"
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      pdxCompany: value,
                      filters: {
                        ...prevState.filters,
                        pdxCompany: [
                          {
                            field: "recipent_pdx_company_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                      pdxCompanyFilter: {
                        value,
                        pdxCompany: [
                          {
                            field: "recipent_pdx_company_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    });

                    if (state.customer) {
                      setState((prevState) => ({
                        ...prevState,
                        customer: defaultState.customer,
                        filters: {
                          ...prevState.filters,
                          customer: [],
                        },
                      }));
                    }
                  }}
                />
              </div>
              <div className="mr-2">
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  size="small"
                  value={state.customer}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={
                    !state.pdxCompany.length
                      ? appData.customers
                      : getCustomerList
                  }
                  getOptionLabel={({ name }) => name}
                  renderInput={(params) =>
                    state.isFetchingList.customers ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        label="Customer"
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      customer: value,
                      filters: {
                        ...prevState.filters,
                        customer: [
                          {
                            field: "recipent_customer_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                      customerFilter: {
                        value,
                        customer: [
                          {
                            field: "recipent_customer_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    });
                  }}
                />
              </div>
              <div className={clsx("mr-2")}>
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={2}
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={appDataList.icList}
                  value={state.IC}
                  getOptionLabel={(item) =>
                    `${item.icid || ""} - ${item.last_name || ""} ${
                      item.first_name || ""
                    }`
                  }
                  renderInput={(params) =>
                    state.isFetchingList.users ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField {...params} label="IC" variant="outlined" />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      IC: value,
                      filters: {
                        ...prevState.filters,
                        IC: [
                          {
                            field: "recipent_ic_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                      ICFilter: {
                        value,
                        IC: [
                          {
                            field: "recipent_ic_ids",
                            type: "contains",
                            value: value.map((e) => `[${e.id}]`),
                          },
                        ],
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <Paper
            elevation={2}
            className={clsx("p-4 mt-10", classes.paperSpacing)}
          >
            <div
              className={clsx(
                "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                classes.actionsWrapper
              )}
            >
              <div></div>

              <div className="d-flex f-align-center">
                <Dropdown
                  placement="bottom-end"
                  options={STATUS.map(({ label, value }) => ({
                    value,
                    label,
                  }))}
                  labelEllipses
                  hasEllipses
                  label={`Email Type: ${
                    STATUS.find(
                      (status) =>
                        status.value === state.filters?.status[0]?.value
                    )?.label || "None"
                  }`}
                  onChange={(evt) => {
                    const { value } = evt;
                    setState((prevState) => ({
                      ...prevState,
                      pageNumber: defaultState.pageNumber,
                      filters: {
                        ...prevState.filters,
                        status:
                          value === null
                            ? []
                            : [{ field: "type", type: "=", value }],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.BULK_MAIL_HISTORY, {
                      statusUser: {
                        value,
                        status:
                          value === null
                            ? []
                            : [{ field: "type", type: "=", value }],
                      },
                    });
                  }}
                />
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      updatePageConfig("bulk-mail-history");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              hasSelection={false}
              isLoading={state.isLoading}
              totalRows={state.totalEntries}
              pageSize={state.pageSize}
              pageNumber={state.pageNumber}
              order={state.order}
              orderBy={state.orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
            />
          </Paper>
        </>
      </div>
      {state.showSendBulkMailPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.invoiceSentPaperSize,
          }}
          open={!!state.showSendBulkMailPopup}
          contentText={
            <div style={{ overflow: "hidden" }}>
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                Send Emails
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value={"noreply@pdxdelivers.com"}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  To
                </Typography>

                <FormControl
                  className="d-flex"
                  style={{
                    gap: "6px",
                    width: "100%",
                  }}
                >
                  {state.selectedCustomerEmails &&
                    state.selectedCustomerEmails.length !== 0 && (
                      <div className="mt-1 mb-2">
                        <FormLabel>Customer's Email(s)</FormLabel>
                        <div className={classes.textChip}>
                          <Autocomplete
                            disableClearable
                            fullWidth
                            size="small"
                            options={[]}
                            className="mt-3"
                            freeSolo
                            multiple
                            defaultValue={state.selectedCustomerEmails}
                            disabled
                            renderTags={(value, props) =>
                              value.map((e) => (
                                <Chip
                                  key={e}
                                  label={e}
                                  className={classes.emailChip}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                className={classes.noBorder}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...params}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                  {state.filteredIcEmails &&
                    state.filteredIcEmails.length !== 0 && (
                      <div className="mt-1 mb-2">
                        <FormLabel>Contractor's Email(s)</FormLabel>
                        <div className={classes.textChip}>
                          <Autocomplete
                            disableClearable
                            fullWidth
                            size="small"
                            options={[]}
                            className="mt-3"
                            freeSolo
                            multiple
                            defaultValue={state.filteredIcEmails}
                            disabled
                            renderTags={(value, props) =>
                              value.map((email) => (
                                <Chip
                                  key={email}
                                  label={email}
                                  className={classes.emailChip}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                className={classes.noBorder}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...params}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                </FormControl>
              </div>
              {state.ccEmailArray && state.ccEmailArray.length !== 0 && (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    Cc
                  </Typography>
                  <TextField
                    disabled
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="ccEmail"
                    value={state.ccEmail}
                    className="mt-3"
                    helperText=" "
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.ccEmailArray.map((email) => (
                            <Chip
                              key={email}
                              label={email}
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  disabled
                  className="mt-3"
                  helperText=" "
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  minRows={10}
                  maxRows={10}
                  multiline
                  disabled
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {state.attachmentFiles && state.attachmentFiles.length !== 0 && (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-3"
                    style={{ width: "8rem" }}
                  >
                    Attachments
                  </Typography>
                  <List style={{ padding: 0, width: "90%" }} className="mt-3">
                    {state.attachmentFiles.map((attachment, index) => (
                      <ListItem
                        key={index}
                        className="mb-2"
                        style={{
                          padding: 3,
                          backgroundColor: "#f5f5f5",
                          borderRadius: "4px",
                          border: "1px solid transparent",
                        }}
                      >
                        <Link
                          href={attachment?.file_url}
                          download={attachment?.fileName}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mr-2"
                        >
                          <Typography
                            style={{
                              color: "#15c",
                              fontWeight: 400,
                              fontSize: 14,
                              fontStretch: "expanded",
                              textDecoration: "underline",
                            }}
                          >
                            {attachment?.fileName}
                          </Typography>
                        </Link>

                        <Typography
                          style={{
                            color: "black",
                            fontSize: 14,
                          }}
                        >
                          {`(${getReadableFileSizeString(attachment?.size)})`}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
          }
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              showSendBulkMailPopup: false,
              ccEmail: defaultState.ccEmail,
              selectedCustomerEmails: defaultState.selectedCustomerEmails,
              filteredIcEmails: defaultState.filteredIcEmails,
              email: defaultState.email,
              emailSubject: defaultState.emailSubject,
              emailBody: defaultState.emailBody,
              errors: {
                email: " ",
                ccEmail: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          hasClose={false}
          showPositiveActionLabel={false}
          negativeActionLabel="Close"
        />
      )}
    </>
  );
};

export default ViewBulkEmailHistory;
