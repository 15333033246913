import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  dropdownPaper: {
    minWidth: 156,
  },
  dropdownBackground: {
    background: "cornflowerblue",
    borderRadius: 8,
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  dropdownPaper: {
    minWidth: 156,
  },
  customerDropdown: {
    width: 180,
  },
  autocompleteFilters: {
    minWidth: 208,
  },
  paperSize: {
    width: "400px",
  },
  invoiceSentPaperSize: {
    minWidth: 800,
    width: 800,
    "& .MuiDialogContent-root": {
      paddingTop: "0px",
    },
    minHeight: 120,
    maxHeight: 800,
    overflowY: "auto",
  },
  buttonStyle1: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "16px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    marginBottom: theme.spacing(1),
    maxHeight: 100, // Set a max height (adjust as needed)
    overflowY: "auto", // Enable vertical scrolling
  },
  inputRoot: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  emailChip: {
    marginRight: "4px",
    marginBottom: "4px",
    marginTop: "6px",
  },
  accordianPaperSpacing: {
    // marginTop: "2px",
    height: "calc(100vh - 16rem)",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "4px",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  textChip: {
    maxHeight: 200,
    width: "100%",
    border: "1px solid #bdbdbd",
    borderRadius: "6px",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  noBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
}));

export default useStyles;
