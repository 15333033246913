import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFileExtension, getReadableFileSizeString } from "utils";
import {
  MAX_ALLOWED_FILE_SIZE,
  MAX_FILE_UPLOAD_LIMIT,
  SUPPORTED_FILE_FORMATS,
} from "../../shared/constants";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";
import { IconButton, Paper, Tooltip } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { noop } from "shared";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const defaulState = {
  files: [],
};

const UploadFiles = ({
  isLoading = false,
  uploadedAttachment = noop,
  sentHistory = false,
  isBulkEmail = false,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaulState,
    files: isBulkEmail
      ? defaulState.files
      : [{ name: "Invoice.pdf", isDefault: true }],
  });

  const handleFileChange = (evt) => {
    const newFiles = Array.from(evt.target.files);
    const validFiles = [];

    setState((prevState) => {
      const existingFiles = prevState.files.map((file) =>
        file.name.toLowerCase()
      );

      if (prevState.files.length + newFiles.length > MAX_FILE_UPLOAD_LIMIT) {
        toast.error(
          `You can upload a maximum of ${MAX_FILE_UPLOAD_LIMIT} files.`
        );
        return prevState;
      }

      const totalExistingFileSize = prevState.files
        .filter((ele) => !ele.isDefault)
        .reduce((total, file) => total + file.size, 0);

      let totalNewFileSize = 0;

      newFiles.forEach((file) => {
        const { name, size } = file;
        const fileExtension = getFileExtension(name);

        if (existingFiles.includes(name.toLowerCase())) {
          toast.error(`File "${name}" has already been uploaded.`);
        } else if (size > MAX_ALLOWED_FILE_SIZE.ATTACH_FILES * 1048576) {
          toast.error(
            `Size of "${name}" is more than ${MAX_ALLOWED_FILE_SIZE.ATTACH_FILES} MB.`
          );
        } else if (
          !SUPPORTED_FILE_FORMATS.ATTACH_FILES.includes(
            fileExtension.toLowerCase()
          )
        ) {
          toast.error(
            `Only ${SUPPORTED_FILE_FORMATS.ATTACH_FILES.join(
              ", "
            )} formats are supported.`
          );
        } else {
          totalNewFileSize += size;
          validFiles.push(file);
        }
      });

      const totalFileSize = totalExistingFileSize + totalNewFileSize;
      if (totalFileSize > MAX_ALLOWED_FILE_SIZE.ATTACH_FILES * 1048576) {
        toast.error(
          `Total file size exceeds the ${MAX_ALLOWED_FILE_SIZE.ATTACH_FILES} MB limit.`
        );
        return prevState;
      }

      return {
        ...prevState,
        files: [...prevState.files, ...validFiles],
      };
    });
  };

  const handleRemoveFile = (fileName) => {
    setState((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file) => file.name !== fileName),
    }));
  };

  useEffect(() => {
    const filteredFiles = state.files.filter((file) => !file.isDefault);
    uploadedAttachment(filteredFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.files]);

  return (
    <div className="d-flex">
      <AttachFileIcon fontSize="small" className={classes.fileInputIcon} />
      <input
        id="file"
        type="file"
        className={classes.hiddenInput}
        accept={`.${SUPPORTED_FILE_FORMATS.ATTACH_FILES.join(",.")}`}
        multiple
        onChange={handleFileChange}
        disabled={isLoading}
        onClick={(evt) => (evt.currentTarget.value = "")}
      />
      <Typography
        variant="body2"
        component="label"
        color="primary"
        htmlFor="file"
        className={clsx([classes.attachLabel], {
          [classes.margin8]: !sentHistory,
        })}
      >
        Attach files
      </Typography>
      {sentHistory && (
        <Tooltip
          arrow
          title={
            <Typography style={{ fontSize: 14 }}>
              Only the latest invoice is attached. Please reattach any
              previously added documents.
            </Typography>
          }
          placement="top-start"
        >
          <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} />
        </Tooltip>
      )}
      <div className={classes.fileListContainer}>
        {state.files.map((file) => (
          <Paper key={file.name} elevation={1} className={classes.fileItem}>
            <Typography variant="body2" className={classes.fileName}>
              {file.isDefault ? (
                file.name
              ) : (
                <>
                  {file.name}
                  <span className={clsx("ml-2", classes.fileSize)}>
                    ({getReadableFileSizeString(file.size)})
                  </span>
                </>
              )}
            </Typography>
            {!file.isDefault && (
              <IconButton
                disabled={isLoading}
                size="small"
                onClick={() => handleRemoveFile(file.name)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Paper>
        ))}
      </div>
    </div>
  );
};

export default UploadFiles;
