/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import useStyles from "./style";
import { Datepicker, Dropdown, Grid } from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import {
  getDateString,
  getPageConfig,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateLayout,
  updatePageConfig,
  updatePagination,
} from "utils";
import { AppContext, PAGE_KEYS, noop } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import { SharedService } from "modules/shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import PaymentSummary from "./payment-summary";
import SearchIcon from "@material-ui/icons/Search";
import { modeOfPaymentList } from "modules/shared/constants";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const defaultState = {
  entries: [],
  scheduleDate: null,
  entry: {},
  pdxCompanyList: [],
  dateFilterType: "single",
  customerlocationList: [],
  customer: null,
  pdxCompany: null,
  selectedLocation: null,
  viewPaymentSummary: false,
  selectedPayment: null,
  search: "",
  filters: {
    customer: [],
    pdxCompany: [],
    selectedLocation: [],
    endDate: [],
    startDate: [],
    paymentModeFilter: [],
    modeOfPayment: [],
  },
  totalEntries: 0,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  selectedRows: [],
  isLoading: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    locations: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    invoice_number: {
      label: "Invoice No.",
      value: "invoice_number",
      isSelected: true,
    },
    invoice_date: {
      label: "Invoice Date",
      value: "invoice_date",
      isSelected: true,
    },
    status: {
      label: "Status",
      value: "status",
      isSelected: true,
    },
    reference_number: {
      label: "Reference Number",
      value: "reference_number",
      isSelected: true,
    },
    payment_number: {
      label: "Payment No.",
      value: "payment_number",
      isSelected: true,
    },
    date: {
      label: "Payment Date",
      value: "date",
      isSelected: true,
    },
    pdxCompany: { label: "PDX Company", value: "pdxCompany", isSelected: true },
    customer: { label: "Customer", value: "customer", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    amount: {
      label: "Amount",
      value: "amount",
      isSelected: true,
    },
    credit_amt: {
      label: "Amount",
      value: "credit_amt",
      isSelected: true,
    },
    mode: {
      label: "Mode of Payment",
      value: "mode",
      isSelected: true,
    },
    payment_notes: {
      label: "Payment Notes",
      value: "payment_notes",
      isSelected: true,
    },
  },
  dynamicMode: {
    all: { label: "All", value: "all", isSelected: true },
    bank_remittance: {
      label: "Bank Remittance",
      value: "bank-remittance",
      isSelected: true,
    },
    bank_transfer: {
      label: "Bank Transfer",
      value: "bank-transfer",
      isSelected: true,
    },
    cash: {
      label: "Cash",
      value: "cash",
      isSelected: true,
    },
    check: {
      label: "Check",
      value: "check",
      isSelected: true,
    },
    credit_card: {
      label: "Credit Card",
      value: "credit-card",
      isSelected: true,
    },
    debit_card: {
      label: "Debit Card",
      value: "debit-card",
      isSelected: true,
    },
  },
};

let timeout = null;

const ViewRecordPaymentHistory = ({ history, location }) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  // const data = location.state || {};
  const pageConfig = getPageConfig(PAGE_KEYS.RECORD_PAYMENT);
  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    dateFilterType:
      (pageConfig && pageConfig?.dateFilterType) || defaultState.dateFilterType,
    pdxCompany:
      (pageConfig && pageConfig?.pdxCompanyFilter?.value) ||
      defaultState.pdxCompany,
    customer:
      (pageConfig && pageConfig?.customerFilter?.value) ||
      defaultState.customer,
    selectedLocation:
      (pageConfig && pageConfig?.selectedLocationFilter?.value) ||
      defaultState.selectedLocation,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    filters: {
      ...defaultState.filters,
      pdxCompany:
        (pageConfig && pageConfig?.pdxCompanyFilter?.pdxCompany) || [],
      customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      selectedLocation:
        (pageConfig && pageConfig?.selectedLocationFilter?.selectedLocation) ||
        [],
      startDate:
        pageConfig && pageConfig?.startDateFilter
          ? [
              {
                field: "date",
                type: "=",
                value: pageConfig?.startDateFilter,
              },
            ]
          : [],
      endDate:
        (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    },
  });

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchCustomerLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: true,
      },
    }));
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          locations: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList: data?.rows || defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const getCustomerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany, appDataList]);

  const getLocationList = Object.entries(state.customerlocationList)
    .map(([stateName, locations]) => {
      const filteredLocations = !state.customer
        ? locations
        : locations.filter((location) =>
            state.customer?.customer_branches?.some(
              (branch) => branch.location === location
            )
          );
      return filteredLocations.map((location) => ({
        label: `${location}-(${stateName})`,
        value: location,
      }));
    })
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const getSearchArray = (search) => {
    const columns = ["invoice_number", "amount"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = []
    ) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const searchArray = getSearchArray(search);

      const sortObj = { field: orderBy, order };

      let filtersArr = [];

      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.startDate];
      }

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      if (filters.modeOfPayment.length && filters.modeOfPayment) {
        filtersArr = [...filtersArr, ...filters.modeOfPayment];
      }
      if (
        filters.paymentModeFilter.length &&
        filters.paymentModeFilter[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.paymentModeFilter];
      }

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );

      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][date][gte]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }

      const { data, error } = await Service.getInvoicesPayments(queryString);

      if (error) {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        return null;
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        totalEntries: data.count,
      }));
      return data;
    },
    [state.dateFilterType]
  );

  const handleFetch = () => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy
    ).then((data) => {
      handleUpdatePayment();
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  };

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewPaymentSummary,
  ]);

  useEffect(() => {
    fetchList("customers", noop);
    fetchPDXCompanyList();
    fetchCustomerLocation();
  }, [state.viewPaymentSummary]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handleItemClick = (invoice) => {
    setState((prevState) => ({
      ...prevState,
      selectedPayment: invoice,
    }));
  };

  const handleInvoiceFilter = (option) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        paymentModeFilter: [
          {
            field: "mode",
            type: "=",
            value: option.value,
          },
        ],
      },
    }));
  };

  const handleClose = (isClose) => {
    setState((prevState) => ({
      ...prevState,
      viewPaymentSummary: false,
    }));
  };

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        pageNumber: defaultState.pageNumber,
      }));
    }, 1000);
  }, []);

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.payment_number?.isSelected,
      id: "payment_number",
      label: "Payment No.",
      field: "payment_number",
      canSort: true,
      render: (row) => {
        const title = row.payment_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography
              variant="body2"
              onClick={() => {
                if (!state.viewPaymentSummary) {
                  setState((prevState) => ({
                    ...prevState,
                    viewPaymentSummary: true,
                    selectedPayment: row,
                  }));
                }
              }}
              style={{
                color: !state.viewPaymentSummary && "#775edc",
                textDecoration: !state.viewPaymentSummary && "underline",
                cursor: !state.viewPaymentSummary && "pointer",
                fontWeight: !state.viewPaymentSummary && "bold",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.date?.isSelected,
      id: "date",
      label: "Payment Date",
      field: "date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title =
          row.status && row.status === "reverted"
            ? "Cancelled"
            : row.status.charAt(0).toUpperCase() + row.status.slice(1);
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_number?.isSelected,
      id: "invoice_number",
      label: "Invoice No.",
      field: "invoice_number",
      canSort: true,
      render: (row) => {
        const title = row.invoice_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_date?.isSelected,
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.invoice.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.reference_number?.isSelected,
      id: "reference_number",
      label: "Reference Number",
      field: "reference_number",
      canSort: true,
      render: (row) => {
        const title = row.reference_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdxCompany",
      label: "PDX Company",
      field: "pdxCompany",
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.amount?.isSelected,
      id: "amount",
      label: "Amount",
      field: "amount",
      canSort: true,
      render: (row) => {
        const title = `$${row?.amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.credit_amt?.isSelected,
      id: "credit_amt",
      label: "Credit Amount",
      field: "credit_amt",
      canSort: true,
      render: (row) => {
        const amount = row?.credit_amt || "0.00";
        const isNegative = parseFloat(amount) < 0;
        const color = isNegative ? "red" : "green";
        return (
          <Tooltip title={`$ ${amount}` ?? ""} placement="top-start">
            <Typography variant="body2" style={{ color }} noWrap>
              {`$ ${amount}`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.mode?.isSelected,
      id: "mode",
      label: "Mode of Payment",
      field: "mode",
      canSort: true,
      render: (row) => {
        const title = modeOfPaymentList.find((ele) => ele.value === row?.mode);
        return (
          <Tooltip title={`${title.label || "-"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`${
              title.label || "-"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.payment_notes?.isSelected,
      id: "payment_notes",
      label: "Payment Notes",
      field: "payment_notes",
      render: (row) => {
        const tooltip = row?.payment_notes || "-";
        const title = row?.payment_notes
          ? row?.payment_notes.length > 10
            ? `${row.payment_notes.slice(0, 10)}...`
            : `${row?.payment_notes}`
          : "-";
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const modeKeys = Object.keys(defaultState.dynamicMode);

  const handleUpdatePayment = (row) => {
    if (row) {
      return setState((prevState) => {
        return {
          ...prevState,
          selectedPayment: row,
        };
      });
    }
    setState((prevState) => {
      const updatedRows = prevState.entries.find(
        (row) => row.id === state.selectedPayment?.id
      );
      return {
        ...prevState,
        selectedPayment: updatedRows,
      };
    });
  };

  return (
    <>
      <div className="mr-5">
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Payments
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/invoice")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Invoice
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            View All Payments History.
          </Typography>
        </div>
        {!state.viewPaymentSummary && (
          <>
            <div className="d-flex f-justify-between f-align-center">
              <div className="d-flex f-wrap">
                <div
                  className={clsx({
                    "d-flex f-align-center mt-1": !appData.isTabletView,
                  })}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      value={state.dateFilterType}
                      onChange={(evt) => {
                        const { value } = evt.target;
                        setState((prevState) => ({
                          ...prevState,
                          dateFilterType: value,
                          selectedRows: [],
                          filters: {
                            ...prevState.filters,
                            startDate: [
                              {
                                field: "date",
                                type: value === "single" ? "=" : "gte",
                                value:
                                  (pageConfig && pageConfig?.startDateFilter) ||
                                  getDateString(new Date()),
                              },
                            ],
                            endDate:
                              value === "single"
                                ? []
                                : [
                                    {
                                      field: "date",
                                      type: "lte",
                                      value:
                                        (pageConfig &&
                                          pageConfig?.endDateFilter) ||
                                        getDateString(new Date()),
                                    },
                                  ],
                          },
                          pageNumber: defaultState.pageNumber,
                        }));
                        setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                          dateFilterType: value,
                          endDateFilter:
                            (pageConfig && pageConfig?.startDateFilter) ||
                            getDateString(new Date()),
                          startDateFilter:
                            (pageConfig && pageConfig?.startDateFilter) ||
                            getDateString(new Date()),
                        });
                      }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio size="small" color="primary" />}
                        label="Specific date"
                      />
                      <FormControlLabel
                        value="multiple"
                        control={<Radio size="small" color="primary" />}
                        label="Date range"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="d-flex mt-3">
                    <Datepicker
                      mask
                      preventClear
                      isClearable={state.dateFilterType === "single"}
                      selected={
                        state.filters.startDate.length &&
                        state.filters.startDate[0]?.value?.length
                          ? state.filters.startDate[0]?.value
                          : null
                      }
                      label={
                        state.dateFilterType === "single"
                          ? "Payment date"
                          : "Payment start date"
                      }
                      classes={{
                        datepickerWrapper: classes.scheduleDateWrapper,
                        input: {
                          root: clsx("mr-4", classes.datepickerWrapper),
                        },
                      }}
                      maxDate={
                        state.filters.endDate[0]?.value
                          ? new Date(state.filters.endDate[0]?.value)
                          : pageConfig &&
                            pageConfig?.dateFilterType === "multiple" &&
                            pageConfig?.endDateFilter
                          ? new Date(pageConfig.endDateFilter)
                          : new Date()
                      }
                      onChange={(startDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          selectedRows: [],
                          filters: {
                            ...prevState.filters,
                            startDate: [
                              {
                                field: "date",
                                type:
                                  state.dateFilterType === "single"
                                    ? "="
                                    : "gte",
                                value: getDateString(startDate),
                              },
                            ],
                          },
                        }));
                        setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                          startDateFilter: getDateString(startDate),
                          endDateFilter:
                            state.dateFilterType === "single"
                              ? getDateString(startDate)
                              : (pageConfig && pageConfig?.endDateFilter) ||
                                getDateString(new Date()),
                        });
                      }}
                    />
                    {state.dateFilterType === "multiple" && (
                      <Datepicker
                        mask
                        preventClear
                        selected={
                          state.filters?.endDate.length &&
                          (state.filters?.endDate[0]?.value ||
                            state.filters?.endDate)
                        }
                        label="Payment end date"
                        classes={{
                          datepickerWrapper: classes.scheduleDateWrapper,
                          input: {
                            root: clsx("mr-4", classes.datepickerWrapper),
                          },
                        }}
                        maxDate={new Date()}
                        minDate={new Date(state.filters.startDate[0]?.value)}
                        onChange={(endDate) => {
                          setState((prevState) => ({
                            ...prevState,
                            selectedRows: [],
                            filters: {
                              ...prevState.filters,
                              endDate: [
                                {
                                  field: "date",
                                  type: "lte",
                                  value: getDateString(endDate),
                                },
                              ],
                            },
                          }));
                          setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                            endDateFilter: getDateString(endDate),
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.pdxCompany}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={state.pdxCompanyList}
                    getOptionLabel={(option) => option.value || ""}
                    renderInput={(params) =>
                      state.isFetchingList.pdxCompany ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="PDX Company"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        pdxCompany: value,
                        filters: {
                          ...prevState.filters,
                          pdxCompany: [
                            {
                              field: "pdx_company_name",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                        pdxCompanyFilter: {
                          value,
                          pdxCompany: [
                            {
                              field: "pdx_company_name",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      });
                      if (state.customer || state.selectedLocation) {
                        setState((prevState) => ({
                          ...prevState,
                          customer: null,
                          selectedLocation: null,
                          filters: {
                            ...prevState.filters,
                            customer: [],
                            selectedLocation: [],
                          },
                        }));
                        setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                          customerFilter: null,
                          selectedLocationFilter: null,
                          routeFilter: null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.customer}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={
                      !state.pdxCompany ? appData.customers : getCustomerList
                    }
                    getOptionLabel={({ name }) => name}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Customer"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        customer: value,
                        filters: {
                          ...prevState.filters,
                          customer: [
                            {
                              field: "customer_name",
                              type: "=",
                              value: value?.name,
                            },
                          ],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                        customerFilter: {
                          value,
                          customer: [
                            {
                              field: "customer_name",
                              type: "=",
                              value: value?.name,
                            },
                          ],
                        },
                      });
                      if (state.selectedLocation) {
                        setState((prevState) => ({
                          ...prevState,
                          selectedLocation: null,
                          route: null,
                          filters: {
                            ...prevState.filters,
                            selectedLocation: [],
                          },
                        }));
                        setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                          selectedLocationFilter: null,
                        });
                      }
                    }}
                  />
                </div>
                <div className={clsx("mr-2")}>
                  <Autocomplete
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    size="small"
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    value={state.selectedLocation}
                    options={getLocationList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) =>
                      state.isFetchingList.locations ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                        />
                      )
                    }
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        selectedLocation: value,
                        filters: {
                          ...prevState.filters,
                          selectedLocation: [
                            {
                              field: "location",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                        selectedLocationFilter: {
                          value,
                          selectedLocation: [
                            {
                              field: "location",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="d-flex f-align-center">
                <Typography variant="body1">Enter to search by:</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  className="ml-2"
                  size="small"
                  defaultValue={state.search}
                  // defaultValue={!data.icid ? undefined : state.search}
                  placeholder="Invoice No., Amount"
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={(evt) => {
                    handleSearch((evt.target.value || "").trim());
                    setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                      search: evt.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div></div>
                <div className="d-flex f-align-center">
                  <Dropdown
                    classes={{
                      paper: classes.dropdownPaper,
                      wrapper:
                        !!state.filters.modeOfPayment.length &&
                        classes.dropdownBackground,
                    }}
                    disablePortal={false}
                    isMultiSelect
                    remainOpen
                    placement="bottom-end"
                    options={modeKeys.map((key) => state.dynamicMode[key])}
                    labelEllipses
                    hasEllipses
                    label={"Mode of Payment:"}
                    onChange={(selectedOptions) => {
                      const isSelectAll = selectedOptions.some(
                        (item) => item.value === "all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicMode?.all?.isSelected;
                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;
                      const isAllOptionSelected =
                        modeKeys.length - 1 ===
                        selectedOptions.filter((item) => item.value !== "all")
                          .length;

                      const updatedDynamicStatus = modeKeys.reduce(
                        (acc, key) => {
                          const isSelected = isClickedOnSelectAll
                            ? isSelectAll
                            : key === "all"
                            ? isAllOptionSelected
                            : selectedOptions.some(
                                (item) =>
                                  item.label === state.dynamicMode[key].label
                              );

                          return {
                            ...acc,
                            [key]: { ...state.dynamicMode[key], isSelected },
                          };
                        },
                        {}
                      );

                      const tempFilters = [];

                      if (!updatedDynamicStatus["all"].isSelected) {
                        modeKeys.forEach((key) => {
                          if (key !== "all") {
                            if (updatedDynamicStatus[key].isSelected) {
                              tempFilters.push({
                                field: "mode",
                                type: "=",
                                value: key.replace(/_/g, "-"),
                              });
                            }
                          }
                        });
                      }

                      setState((prevState) => ({
                        ...prevState,
                        dynamicMode: {
                          ...prevState.dynamicMode,
                          ...updatedDynamicStatus,
                        },
                        filters: {
                          ...prevState.filters,
                          modeOfPayment: tempFilters,
                        },
                        pageNumber: defaultState.pageNumber,
                      }));
                    }}
                  />
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(state.dynamicColumns).map(
                      (key) => state.dynamicColumns[key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      const isSelectAll = options.some(
                        (item) => item.value === "select_all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicColumns?.select_all?.isSelected;

                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;

                      const isAllOptionSelected =
                        Object.keys(state.dynamicColumns).length - 1 ===
                        options.filter((item) => item.value !== "select_all")
                          .length;
                      const updatedDynamicColumns = Object.keys(
                        state.dynamicColumns
                      ).reduce((acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "select_all"
                          ? isAllOptionSelected
                          : !!options.some((item) => item.value === key);

                        return {
                          ...acc,
                          [key]: {
                            ...state.dynamicColumns[key],
                            isSelected,
                          },
                        };
                      }, {});
                      setPageConfig(PAGE_KEYS.RECORD_PAYMENT, {
                        dynamicColumns: updatedDynamicColumns,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        dynamicColumns: {
                          ...prevState.dynamicColumns,
                          ...updatedDynamicColumns,
                        },
                      }));
                    }}
                  />
                  <Tooltip placement="top-end" title="Reset All Filters">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        updatePageConfig("record-payment");
                        window.location.reload();
                      }}
                    >
                      <RotateLeftIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries}
                actionBarConfig={null}
                hasSelection={false}
                isLoading={state.isLoading}
                totalRows={state.totalEntries}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
              />
            </Paper>
          </>
        )}
        {state.viewPaymentSummary && (
          <PaymentSummary
            rows={state.entries}
            totalEntries={state.totalEntries}
            selectedPayment={state.selectedPayment}
            isLoading={state.isLoading}
            pageSize={state.pageSize}
            pageNumber={state.pageNumber}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            handleItemClick={handleItemClick}
            handleClose={handleClose}
            handleInvoiceFilter={handleInvoiceFilter}
            invoiceLoading={state.invoiceLoading}
            modeOfPaymentFilters={state.filters.modeOfPayment}
            customerList={appDataList.customers}
            handleFetch={handleFetch}
            handleUpdatePayment={handleUpdatePayment}
          />
        )}
      </div>
    </>
  );
};

export default ViewRecordPaymentHistory;
