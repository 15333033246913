/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useCallback, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { noop } from "shared";
import clsx from "clsx";
import Grid from "../grid";
import GridLoader from "../loader";
import useStyles from "../style";
import NoRecords from "assets/images/norecord.svg";

let gridHelpers = {};

const defaultState = {
  openStates: {
    kam: {},
    customer: {},
    location: {},
  },
  selectedRows: {},
};

let collapsedRows = [];

const BRCollapsibleGrid = ({
  rows = [],
  columns = [],
  entries = {},
  isLoading = false,
  gridActions = [],
  isTableLoading = {},
  hasSelection = true,
  gridClasses = {},
  rowEvents = [],
  onReady = noop,
  clearAllEntries = noop,
  onSelectionChange = noop,
  expandLocation = noop,
  gridRefs = null,
  wrapRef2,
  wrapRef1,
  tableHeadRef,
  handleScroll = noop,
  syncChildGridsScroll = noop,
  handleRowOpen = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const toggleCollapse = useCallback(
    (id, type, locationName, customerName, kamId) => {
      setState((prevState) => {
        const stateKey =
          type === "customer" || type === "location" ? `${kamId}-${id}` : id;
        const isOpen = !prevState.openStates[type][stateKey];

        let updatedOpenStates = { ...prevState.openStates };

        if (isOpen && locationName && type === "location") {
          expandLocation(id, locationName, customerName, kamId);

          if (wrapRef1.current) {
            wrapRef1.current.scrollLeft = 0;
          }
          if (wrapRef2.current) {
            wrapRef2.current.scrollLeft = 0;
          }
          syncChildGridsScroll(0);
        }

        if (type === "customer" && !isOpen) {
          const customerLocations = rows
            .find((kam) => kam.customers.find((c) => c.customer.id === id))
            ?.customers.find((c) => c.customer.id === id)?.customer_locations;

          customerLocations?.forEach((location) => {
            const locationKey = `${kamId}-${location.id}`;
            updatedOpenStates.location[locationKey] = false;
            collapsedRows = collapsedRows.filter(
              (item) => item !== `location - ${location.id}`
            );
          });
        }

        if (type === "kam" && !isOpen) {
          const kamData = rows.find((k) => k.kam.id === id);

          kamData?.customers.forEach((customer) => {
            const customerKey = `${id}-${customer.customer.id}`;
            updatedOpenStates.customer[customerKey] = false;
            customer.customer_locations.forEach((location) => {
              const locationKey = `${id}-${location.id}`;
              updatedOpenStates.location[locationKey] = false;
              collapsedRows = collapsedRows.filter(
                (item) => item !== `location - ${location.id}`
              );
            });
          });
        }

        collapsedRows = prevState.openStates[type][stateKey]
          ? collapsedRows.filter((item) => item !== `${type} - ${id}`)
          : type === "location"
          ? [...collapsedRows, `location - ${id}`]
          : collapsedRows;

        handleRowOpen(collapsedRows);

        return {
          ...prevState,
          openStates: {
            ...updatedOpenStates,
            [type]: {
              ...updatedOpenStates[type],
              [stateKey]: isOpen,
            },
          },
        };
      });
    },
    [expandLocation, rows]
  );

  const handleSelectionChange = useCallback(
    (evt, locationId, kamId) => {
      setState((prevState) => {
        const updatedSelectedRows = {
          ...prevState.selectedRows,
          [`${kamId}-${locationId}`]: evt,
        };
        onSelectionChange(Object.values(updatedSelectedRows).flat());
        return {
          ...prevState,
          selectedRows: updatedSelectedRows,
        };
      });
    },
    [onSelectionChange]
  );

  useEffect(() => {
    onReady({
      resetSelection: () => {
        Object.keys(gridHelpers).forEach((key) => {
          gridHelpers[key] && gridHelpers[key].resetSelection();
        });
        setState((prevState) => ({
          ...prevState,
          selectedRows: {},
        }));
      },
      setSelection: (selectedRows) =>
        setState((prevState) => ({
          ...prevState,
          selectedRows,
        })),
      getSelection: () => state.selectedRows,
      toggleAll: (open = false) => {
        Object.keys(gridHelpers).forEach((key) => {
          if (gridHelpers[key]) {
            gridHelpers[key].toggle(open);

            if (!open) {
              clearAllEntries();
              setState((prevState) => ({
                ...prevState,
                openStates: {
                  kam: {},
                  customer: {},
                  location: {},
                },
              }));
            }
          }
        });
      },
    });
  }, [onReady, state.selectedRows, clearAllEntries]);

  if (rows?.length === 0 && !isLoading) {
    return (
      <div className="d-flex f-justify-center">
        <img alt="No record" className={classes.noRecordsImg} src={NoRecords} />
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        [...new Array(5).fill("Loading")].map((_, index) => (
          <GridLoader key={index} columns={columns.length} />
        ))
      ) : (
        <>
          {rows.map(({ kam, customers }) => (
            <Fragment key={kam.id}>
              <KAMRow
                kam={kam}
                classes={classes}
                toggleCollapse={toggleCollapse}
                openStates={state.openStates.kam}
              />
              {state.openStates.kam[kam.id] &&
                customers.map(({ customer, customer_locations }) => (
                  <Fragment key={customer.id}>
                    <CustomerRow
                      customer={customer}
                      kamId={kam.id}
                      classes={classes}
                      toggleCollapse={toggleCollapse}
                      openStates={state.openStates.customer}
                    />
                    <div ref={wrapRef2} onScroll={handleScroll}>
                      {state.openStates.customer[`${kam.id}-${customer.id}`] &&
                        customer_locations.map((location) => (
                          <Fragment key={location.id}>
                            <LocationRow
                              classes={classes}
                              location={location}
                              entries={entries}
                              columns={columns}
                              isTableLoading={isTableLoading}
                              hasSelection={hasSelection}
                              gridClasses={gridClasses}
                              rowEvents={rowEvents}
                              customer={customer}
                              onReady={(gridHelper) =>
                                (gridHelpers[customer.customer_name] =
                                  gridHelper)
                              }
                              selectedRows={state.selectedRows}
                              onSelectionChange={handleSelectionChange}
                              openStates={state.openStates.location}
                              toggleCollapse={toggleCollapse}
                              gridRefs={gridRefs}
                              handleScroll={handleScroll}
                              tableHeadRef={tableHeadRef}
                              kam={kam}
                            />
                          </Fragment>
                        ))}
                    </div>
                  </Fragment>
                ))}
            </Fragment>
          ))}
          {!!rows.length && (
            <div
              className={clsx("d-flex f-align-center", gridClasses.gridActions)}
            >
              {gridActions.map((action, index) => (
                <Fragment key={index}>{action}</Fragment>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

const KAMRow = ({ kam, classes, toggleCollapse, openStates }) => (
  <TableRow
    className={classes.tablePadding}
    hover
    onClick={() => toggleCollapse(kam.id, "kam")}
  >
    <TableCell className="p-2">
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          toggleCollapse(kam.id, "kam");
        }}
      >
        {openStates[kam.id] ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </IconButton>
    </TableCell>
    <TableCell colSpan={2} className={classes.fullWidthCell}>
      <Typography variant="body2" className={classes.kamName}>
        {kam.name}
      </Typography>
    </TableCell>
  </TableRow>
);

const CustomerRow = ({
  customer,
  kamId,
  classes,
  toggleCollapse,
  openStates,
}) => (
  <TableRow
    hover
    className={classes.tablePadding}
    style={{ display: "flow" }}
    onClick={() => {
      toggleCollapse(
        customer.id,
        "customer",
        null,
        customer.customer_name,
        kamId
      );
    }}
  >
    <TableCell></TableCell>
    <TableCell className="p-2">
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          toggleCollapse(
            customer.id,
            "customer",
            null,
            customer.customer_name,
            kamId
          );
        }}
      >
        {openStates[`${kamId}-${customer.id}`] ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </IconButton>
    </TableCell>
    <TableCell className={classes.fullWidthCell}>
      <Typography variant="body2" className={classes.customerName}>
        {customer.customer_name}
      </Typography>
    </TableCell>
  </TableRow>
);

const LocationRow = ({
  classes,
  location,
  toggleCollapse,
  entries,
  columns,
  isTableLoading,
  hasSelection,
  gridClasses,
  rowEvents,
  customer,
  onReady,
  selectedRows,
  onSelectionChange,
  gridRefs,
  handleScroll,
  tableHeadRef,
  openStates,
  kam,
}) => (
  <>
    <TableRow
      hover
      className={classes.tablePadding}
      onClick={() =>
        toggleCollapse(
          location.id,
          "location",
          location.location,
          customer.customer_name,
          kam.id
        )
      }
    >
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell className="p-2">
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            toggleCollapse(
              location.id,
              "location",
              location.location,
              customer.customer_name,
              kam.id
            );
          }}
        >
          {openStates[`${kam.id}-${location.id}`] ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </TableCell>
      <TableCell className={classes.fullWidthCell}>
        <div className="d-flex f-align-center f-justify-between">
          <Typography variant="body2">{location.location}</Typography>
          {!!entries[`${kam.id}-${location.id}`] &&
            entries[`${kam.id}-${location.id}`].length > 0 && (
              <Typography
                variant="body1"
                className="mr-6 text-bold"
                color="black"
              >
                {`${entries[`${kam.id}-${location.id}`].length} Record(s)`}
              </Typography>
            )}
        </div>
      </TableCell>
    </TableRow>
    {openStates[`${kam.id}-${location.id}`] && (
      <TableRow>
        <TableCell colSpan={4} className={classes.tableCellShadow}>
          <Collapse
            in={openStates[`${kam.id}-${location.id}`]}
            timeout="auto"
            unmountOnExit
          >
            {isTableLoading[`${kam.id}-${location.id}`] ? (
              <GridLoader
                open={openStates[`${kam.id}-${location.id}`]}
                columns={columns.length}
                pageSize={entries[`${kam.id}-${location.id}`]?.length || 0}
              />
            ) : (
              entries[`${kam.id}-${location.id}`]?.length > 0 && (
                <div className={classes.tableContainer}>
                  <Grid
                    rows={entries[`${kam.id}-${location.id}`]}
                    columns={columns.filter(
                      (column) => column.id !== location.id
                    )}
                    open={openStates[`${kam.id}-${location.id}`]}
                    hasSelection={hasSelection}
                    classes={gridClasses}
                    rowEvents={rowEvents}
                    onReady={onReady}
                    onSelectionChange={(evt) =>
                      onSelectionChange(evt, location.id, kam.id)
                    }
                    location={location}
                    customer={customer}
                    kam={kam}
                    gridRefs={gridRefs}
                    handleScroll={handleScroll}
                    tableHeadRef={tableHeadRef}
                    selectedRows={
                      selectedRows[`${kam.id}-${location.id}`] || []
                    }
                  />
                </div>
              )
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    )}
  </>
);

export default BRCollapsibleGrid;
