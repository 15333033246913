import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 800,
    height: 600,
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  existingButton: {
    //marginTop: -14,
    marginLeft: 2,
    marginBottom: 12,
  },
  datepickerWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  scheduleDateWrapper: {
    "& > div:nth-child(-n+2)": {
      width: "100%",
    },
  },
  infoIcon: {
    cursor: "default",
    color: "#004700",
  },
  weekInputProps: { pointerEvents: "auto" },
}));

export default useStyles;
