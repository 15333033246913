import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import format from "date-fns/format";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { debounce, getDateString } from "utils";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import useStyles from "./style";
import Datepicker from "../datepicker";
import DatePicker from "react-datepicker";
import DateRangeIcon from "@material-ui/icons/DateRange";

const defaultRow = {
  keyId: Date.now(),
  label: "",
  type: "",
  value: "",
  isFilterApplied: false,
};

const STRING_TYPE_LIST = {
  Equals: "iLike",
  Contains: "iRegexp",
  "Does not equal": "notILike",
  "Does not contain": "notIRegexp",
};

const ILIKE_TYPE_LIST = {
  Equals: "iLike",
  "Does not equal": "notILike",
};

const ARRAY_TYPE_LIST = {
  Equals: "contains",
  "Does Not Equal": "notContains",
};

const REGEXP_TYPE_LIST = {
  Equals: "iLike",
  Contains: "iRegexp",
  "Does not equal": "notILike",
  "Does not contain": "notIRegexp",
};

const CONTAIN_TYPE_LIST = {
  Contains: "iRegexp",
  "Does not contain": "notIRegexp",
};

const NUM_TYPE_LIST = {
  Equals: "eq",
  Contains: "iRegexp",
  "Does not equal": "neq",
  "Does not contain": "notIRegexp",
};

const EQ_TYPE_LIST = {
  Equals: "eq",
  "Does not equal": "neq",
};

const RANGE_TYPE_LIST = {
  Equals: "eq",
  Range: "ra",
};

const defaultState = {
  filterRows: [{ ...defaultRow }],
  columnsList: [],
  selectedValue: false,
  prevSelectedValue: null,
};

const Filter = ({
  isFiltering = false,
  filterConfig = [],
  appliedFilters = [],
  disabled = false,
  onClose = noop,
  onFilterApply = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const handleChange = (rowIndex, type = "", value = "") => {
    const row = { ...state.filterRows[rowIndex] };
    row[type] = value;
    if (type === "label") {
      row.type = "";
      row.value = "";
    }
    if (type === "type") {
      row.value = "";
    }

    setState((prevState) => {
      const filterRows = prevState.filterRows.slice();
      filterRows.splice(rowIndex, 1, row);
      return {
        ...prevState,
        filterRows,
      };
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setState((prevState) => {
      const { filterRows } = prevState;
      filterRows.splice(rowIndex, 1);
      const updatedRows = prevState.filterRows;
      return {
        ...prevState,
        filterRows: updatedRows,
      };
    });
  };

  const handleCancel = () => {
    setState((prevState) => {
      let updatedRows = prevState.filterRows?.filter(
        (row) => row.isFilterApplied
      );
      updatedRows = updatedRows.length ? updatedRows : defaultState.filterRows;
      return {
        ...prevState,
        filterRows: updatedRows,
      };
    });
    onClose();
  };

  const handleAddClick = () => {
    const { filterRows } = state;
    filterRows.push({
      ...defaultRow,
      keyId: Date.now(),
    });
    setState((prevState) => {
      const updatedRows = prevState.filterRows;
      return {
        ...prevState,
        filterRows: updatedRows,
      };
    });
  };

  const validateRows = () => {
    const { filterRows } = state;
    const isRowEmpty = filterRows.find(
      (row) => !row.label || !row.type || !row.value
    );
    return !!isRowEmpty;
  };
  const handleApplyFilter = (filters = [], callback = noop) => {
    const filterArr = [];
    filters.map((item) => {
      const columnField = state.columnsList.find(
        (row) => row.columnName === item.label
      );

      const columns =
        columnField?.columnType === "string"
          ? STRING_TYPE_LIST
          : columnField?.columnType === "array"
          ? ARRAY_TYPE_LIST
          : columnField?.columnType === "regexp"
          ? REGEXP_TYPE_LIST
          : columnField?.columnType === "iLike"
          ? ILIKE_TYPE_LIST
          : columnField?.columnType === "range"
          ? RANGE_TYPE_LIST
          : columnField?.columnType === "eq"
          ? EQ_TYPE_LIST
          : columnField?.columnType === "contain"
          ? CONTAIN_TYPE_LIST
          : NUM_TYPE_LIST;

      const option = columnField?.options?.find(
        (opt) => opt.label === item.value
      );

      const value = option
        ? option.value
        : columnField.isDateField
        ? item.type === "Range"
          ? `${format(new Date(item.value?.[0]), "MM/dd/yyyy")} - ${format(
              new Date(item.value?.[1]),
              "MM/dd/yyyy"
            )}`
          : format(new Date(item.value), "MM/dd/yyyy")
        : // columnField
        item.label === "Phone"
        ? item.value.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3")
        : item.value;
      const type =
        columnField.isDateField &&
        !columnField.hasTimeStamp &&
        columns[item.type] === "iLike"
          ? "="
          : columnField.isDateField &&
            !columnField.hasTimeStamp &&
            columns[item.type] === "notILike"
          ? "neq"
          : columnField.hasTimeStamp && columns[item.type] === "iLike"
          ? "iRegexp"
          : columnField.hasTimeStamp && columns[item.type] === "notILike"
          ? "notIRegexp"
          : columns[item.type];

      filterArr.push({
        field: columnField.columnValue,
        type,
        value,
      });
    });
    onFilterApply(filterArr);
    callback();
  };

  useEffect(() => {
    if (!isFiltering || !filterConfig.length) return;
    const updatedFilterConfig = filterConfig.map((item) => {
      return {
        columnName: item.fieldToDisplay,
        columnType: item.operatorType,
        columnValue: item.field,
        subField: item.subField,
        options: item.options,
        isDateField: item.isDateField,
        hasTimeStamp: item.hasTimeStamp,
      };
    });
    setState((prevState) => ({
      ...prevState,
      columnsList: updatedFilterConfig,
    }));
    if (appliedFilters.length) {
      const updatedRows = appliedFilters.map((item) => {
        const columnField = updatedFilterConfig.find(
          (row) => row.columnValue === item.field
        );
        const columns =
          columnField?.columnType === "string"
            ? STRING_TYPE_LIST
            : columnField?.columnType === "array"
            ? ARRAY_TYPE_LIST
            : columnField?.columnType === "regexp"
            ? REGEXP_TYPE_LIST
            : columnField?.columnType === "iLike"
            ? ILIKE_TYPE_LIST
            : columnField?.columnType === "range"
            ? RANGE_TYPE_LIST
            : columnField?.columnType === "eq"
            ? EQ_TYPE_LIST
            : columnField?.columnType === "contain"
            ? CONTAIN_TYPE_LIST
            : NUM_TYPE_LIST;
        let type = "";
        if (
          (columnField?.isDateField && item.type === "eq") ||
          (columnField?.isDateField && item.type === "=") ||
          (columnField?.hasTimeStamp && item.type === "iRegexp")
        )
          type = "Equals";
        else if (columnField?.isDateField && item.type === "ra") type = "Range";
        else if (
          (columnField?.isDateField && item.type === "neq") ||
          (columnField?.hasTimeStamp && item.type === "notIRegexp")
        )
          type = "Does not equal";
        else
          type = Object.keys(columns).find((key) => columns[key] === item.type);

        const label = columnField?.columnName;

        // const value = columnField?.isDateField
        //   ? new Date(item.value)
        //   : item.value;

        let value = item.value;
        if (
          (item.field === "submitted_at" ||
            item.field === "updated_at" ||
            item.field === "last_updated_at") &&
          item.type === "ra"
        ) {
          const [startDate, endDate] = value.split(" - ");
          value = [startDate, endDate];
        }
        return {
          keyId: Date.now(),
          label,
          type,
          value,
          isFilterApplied: true,
        };
      });
      setState((prevState) => ({
        ...prevState,
        filterRows: updatedRows,
      }));
    }
  }, [isFiltering]);

  const isDisabled = validateRows() || disabled;
  return (
    <>
      {isFiltering && (
        <Drawer open={isFiltering} anchor="right" onClose={handleCancel}>
          <Box className={classes.drawerPaper}>
            <Box>
              <Box className={classes.filterHeading}>
                <Typography color="primary" variant="h6">
                  Filter
                </Typography>
              </Box>
              <Box className={classes.filterWrapper}>
                <Box className={classes.clearbutton}>
                  <Button
                    disabled={state.filterRows.length === 1 && isDisabled}
                    color="primary"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        filterRows: [defaultRow],
                      }));
                      handleApplyFilter([]);
                    }}
                  >
                    Clear All
                  </Button>
                </Box>
                {state.filterRows.map((row, index) => {
                  let columnField = state.columnsList.find(
                    (item) => item.columnName === row.label
                  );
                  return (
                    <Box className={classes.filterContainer} key={index}>
                      {!!state.columnsList.length && (
                        <FormControl
                          variant="outlined"
                          className={classes.input}
                        >
                          <InputLabel id="filter-label">Select</InputLabel>
                          <Select
                            label="Select"
                            labelId="filter-label"
                            id="filter"
                            variant="outlined"
                            size="small"
                            value={row.label}
                            onChange={(selectedOption) => {
                              handleChange(
                                index,
                                "label",
                                selectedOption.target.value
                              );
                            }}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            {state.columnsList.map((item) => (
                              <MenuItem
                                key={item.columnName}
                                value={item.columnName}
                              >
                                {item.columnName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      <FormControl variant="outlined" className={classes.input}>
                        <InputLabel id="filter-label2">Select</InputLabel>
                        <Select
                          label="Select"
                          labelId="filter-label2"
                          variant="outlined"
                          size="small"
                          disabled={!row.label}
                          value={row.type}
                          IconComponent={KeyboardArrowDownIcon}
                          onChange={(event) => {
                            handleChange(index, "type", event.target.value);
                          }}
                        >
                          {Object.keys(
                            columnField?.columnType === "string"
                              ? STRING_TYPE_LIST
                              : columnField?.columnType === "array"
                              ? ARRAY_TYPE_LIST
                              : columnField?.columnType === "regexp"
                              ? REGEXP_TYPE_LIST
                              : columnField?.columnType === "iLike"
                              ? ILIKE_TYPE_LIST
                              : columnField?.columnType === "range"
                              ? RANGE_TYPE_LIST
                              : columnField?.columnType === "eq"
                              ? EQ_TYPE_LIST
                              : columnField?.columnType === "contain"
                              ? CONTAIN_TYPE_LIST
                              : NUM_TYPE_LIST
                          ).map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {!!columnField?.options?.length && (
                        // <FormControl
                        //   variant="outlined"
                        //   className={clsx("w-100", classes.input)}
                        // >
                        //   <InputLabel id="filter-label3">Select</InputLabel>
                        //   <Select
                        //     label="Select"
                        //     labelId="filter-label3"
                        //     variant="outlined"
                        //     size="small"
                        //     disabled={!row.type || !row.label}
                        //     select={true}
                        //     value={row.value}
                        //     onChange={(event) => {
                        //       handleChange(index, "value", event.target.value);
                        //     }}
                        //     IconComponent={KeyboardArrowDownIcon}
                        //   >
                        //     {columnField.options.map((option) => (
                        //       <MenuItem key={option.value} value={option.value}>
                        //         {option.label}
                        //       </MenuItem>
                        //     ))}
                        //   </Select>
                        // </FormControl>
                        <Autocomplete
                          disableClearable
                          className={clsx("w-100", classes.input)}
                          value={row.value}
                          freeSolo={
                            row.type === "Does not contain" ||
                            row.type === "Contains"
                              ? true
                              : false
                          }
                          options={
                            row.type === "Does not contain" ||
                            row.type === "Contains"
                              ? columnField.options.filter((option) =>
                                  option.label
                                    ?.toLowerCase()
                                    .includes(
                                      columnField.searchedValues
                                        ?.find((obj) => obj.key === row.keyId)
                                        ?.value?.toLowerCase() || ""
                                    )
                                )
                              : columnField.options
                          }
                          filterOptions={(options, { inputValue }) =>
                            options?.filter((option) =>
                              option?.label
                                ?.toLowerCase()
                                .includes(inputValue?.toLowerCase())
                            )
                          }
                          getOptionLabel={(option) => option.label || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select"
                              variant="outlined"
                              onChange={(evt, value) => {
                                // if (
                                //   row.type !== "Does not contain" &&
                                //   row.type !== "Contains"
                                // ) {
                                //   setState((prevState) => ({
                                //     ...prevState,
                                //     selectedValue:
                                //       evt.target.value != row?.value?.label &&
                                //       evt.target.value !=
                                //         state.prevSelectedValue,
                                //     prevSelectedValue: row?.value?.label,
                                //   }));
                                // }
                                if (
                                  row.type === "Does not contain" ||
                                  row.type === "Contains"
                                ) {
                                  handleChange(
                                    index,
                                    "value",
                                    evt.target.value
                                  );
                                  const searchedValue =
                                    columnField.searchedValues?.find(
                                      (obj) => obj.key === row.keyId
                                    );
                                  if (searchedValue) {
                                    searchedValue.value = evt.target.value;
                                  } else {
                                    columnField.searchedValues = [
                                      ...(columnField.searchedValues || []),
                                      {
                                        key: row.keyId,
                                        value: evt.target.value,
                                      },
                                    ];
                                  }
                                }
                              }}
                            />
                          )}
                          onChange={(evt, value) => {
                            handleChange(index, "value", value);
                            setState((prevState) => ({
                              ...prevState,
                              selectedValue: false,
                            }));
                          }}
                          // onBlur={(evt, value) => {
                          //   if (
                          //     row.type === "Does not contain" ||
                          //     row.type === "Contains"
                          //   ) {
                          //     handleChange(index, "value", evt.target.value);
                          //   }
                          // }}
                        />
                      )}
                      {/* {columnField?.isDateField && (
                        <Box sx={filterStyles.input}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              label="Select date"
                              inputFormat="MM/dd/yyyy"
                              disabled={!row.type || !row.label}
                              value={row.value ? row.value : null}
                              onChange={(date) =>
                                handleChange(index, "value", date)
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      )} */}
                      {columnField?.isDateField &&
                        (row.type === "Range" || row.type === "No Range" ? (
                          <FormControl style={{ width: "270px" }}>
                            <DatePicker
                              selectsRange={true}
                              shouldCloseOnSelect={false}
                              className={classes.reactDatepickerWrapper}
                              disabled={!row.type || !row.label}
                              startDate={
                                row.value?.[0] !== null
                                  ? new Date(row.value?.[0])
                                  : null
                              }
                              endDate={
                                row.value?.[1] !== null
                                  ? new Date(row.value?.[1])
                                  : null
                              }
                              popperPlacement="bottom-end"
                              customInput={
                                <div>
                                  <Tooltip
                                    placement="top-start"
                                    title={
                                      row.value?.[0] && row.value?.[1]
                                        ? `${getDateString(
                                            row.value?.[0]
                                          )} - ${getDateString(row.value?.[1])}`
                                        : "" ?? ""
                                    }
                                  >
                                    <TextField
                                      label="Select Date"
                                      variant="outlined"
                                      disabled={!row.type || !row.label}
                                      value={
                                        row.value === ""
                                          ? ""
                                          : `${getDateString(
                                              row.value?.[0]
                                            )} - ${getDateString(
                                              row.value?.[1]
                                            )}`
                                      }
                                      InputProps={{
                                        endAdornment:
                                          row.value === "" ||
                                          row.value?.[1] === null ? (
                                            <DateRangeIcon />
                                          ) : (
                                            ""
                                          ),
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              }
                              maxDate={new Date()}
                              disabledKeyboardNavigation
                              openToDate={
                                row.value?.[0]
                                  ? new Date(row.value?.[0])
                                  : new Date()
                              }
                              onChange={(dateRange) => {
                                handleChange(index, "value", dateRange);
                              }}
                              onCalendarClose={() => {
                                if (row.value?.[0] && !row.value?.[1]) {
                                  const startDate = new Date(row.value?.[0]);
                                  handleChange(index, "value", [
                                    startDate,
                                    startDate,
                                  ]);
                                }
                              }}
                            />
                          </FormControl>
                        ) : (
                          <Datepicker
                            label="Select Date"
                            selected={row.value}
                            value={row.value ? row.value : null}
                            disabled={!row.type || !row.label}
                            classes={{
                              input: {
                                root: clsx("mr-4", classes.datepickerWrapper),
                              },
                            }}
                            maxDate={new Date()}
                            onChange={(date) => {
                              handleChange(index, "value", date);
                            }}
                          />
                        ))}
                      {!columnField?.isDateField && !columnField?.options && (
                        <TextField
                          className={classes.input}
                          type="text"
                          disabled={!row.type || !row.label}
                          variant="outlined"
                          placeholder="Keyword..."
                          value={
                            row.value
                            // row.label === "Phone"
                            //   ? row.value.replace(
                            //       /(\d{3})(\d{3})(\d{0})/,
                            //       "$1-$2-$3"
                            //     )
                            //   : row.value
                          }
                          onChange={(event) => {
                            let value = event.currentTarget.value;
                            handleChange(index, "value", value);
                          }}
                        />
                      )}
                      {state.filterRows.length > 1 && (
                        <Tooltip title="Clear Filter" placement="top-start">
                          <IconButton onClick={() => handleDeleteRow(index)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  );
                })}
                <Button
                  startIcon={
                    <AddCircleIcon color={isDisabled ? "default" : "primary"} />
                  }
                  color="primary"
                  onClick={handleAddClick}
                  disabled={isDisabled}
                >
                  <Typography variant="button">Add Filter...</Typography>
                </Button>
              </Box>
            </Box>
            <Box className={classes.confirmBtn}>
              <Button
                variant="outlined"
                color="primary"
                className="mr-4"
                onClick={handleCancel}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabled || state.selectedValue}
                onClick={() => handleApplyFilter(state.filterRows, onClose)}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Filter;
