import { Switch, Route } from "react-router-dom";
import View from "./view";
import ViewBulkEmailHistory from "./bulk-email-history";

const BulkEmailModule = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={View} />
      <Route
        exact
        path={`${match.path}/history`}
        component={ViewBulkEmailHistory}
      />
    </Switch>
  );
};

export default BulkEmailModule;
